.video-player-container {

    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

    .react-player {
        position: absolute;
        top: 0;
        left: 0;
      }
        
    .play {
        background-color: rgba(0, 0, 0, 0.418);
        border-radius: 30px;

        width: 40px;
        height: 40px;

        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
            color: white;

        }
    }

    &.video-error {
        background-color: black;
        height: 300px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}