.confirmation-container {
    text-align: center;
    max-width: 500px !important;
    padding: 100px;

    .confirm-title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        margin-bottom: 24px;
    }

    .sub-text {
        margin-bottom: 48px;
    }

    .security-code {
        width: 100%;
        padding: 13px;
        display: block;
    }

    .confirm-button {
        margin-top: 36px;
        margin-bottom: 36px;
        width: 100%;
        padding: 10px;
        border-radius: 0;
        text-transform: uppercase;

        svg{
            margin-left: 5px;
            vertical-align: middle;
        }
    }

    .resend-button {
        font-weight: 300;
        background: none !important;
        border: none;
        padding: 0 !important;
        color: #069;
        text-decoration: underline;
        cursor: pointer;
    }
}
