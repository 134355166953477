.usertips-container {
    max-width: 800px;
    margin: auto;

    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: center;
    background-color: #fff3db;
    margin-bottom: 50px;

    .usertips-content {
        @media screen and (max-width: 760px) {
            padding: 0px 20px 100px 20px;
        }

        border: 1px solid rgba(23, 36, 61, 0.3);
        margin: 40px 40px;
        border-radius: 1px;
        padding: 0px 60px 100px 60px;

        .carousel-caption {
            position: relative;
            right: unset;
            bottom: unset;
            left: unset;
            padding-top: unset;
            padding-bottom: unset;
            color: $base;
            text-align: center;
        }

        .carousel-control-prev,
        .carousel-control-next {
            height: 100%;
            top: 40px;
            width: 100px;
        }

        .carousel-control-prev {
            left: -110px;
        }
        .carousel-control-next {
            right: -110px;
        }

        .carousel-control-prev-icon {
            z-index: 100;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.9);
            box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.12);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z'/%3E%3C/svg%3E");
        }
        .carousel-control-next-icon {
            z-index: 100;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.9);
            box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.12);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M10 6L8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6z'/%3E%3C/svg%3E");
        }

        .carousel-indicators {
            bottom: -55px;
        }

        .carousel-indicators [data-bs-target] {
            box-sizing: content-box;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            padding: 0;
            margin-right: 3px;
            margin-left: 3px;
            text-indent: -999px;
            cursor: pointer;
            background-color: $base;
            background-clip: padding-box;
            border: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            opacity: 0.3;
            transition: opacity 0.6s ease;
        }

        .carousel-indicators .active {
            box-sizing: border-box;
            opacity: 1;
            background-color: white;
            height: 10px;
            width: 10px;
            margin-top: 8px;
            border: 2px solid rgba(23, 36, 61, 0.5);
        }

        small {
            display: block;
            color: $base;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 1.25px;
            line-height: 16px;
            text-align: center;
            text-transform: uppercase;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        h3.usertips-title {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 18px;
            text-align: center;
            margin-bottom: 10px;
        }

        p.usertips-author {
            color: rgba(23, 36, 61, 0.5);
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            margin-bottom: 20px;
        }

        p.usertips-main-content {
            /*font-size: 12px;*/
            letter-spacing: 0.25px;
            line-height: 18px;
            text-align: center;
        }

        a.usertips-button {
            display: block;
            text-align: center;
            font-size: 12px !important;
            letter-spacing: 0.25px;
            line-height: 18px !important;
        }

        iframe {
            @media only screen and (min-width: 701px) {
                max-width: 600px;
            }
        }
    }

    button.usertips-share-button {
        position: absolute;
        bottom: 14px;
        left: 50%;
        width: 201px;
        transform: translateX(-50%);
    }
}
