.header {
    height: 56px;
    width: 100%;
    max-width: none !important;
    align-items: center;
    border-bottom: 1px solid rgba($base, 0.1);
    padding: 0 18px;
    position: relative;

    > div{
        padding: 0;
    }

    .back-btn {
        cursor: pointer;
        position: relative;
        left: -10px;
        transition: $transition;

        &:hover {
            color: $gold;
        }
        
        button {
            border: none;
            background-color: transparent;

            svg {
                font-size: 20px;
                color: $base;
                vertical-align: middle;
            }
        }
    }

    .page-title {
        text-align: center;
        white-space: nowrap;

        p {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
        }
    }

    .menu-container {
        text-align: right;
        margin-right: 15px;

        svg {
            font-size: 18px;
            vertical-align: middle;
            cursor: pointer;
            transition: $transition;

            &:hover {
                color: $gold;
            }
        }
    }

    .dot {
        position: absolute;
        height: 20px;
        width: 20px;
        background-color: #EF5A5A;
        border-radius: 50%;
        margin-top: 10px;

        top: 0px;
        right: 24px;

        color: white;

        &:hover {
            cursor: pointer;
        }

        p {
            /* margin-right: 7px; */
            font-size: 11px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 20px;
            text-align: center;
          
        }
    }
}
