.story-builder {

    text-align: center;
    max-width: 500px;
    margin: auto;
    @include general-desktop-container();
    @media screen and (max-width: 760px) {
        margin: 0 20px 20px 20px;
    }

    .story-title {
        margin-top: 36px;
        margin-bottom: 48px;
        text-align: center;
        letter-spacing: 0.5px;
        line-height: 36px;
        font-size: 28px;
        font-weight: 500;
        color: $base;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }

        svg {
            color: $gray;
        }
    }

    .story-title + div  {

        .group input {
            width: 80%;
            display: inline-block;
        }

        .group .submit-icon-container {
            margin-left: 10px;
            display: inline-block;
        }

        .group .top-label {
            left: 30px;
        }
        
        .sub-subtitle {
            font-size: 12px;
            color: RGBA(23, 36, 61, 0.5);
        }
    
        .right {
            float: right;
            margin-right: 85px;
        }        
    }    

    .missing-input-container {
        .missing-input {
            width: 100%;
            height: 268px;
            background-color: RGBA(23, 36, 61, 0.05);
            border: 3px dashed RGBA(23, 36, 61, 0.12);
            display: table;
    
            p {
                width: 135px;
                text-align: center;
                vertical-align: middle;
                font-size: 16px;
                font-weight: 400;
                display: table-cell;
                color: rgba(23,36,61,0.5);
                letter-spacing: 0.15px;
            }
    
        }
    
        .missing-input:hover {
            cursor: pointer;
        }

        .help-message {
            cursor: pointer;
            font-size: 12px;
            color: $gray;
            margin-bottom: 0.5rem;
        }
    
        .new-block {
            margin-top: 30px;
            margin-bottom: 0px;
            position: relative;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;

            .line {
                z-index: 1;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100vw;
                max-width: 500px;
                height: 1px;
                background-color: rgba(23,36,61,0.1);
                overflow: visible;
                @media screen and (min-width: 1000px) {
                    max-width: 100%;
                    width: 100%;
                }
            }
    
            .add-story-svg {
                svg {
                    position: relative;
                    padding: 2px;
                    border: 1px solid white;
                    border-radius: 30px;
                    background-color: #B9BDC4;
                    color: white !important;
                    height: 28px;
                    cursor: pointer;
                    z-index: 5;
                }
            }

            .add-story-svg:hover {
                cursor: pointer;

                svg {
                    background-color: #8d9095;
                }
            }
        }
    }

    .block-builder {
        .tooltip-text {
            margin-left: 0;
            letter-spacing: 0.4px;
        }

        .story-block {
            margin-top: 40px;
            margin-bottom: 40px;
            position: relative;
            overflow-wrap: break-word;
            
            > div:first-child {
                padding-top: 10px;
            }

            .line {
                z-index: 1;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100vw;
                max-width: 500px;
                @media screen and (min-width: 1000px) {
                    max-width: 100%;
                    width: 100%;
                    //added 100% width to the line at story create page based on design
                }
                height: 1px;
                background-color: rgba(23, 36, 61, 0.1);
                overflow: visible;
            }

            .edit-icon {
                position: absolute;
                top: -10px;
                right: 0px;
                svg {
                    color: rgba(0, 0, 0, 0.4);
                }
            }

            .edit-icon:hover {
                cursor: pointer;
                svg {
                    color: rgba(0, 0, 0, 0.7);
                }
            }

            .pen {
                right: 40px;
            }

            .help-message {
                cursor: pointer;
                font-size: 12px;
                color: $gray;
                margin-bottom: 1rem;
                margin-top: 5rem;
            }            
            
            .story-block-edit {
                position: absolute;
                background-color: white;
                right: 0;
                top: 20px;
                width: 160px;
                height: auto;
                box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                z-index: 10;

                padding: 5px;
                p {
                    margin: 0px;
                    text-align: left;
                    padding: 5px;
                    font-weight: 200;
                }

                p:hover {
                    cursor: pointer;
                    background-color: rgb(226, 226, 226);
                }

                .edit-svg {
                    margin-right: 10px;
                }
            }
    
            .add-story-svg {
                svg {
                    position: relative;
                    top: 17px;
                    padding: 2px;
                    border-radius: 30px;
                    height: 34px !important;
                    width: 34px !important;                    
                    cursor: pointer;
                    border: 1px solid white;
                    background-color: #B9BDC4;
                    color: white;
                    z-index: 5;
                }
            }

            .add-story-svg:hover {
                cursor: pointer;

                svg {
                    background-color: #8d9095;
                }
            }
        }
    }

    .default-button-story {
    margin-top: 86px; /* 36px */
    margin-bottom: 48px;
    max-width: unset;

    @media (min-width: 1000px) {
        max-width: 50%;
        //desktop story green button width modification
    }
}

    .story-block {
        .block.TEXT {
            text-align: left !important;
            p > span {
                text-align: left !important;
                font-size: 16px !important;
                letter-spacing: 0.5px;
                line-height: 24px;
                font-family: 'DM Sans', Verdana, Geneva, Tahoma, sans-serif !important;
            }
            .display-text {
                margin-left: 0;
                margin-right: 0;
                @media (max-width: 550px) {

                }
                @media (min-width: 1000px) {
                    max-width: 700px;
                    margin: auto;
                }
            }
        }

        .block.LIFE_LESSON {
            padding-top: 40px;
            padding-bottom: 40px;                
        }        
    }

}

    section.preview-story-section {
        margin-left: -48px !important;
        margin-right: -44px !important;
        margin-top: 40px;
        background-color: #f3f4f5;

        .preview-story-section-content {
            padding: 0 18px;
            padding-bottom: 36px;
            text-align: center;
            border-bottom: 1px solid rgba(23,36,61,0.1);

            .subtitle {
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 1.25px;
                line-height: 16px;
                padding-left: 5px;
                text-align: center;
                text-transform: uppercase;
                color: $base-green;
                padding-top: 36px;
                padding-bottom: 0px;
            }
            small {
                display: block;
                font-size: 12px;
                letter-spacing: 0;
                padding-left: 5px;
                line-height: 18px;
                text-align: center;
                color: rgba(23, 36, 61, 0.7);
            }
        }

        .preview-story-section-content:hover { 
            cursor: pointer;
        }

    }

