.offline-detect-alert {
    position: fixed !important;
    z-index: 100 !important;
    bottom: -15px !important;
    width: 100% !important;
    padding: 5px !important;
    background-color: #fff !important;
    border-radius: 0px !important;
    border-left: none !important;
    border-right: none !important;

    p {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        text-align: center;
    }

    @media screen and (max-width: 992px) {
        bottom: 49px !important;
    }
}