.lifelesson-container {

    .inputs {
        padding: 0 0 20px 0 !important;
    }

    .subtitle {
        color: rgba(23, 36, 61, 0.5);
        font-size: 13px;
        text-align: center;
        margin-bottom: 20px;
    }    

    .group {

        padding-bottom: 10px;
        
        .sub-subtitle {
            color: rgba(23, 36, 61, 0.5);
            font-size: 13px;
            text-align: center;
            margin-bottom: 20px;
        }

        .right {
            text-align: right;
            padding-left: 12px;
        }

        textarea {
            width: 100%
        }
    }
}