.group-and-individual-connections {
    background-color: #f3f4f5;
    position: relative;
    width: 100vw;
    max-width: 500px;
    left: -18px;
    padding: 24px 18px 42px 18px;
    margin-top: 52px;
    @include desktop-box-max-width();

    .connect-item {
        margin-bottom: 36px;

        &:last-child {
            margin-bottom: 0;
        }

        .icon-wrapper {
            text-align: center;
            font-size: 24px;
            color: rgba($base, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            &::before {
                content: "";
                height: 1px;
                width: 40%;
                background-color: rgba($base, 0.07);
                display: inline-block;
                margin-right: 15px;
            }
            &::after {
                content: "";
                height: 1px;
                width: 40%;
                background-color: rgba($base, 0.07);
                display: inline-block;
                margin-left: 15px;
            }
        }

        .subtitle {
            font-size: 12px;
        }

        select {
            background-color: transparent;
        }

        .selectedGroups {

            .group {
                margin-bottom: 20px;
                background-color: #e6e8ea;
                margin-top: 16px;
                border-radius: 16px;
                padding-bottom: 16px;
            }

            &:empty{
                padding: 0;
                margin: 0;
            }

            .group-item {
                border: 1px solid rgba($base, 0.3);
                padding: 4px;
                display: flex;
                align-items: center;
                border-radius: 16px;
                background-color: white;

                .icon {
                    height: 24px;
                    width: 24px;
                    background-color: $blue;
                    border-radius: 50px;
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        color: $secondary;
                        font-size: 12px;
                    }
                }


                p {
                    display: inline-block;
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    font-size: 14px;
                    letter-spacing: 0.13px;
                    color: $base;
                    padding: 0 10px;
                    flex-grow: 1;
                    margin-bottom: 0;
                }

                .remove-icon {
                    width: 15px;
                    height: 15px;
                    flex-shrink: 0;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 6px;

                    svg {
                        color: $gray;
                        font-size: 10px;
                    }
                }

                .remove-icon:hover {

                    svg {
                        color: $darkgray;
                    }
                    cursor: pointer;
                }
            }

            .name-list {
                font-size: 12px;
                color: $gray;
                letter-spacing: 0.25px;
                line-height: 18px;
                padding: 8px 12px 0 12px;
                margin-bottom: 13px;
            }
        }

        .checkbox-container {
            padding: 0 12px;
            display: flex;
            align-items: center;

            input {
                margin-right: 8px !important;
                margin: 0;
            }

            label{
                font-size: 12px;
                letter-spacing: 0.25px;
            }
        }

        .example-story-select {
            .option p {
                font-size: 14px;
            }
        }

        .example-story-list {
            background-color: #f3f4f5;
        }
    }
}
