.stories-container {
    padding: 0 !important;

    @media only screen and (min-width: 992px) {
        p.life-lessons {
            min-height: 49.7vh;
        }
    }
    p.life-lessons {
        min-height: 15.7vh;
    }
}