.select-administrator-container {
    margin-top: 36px;
    position: relative;
    margin-bottom: 36px;    

    .title {
        color: #17243d;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 16px;
    }

    .text {
        font-size: 14px;
        font-weight: 400;
        max-width: 80%;
        margin: auto;
    }

    .admin-select {
        width: 100%;
        padding: 15px;
        font-weight: 300;
        margin-bottom: 16px;
    }

    .no-admin {
        font-size: 13px;
        text-align: center;
        margin: 0;
    }

    > p {
        font-size: 13px;
        text-align: center;
    }

    .avalible-family-members {
        box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 8px 0px;
        position: absolute;
        background-color: #fff;
        z-index: 10;
        top: 56px;
        width: 100%;
        padding: 0 16px;

        p {
            font-size: 16px;
            margin: 0;
            line-height: 48px;
        }

        p:hover {
            cursor: pointer;
            color: $gray;
        }
    }

    .administrators {
        .admin-info {
            border: 1px solid rgba($base, 0.3);
            border-radius: 20px;
            margin-bottom: 12px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:last-child{
                margin: 0;
            }

            .profile {
                display: flex;
                align-items: center;

                img {
                    height: 26px;
                    width: 26px;
                    border-radius: 20px;
                    margin-left: 4px;
                    margin-right: 8px;
                }

                p {
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 400;
                    margin: 0;
                }
            }

            svg {
                color: $gray;
                font-size: 15px;
                height: 18px !important;
                width: 18px !important;
                margin-right: 9px;
            }

            svg:hover {
                color: #213457;
                cursor: pointer;
            }
        }
    }
}
