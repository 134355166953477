.navigation-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $secondary;
    box-shadow: 0 -1px 0 0 #e7e9eb;
    z-index: 101;
    padding-bottom: 20px;

    @media screen and (min-width: 992px) {
        position: static;
        width: 260px;
        box-shadow: 1px 0 0 0 #e7e9eb;
        padding: 14px 20px;
    }

    .content {
        height: 64px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        max-width: 435px;
        margin: auto;

        @media screen and (min-width: 992px) {
            display: block;
            margin: 0;
        }

        .logo{
            display: none;

            @media screen and (min-width: 992px) {
                display: block;
                margin-bottom: 30px;
            }
        }

        .logo:hover{
            cursor: pointer;
        }

        .nav-item {
            height: 100%;
            color: rgba($base, 0.7);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20%;

            @media screen and (min-width: 992px) {
                height: auto;
                padding: 15px 0 !important;
                width: auto;
            }

            a.active {
                font-weight: 500;
                color: $gold;
            }

            &.active {
                color: $gold;
                border-bottom: 3px solid $gold;
                padding-top: 3px;

                @media screen and (min-width: 992px) {
                    border: none;
                }

                a,
                button {
                    color: $gold;
                    
                    @media screen and (min-width: 992px) {
                        font-weight: 600;
                    }
                }
            }

            a,
            button {
                color: rgba($base, 0.7);
                text-decoration: none;
                border: none;
                background-color: transparent;
                font-size: 12px;
                letter-spacing: 0.4px;
                line-height: 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 0;
                transition: $transition;

                @media screen and (min-width: 992px) {
                    flex-direction: row;
                    justify-content: flex-start;
                    font-size: 14px;
                    letter-spacing: 1.25px;
                    line-height: 18px;
                    width: 100%;
                }

                &:hover{
                    color: $gold;
                }

                svg {
                    margin-bottom: 3px;

                    @media screen and (min-width: 992px) {
                        margin-bottom: 0;
                        margin-right: 14px;
                    }
                }
            }
        }
    }
}
