.story-request.story-details {
    .main-info {
        
        margin-bottom: 30px;

        .user-picture {
            width: 60px;
            height: 60px;
            margin-bottom: 0;
        }
        p.title{
            margin-bottom: 40px;
        }
        p.from {
            margin-top: 10px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
        }
        p.date{
            margin-bottom: 10px;
        }
        .whats-next-body {
            margin: 30px auto 30px auto;

            .carousel-container {
                padding-top: 0;
            }
        }
        .name {
            margin-top: 0 !important;
            font-size: 12px;            
        }
        .status {
            span {
                vertical-align: middle;
                padding-left: 5px;
            }
        }        

    }
    .story-body {
        margin-bottom: 50px;
        max-width: 100%;

        .display-block-text {
            .text-data {
                p {
                    white-space: pre-line
                }
            }
        }
    }
    .default-button {
        margin-bottom: 1rem;
    }    
    .link-button {
        display: block;
        margin: auto;
        padding: 1rem !important;
        margin-bottom: 1rem;
        letter-spacing: 1.5px;
    }
    .link-button.cancel-button {
        color: #ef5a5a !important;
    }
    button:last-child {
        margin-bottom: 70px;
    }

    .attachment-title {
        text-align: center;
        font-size: 20px;
        /*margin-bottom: 64px;*/
        padding-top: 30px;
    }

    .public-story-page .story-container {
        border: 0;
    }

    .public-story-page .error-container {
        padding: 2rem;
        margin-bottom: 50px;
    }

    .attached-inspiration {

        text-align: center;

        img {
            width: 100%;
            height: auto;
        }

        p.question {
            font-size: 18px;
            line-height: 1.2;
            min-height: 3rem;
            padding-bottom: 0;
            overflow: hidden;
        }

        p.follow-up {
            min-height: 2.5rem;
        }

        .new-block {
            svg {
                padding: 10px;
            }    
        }

        .attachment-title {
            font-weight: 700;
            line-height: 24px;
        }
    }

    .attached-story {
        .new-block {
            svg {
                padding: 10px;
            }    
        }        
    }

}

.story-requests-sent {
    .img-author {
        display: inline-block;
        padding-left: 5px;
    }

    .to-user-information {
        font-size: 12px;
        color: $gray;
    }
    
    .to-user-plus {
        padding-left: 5px;
    }

    .status {
        position: absolute;
        top: 15px;
        right: 50px;
    }  
}