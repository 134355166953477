// Adult
.add-to-family-adult-modal {

}

.add-to-family-child-modal {
    .explanation {
        input {
            margin-bottom: 10px !important;
        }
    }
    .group {
        .sub-subtitle {
            color: rgba(23, 36, 61, 0.5);
            font-size: 13px;
            text-align: center;
            margin-bottom: 40px;
        }

        .left {
            text-align: left;
            padding-left: 12px;
        }
    }

    .record-button {
        margin: auto;
    }
}