.existing-profile {
    padding: 0 50px;
    @media screen and (max-width: 520px) {
        padding: 0 20px;
    }
    .profile-details {
        text-align: center;
        border-bottom: 1px solid rgba($gray, 0.07);
        padding-bottom: 24px;
        margin-bottom: 24px;
        width: 100vw;
        position: relative;
        left: -18px;
        @media screen and (max-width: 480px) {
            left: 0;
            width: inherit;
        }

        @media screen and (min-width: 480px) {
            position: static;
            width: 100%;
            left: 0;
        }

        img {
            width: 150px;
            height: 150px;
            margin-bottom: 16px;
        }

        .name {
            margin: 0;
        }
    }

    .subtitle {
        display: block;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-bottom: 4px;
    }

    .top-text {
        margin-bottom: 34px;
        color: $gray;
        font-size: 13px;
        letter-spacing: 0.4px;
        text-align: center;
    }
/*
    .group {
        margin-bottom: 48px;
    }
*/    

    .relationship-picker-container {
        margin-bottom: 48px;
        @media screen and (max-width: 520px) {
            padding: 0 20px;
        }

        .group {
            margin-bottom: 0;
        }
    }

    .weekly-update {
        padding-left: 10px;
        padding-top: 4px;

        span {
            font-size: 16px;
        }

        .switch {
            float: right;

            .react-switch-bg {
                opacity: 0.15;
            }
        }

        .switch-status {
            float: right;
            margin-right: 10px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(23, 36, 61, 0.5);
            margin-bottom: 0;
        }
    }

    .actions {
        text-align: center;
        padding-bottom: 48px;
        margin-top: 60px;
        /* border-bottom: 1px solid rgba($gray, 0.1); */

        .finish {
            margin-bottom: 36px;
        }
    }

    .family-member-list-to-connect {
        margin-top: 10px;

        .group {
            .top-label {
                z-index: 2
            }    
        }

        .members {
            .admin-info {
                border: 1px solid rgba($base, 0.3);
                border-radius: 20px;
                margin-bottom: 12px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: space-between;
    
                &:last-child {
                    margin: 0;
                }
    
                .profile {
                    display: flex;
                    align-items: center;
    
                    img {
                        height: 26px;
                        width: 26px;
                        border-radius: 20px;
                        margin-left: 4px;
                        margin-right: 8px;
                    }
    
                    p {
                        display: inline-block;
                        font-size: 14px;
                        font-weight: 400;
                        margin: 0;
                    }
                }
    
                svg {
                    color: $gray;
                    font-size: 15px;
                    margin-right: 9px;
                    width: 18px !important;
                    height: 18px !important;
                }
    
                svg:hover {
                    color: #213457;
                    cursor: pointer;
                }

            }

        }        

        .member-name {
            display: inline;
            font-size: 12px;
            font-weight: 400;
            color: $gray;
        }

        .connect-info {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 12px;
            font-weight: 400;
            color: $gray;
            background-color: none;
        }

        .show-all {
            text-decoration: underline;
            color: $base-green;
            cursor: pointer;

            &.less {
                margin-left: 5px
            }
        }
    }    
}
