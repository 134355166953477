.delete-group-modal {
    .modal-body {
        padding: 24px 50px 60px 50px;
    }
    .subtitle {
        text-align: center;
        line-height: 21px;
        margin-bottom: 36px;
    }

    .button-group {
        padding-top: 22px;
        text-align: center;

        .proceed {
            background-color: $red !important;

            &:hover {
                background-color: $lightred !important;
            }
        }

        .cancel {
            color: $gray !important;
            margin-top: 34px;

            &:hover {
                color: $base !important;
            }
        }
    }
}
