.profile-action {
    backdrop-filter: blur(1px);

    @media (min-width: 768px) {
        text-align: left;
        display: flex !important;
        align-items: center;
    }

    .modal-dialog {
        position: absolute;
        bottom: 0;
        margin: 0;
        width: 100%;
        max-width: none;

        @media (min-width: 768px) {
            max-width: 500px;
            margin: auto;
            position: relative;
        }

        .modal-content {
            border: none;
            border-radius: 8px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            @media (min-width: 768px) {
                border-radius: 8px;
            }

            .modal-header {
                border: none;
                padding: 18px 24px;
                min-height: 56px;
                position: relative;
                display: block;

                .modal-title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 36px;

                    &:empty{
                        display: none;
                    }
                   
                    &::after{
                        content: "";
                        width: 100%;
                        height: 1px;
                        background-color: rgba($base, 0.1);
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }
                }

                .btn-close {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    padding: 0 8px;
                }

                svg {
                    position: absolute;
                    right: 28px;
                    top: 8px;
                    color: rgba($base, 0.3);
                    font-size: 20px;
                    cursor: pointer;
                    transition: $transition;

                    &:hover {
                        color: $gray;
                        transition: $transition;
                    }
                }
            }

            .modal-body {
                padding: 0px 50px 56px 50px;
                @media screen and (max-width: 520px) {
                    padding: 0px 20px 56px 20px;
                }

                .link-row {
                    border-bottom: 1px solid rgba($base, 0.05);
                    cursor: pointer;
                    padding: 16px 0;

                    &:last-child {
                        border: none;
                    }

                    &:hover {
                        p {
                            color: $gray;
                        }
                    }

                    svg {
                        color: white;
                        background-color: $base-green;
                        width: 36px !important;
                        height: 36px;
                        padding: 6px;
                        border-radius: 20px;
                        text-align: center;
                        vertical-align: middle;
                        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

                        &.black {
                            background-color: $darkgray;
                        }

                        &.red {
                            background-color: $red;
                        }
                    }

                    p {
                        display: inline-block;
                        margin-left: 12px;
                        vertical-align: middle;
                        font-size: 16px;
                        font-weight: 600;
                        margin-bottom: 0;
                    }
                }                

                .remove-from-family {
                    padding-top: 24px;

                    p {
                        margin: 0;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        letter-spacing: 0.25px;
                        margin-bottom: 24px;
                    }

                    .button-group{
                       padding-top: 22px; 
                       text-align: center;

                       .proceed{
                           background-color: $red !important;

                           &:hover {
                                background-color: $lightred !important;
                           }
                       }
                    }
                }
            }
        }
    }
}

.profile-action.options-active.modal {
    .modal-dialog {
        @media screen and (max-width: 520px) {
            top: 24px;
            bottom: 0;
        }
    }
}