// Font
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700;900&display=swap");

//Variables
$base: #17243d;
$secondary: #fff;
$base-green: #006238;
$base-green-hover: #009454;
$gold: #a38138;
$blue: #4569ad;
$gray: rgba($base, 0.5);
$fullgray: #16243c;
$darkgray: #17243d;
$lightgray: #f3f4f5;
$custom_white: rgba(white, 0.9);
$red: #ef5a5a;
$lightred: rgb(238, 109, 109);
$font: "DM Sans", sans-serif;
$transition: all 0.2s linear;

$gray-color-line: rgba(23, 36, 61, 0.12);
$gray-color-border: rgba(23, 36, 61, 0.1);

//Global styles
html body {
    font-family: $font;
    font-size: 14px;
    color: $base;
    max-width: 100%;
    overflow-x: hidden;
}

p {
    font-size: 14px;
    line-height: 21px;
}

// Text
.title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: $base;
    text-align: center;
}

//gold switch
.active-gold-switch {
    .react-switch-bg {
        opacity: 0.15;
        background: $gold !important;

    }

    .react-switch-handle {
        background: $gold !important;
    }
}

.react-switch-bg {
    opacity: 0.15;
    background: $gray !important;

}

.react-switch-handle {
    background: rgb(175, 175, 175)!important;
}

// Buttons
.default-button {
    background-color: $base-green !important;
    color: white !important;
    width: 100%;
    height: 56px;
    border: none !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    border-radius: 0px !important;
    box-shadow: none !important;
    transition: $transition;
    display: block !important;

    @media screen and (min-width: 425px) {
        max-width: 392px;
        margin-left: auto;
        margin-right: auto;
    }

    &:disabled {
        background-color: rgba($base, 0.12) !important;
        color: rgba($base, 0.3) !important;

        &:hover{
            background-color: rgba($base, 0.12) !important;
        }
    }

    &:hover {
        background-color: $base-green-hover !important;
    }

    svg {
        margin-left: 10px;
        vertical-align: middle;
    }
}

.danger-button {
    background-color: $red !important;
    color: white !important;
    width: 100%;
    height: 56px;
    border: none !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    border-radius: 0px !important;
    box-shadow: none !important;
    transition: $transition;
    display: block !important;

    @media screen and (min-width: 425px) {
        max-width: 392px;
        margin-left: auto;
        margin-right: auto;
    }

    &:disabled {
        background-color: rgba($base, 0.12) !important;
        color: rgba($base, 0.3) !important;

        &:hover{
            background-color: rgba($base, 0.12) !important;
        }
    }

    &:hover {
        background-color: $lightred !important;
    }

    svg {
        margin-left: 10px;
        vertical-align: middle;
    }
}

.default-button-gold {
    background-color: $gold !important;
    color: white !important;
    width: 100%;
    height: 40px;
    border: none !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    border-radius: 0px !important;
    box-shadow: none !important;
    transition: $transition;
    display: block !important;

    @media screen and (min-width: 425px) {
        max-width: 364px;
        margin-left: auto;
        margin-right: auto;
    }

    &:disabled {
        background-color: rgba($base, 0.12) !important;
        color: rgba($base, 0.3) !important;

        &:hover{
            background-color: rgba($base, 0.12) !important;
        }
    }

    svg {
        margin-left: 10px;
        vertical-align: middle;
    }
}

.light-button {
    background-color: white !important;
    color: $gray !important;
    width: 100%;
    height: 56px;
    border: none !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    border-radius: 0px !important;
    box-shadow: none !important;
    transition: $transition;
    display: block !important;

    margin-top: 20px;
    margin-bottom: 20px;


    .red-highlight {
        color: $red;
        font-size: 12px;
        letter-spacing: 1.25px;
        &:hover {
            color: #ca3a3a;
        }
    }

    .or-text {
        color: $gray;
        font-size: 12px;
        letter-spacing: 1.25px;
        &:hover {
            color: $gray !important;
        }
    }

    @media screen and (min-width: 425px) {
        max-width: 364px;
        margin-left: auto;
        margin-right: auto;
    }

    &:disabled {
        background-color: rgba($base, 0.12) !important;
        color: rgba($base, 0.3) !important;

        &:hover{
            background-color: rgba($base, 0.12) !important;
        }

    }

    &:hover {
        color: $base !important;
    }

    svg {
        margin-left: 10px;
        vertical-align: middle;
    }
}

.underlined-link-button {
    font-weight: 400 !important;
    background: none !important;
    border: none !important;
    padding: 0 !important;
    color: $base-green !important;
    text-decoration: underline !important;
    cursor: pointer !important;
    line-height: 21px !important;
    font-size: 14px !important;
    box-shadow: none !important;
    transition: $transition;

    &:hover {
        color: $base-green-hover !important;
    }
}

.link-button {
    color: $base-green !important;
    background-color: transparent !important;
    border: none !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    text-decoration: none !important;
    padding: 0 !important;
    box-shadow: none !important;
    transition: $transition;

    &:hover {
        color: $base-green-hover !important;
    }
}

.cancel-button {
    color: $gray !important;
    margin-top: 10px;
    height: 56px;
    width: 100%;
    background-color: transparent !important;
    border: none !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    text-decoration: none !important;
    padding: 0 !important;
    box-shadow: none !important;
    transition: $transition;
    display: block;

    &:hover {
        color: $base !important;
    }

    @media screen and (min-width: 425px) {
        max-width: 364px;
        margin-left: auto;
        margin-right: auto;
    }
}

.bordered-button {
    width: 100%;
    background-color: #fff !important;
    color: $base-green !important;
    height: 56px;
    border: 1px solid $base-green;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    border-radius: 3px !important;
    box-shadow: none !important;
    transition: all 0.2s linear;
    display: block !important;

    @media screen and (min-width: 425px) {
        max-width: 364px;
        margin-left: auto;
        margin-right: auto;
    }
}

.bordered-button:hover {
    color: #fff !important;
    background-color: $base-green !important;
}

.button-space {
    padding: 1rem !important;
    margin-bottom: 1rem;
}

.button-space-below {
    margin-bottom: 1rem;    
}


//Inputs
input,
select {
    border: 1px solid rgba($base, 0.38) !important;
    height: 56px !important;
    outline: $base;
    font-size: 16px !important;
    color: $base !important;
    background-color: transparent;
    border-radius: 0 !important;
    width: 100%;
    padding: 0 15px;
    

    &::placeholder {
        font-size: 16px;
        font-weight: 400;
        color: $gray;
        letter-spacing: 0.15px;
    }

    &:focus {
        box-shadow: none !important;
    }
}

select{
    cursor: pointer;
}

textarea {
    border: 1px solid rgba($base, 0.38) !important;
    border-radius: 0 !important;
    padding: 15px !important;
    box-shadow: none !important;
    font-size: 16px !important;
    color: $base !important;

    &::placeholder {
        font-size: 16px;
        font-weight: 400;
        color: $gray !important;
        letter-spacing: 0.15px;
    }

    &:focus-visible {
        outline: none;
    }

}


input[type="checkbox"] {
    width: 18px !important;
    height: 18px !important;
    margin-right: 10px !important;
    border-radius: 2px !important;
    padding: 0;

    &:checked {
        background-color: $gold !important;
        border-color: $gold !important;
    }
}

input[type="radio"] {
    height: 20px !important;
    width: 20px !important;
    padding: 0;

    &:checked + .radio-circle {
        background-color: $gold;
        border-color: $gold;
        position: relative;

        &::after {
            content: "";
            height: 16px;
            width: 16px;
            border-radius: 50%;
            border: 3px solid $secondary;
            position: absolute;
            top: 0;
            left: 0px;
        }
    }
}

.radio-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid rgba($base, 0.38);
    margin-right: 18px;
    cursor: pointer;
}

.group {
    position: relative;

    .top-label {
        position: absolute;
        top: -10px;
        left: 15px;
        background-color: white;
        font-size: 13px !important;
        padding: 0 5px;
        color: rgba($base, 0.7) !important;
        z-index: 10;
        line-height: 19px !important;
    }

    span {
        padding-left: 2px;
        color: red;
    }

    input[value=""] + .top-label,
    input:placeholder-shown + .top-label,
    textarea:placeholder-shown + .top-label {
        display: none;
    }
}

// Container
/*
.container {
    padding-left: 18px !important;
    padding-right: 18px !important;
}
*/

.s-form-container {

    padding-top: 40px;
    max-width: 500px;
    margin: auto;
    padding-bottom: 80px;
    
    @include desktop-box-max-width();    

    .inputs {
        padding: 20px 0 20px 0 !important;
    }

    .another-title {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: $base;
        margin-top: 40px;
    }

    .subtitle {
        color: rgba(23, 36, 61, 0.5);
        font-size: 13px;
        text-align: center;
        margin-bottom: 20px;
    }

    .group {

        padding-bottom: 40px;
        
        .sub-subtitle {
            color: rgba(23, 36, 61, 0.5);
            font-size: 13px;
            text-align: center;
            margin-bottom: 0px;
        }

        .right {
            text-align: right;
            padding-left: 12px;
        }

        textarea {
            width: 100%
        }

        &.checkbox {
            .form-check {
                label {
                    margin-top: -1.75em;
                    padding-left: 6px;
                }
            }
        }

        .form-check {

            label {
                margin-top: 0.25em;
            }

            input {
                margin-right: 10px !important;
            }
        }
    }

    .group.charlimit {
        padding-bottom: 13px;
    }

    button.link-button {
        padding: 1rem !important;
        margin-bottom: 1rem;
        width: 100%
    }

}

div.row {
    max-width: 100%;
    margin: auto;
}

// Img
.user-picture {
    border-radius: 50%;
    object-fit: cover;
}

//Unique select
.unique-select {
    margin-bottom: 0 !important;
}

.tooltip-text {
    font-size: 12px;
    font-weight: 400;
    color: $base-green;
    text-decoration: underline;
    margin-left: 15px;
}

.tooltip-text:hover {
    color: $base-green-hover;
    cursor: pointer;
}

//spinner
.spinner-container {
    text-align: center;
    padding: 20px;
}

/* Custom checked radio styling with pseudo element */
.radio-input-container {
    input[type="radio"] {
        opacity: 0;
    }
    label > input[type="radio"] + *::before {
        content: "";
        display: inline-block;
        vertical-align: bottom;
        width: 1.4rem;
        height: 1.4rem;
        margin-right: 0.3rem;
        border-radius: 50%;
        border-style: solid;
        border-width: 0.1rem;
        margin-left: -30px;
        margin-bottom: 6px;
        border-color: gray;
    }

    label {
        cursor: pointer;
        div {
            display: inline-block;
        }
        span {
            position: absolute;
            display: inline-block;
        }
    }

    label > input[type="radio"]:checked + *::before {
        background: radial-gradient($gold 0%, $gold 40%, transparent 50%, transparent);
        border-color: $gold;
    }
}
/*
.help-container {
    // margin-top: 10px;
    padding: 35px;
    border-top: 1px solid #f3f4f5;
    position: relative;

    text-align: center;

    button {
    }

    svg {
        position: absolute;
        right: 10px;

        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);

        color: rgba($gray, 0.3);
        font-size: 12px;
    }
}
*/
//unique date select container with standalone day, month and year
.dates-input-container {
    display: flex;
    justify-content: space-between;
    max-width: 500px;
    margin-bottom: 20px;
    @include desktop-box-max-width-100();
    .date-container {
        position: relative;
        max-width: 150px;
        width: 30%;

        @media screen and (min-width: 1000px) {
            max-width: inherit;
        }    

        &.disabled {
            background-color: $lightgray;
        }

        label {
            display: inline-block;
            position: absolute;
            top: -9px;
            left: 15px;
            background-color: white;
            font-size: 13px !important;
            padding: 0 5px;
            color: rgba(23, 36, 61, 0.7) !important;
            z-index: 10;
            line-height: 19px !important;
        }

        .calendar-icon {
            width: 1.5rem;
            height: 1.5rem;
            display: inline-block;
            position: absolute;
            top: 15px;
            right: 15px;
            background-color: none;
            color: rgba(23, 36, 61, 0.7) !important;
        }

        .calendar-close-icon {
            position: absolute;
            top: -8px;
            right: -8px;
            background-color: white;
            color: $gray;
        }

        .calendar-close-icon:hover {
            color: gray;
            cursor: pointer;
        }
    }
}


.input-error input,
input.input-error {
    border: 2px solid $red !important;
}
/*
.input-error input[type=checkbox] {
    outline: 2px solid $red !important;
}
*/

.terms.container {
    padding: 10%
}


.banner-container {
    margin: 0 auto;
    text-align: center;
    padding-bottom: 30px;

    img {
        max-width: 100%;
        height: auto;
    }
}

.action-container {
    position: absolute;
    top: 15px;
    right: 20px;
    color: $gray;
}

.new-block {
    margin-top: 60px;
    margin-bottom: 0px;
    position: relative;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    svg {
      position: relative;
      padding: 2px;
      border: 1px solid white;
      border-radius: 30px;
      color: rgba(23, 36, 61, 0.1) !important;
      background-color: white;
      height: 28px;
      cursor: pointer;
      z-index: 5;
    }

    .line {
      z-index: -1 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100vw !important;
      height: 1px !important;
      background-color: rgba(23, 36, 61, 0.1) !important;
      overflow: visible !important;
      /* max-width: inherit !important; */
      margin: 0 !important;
    }
  }

//Disable text selection
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.App {
    overflow-x: hidden;
}

html, body, #root, .App, .main-wrapper {
	min-height: 100vh;
}

/* text editor when editing */
.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
    padding-left: 2rem !important;
}
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
    margin-left: 0 !important;
}
.public-DraftStyleDefault-block {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
}
li.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 div.public-DraftStyleDefault-block {
    margin: 0 !important;
}
.DraftEditor-editorContainer span {
    /* font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important; */    
    color: #17243d !important;
    font-family: "DM Sans", sans-serif !important;
    letter-spacing: 0.5px !important;
}

.rdw-link-modal-target-option {
    display: none;
}

.access {
    text-align: center;
    padding: 20px;

    .locked {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        padding-top: 20px;
    }
    .info-text {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    button {
        margin-bottom: 70px;
    }
}

.sub-access-message {

    text-align: center;
    padding: 50px;
    font-size: 13px;
    color: rgba(23, 36, 61, 0.7);
    margin-bottom: 0;
}

.information {
    text-align: center;
    color: $gray;
    font-size: 13px; /* 12px; */
    margin-top: 30px;
    margin-bottom: 30px;
}

.responsive {
    max-width: 100%;
    height: auto;
}

.iframe-empty-page {
    text-align: center;
    margin: 24px 0;
}

iframe {
    @media only screen and (max-width: 400px) {
        max-width: 245px;
    }

    @media only screen and (min-width: 401px) and (max-width: 500px) {
        max-width: 335px;
    }

    @media only screen and (min-width: 501px) and (max-width: 700px) {
        max-width: 500px;
    }

    @media only screen and (min-width: 701px) {
        max-width: 700px;
    }

}
