.account {

    .subscription-info {

        text-align: center;
        max-width: 700px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 40px;
        @include desktop-box-max-width();

        .row {

            padding-top: 10px;
            padding-bottom: 10px;

            .expired {
                color: #f75f5f;
            }

            .col {
                padding-top: 10px;
                padding-bottom: 10px;

                p {
                    margin: 0;
                }
            }

            .col:first-child {
                text-align: left;
                color: rgba(23,36,61,0.5);
                font-size: 14px;
                letter-spacing: 0;
                line-height: 48px;
            }

            .col:last-child {
                text-align: right;
                color: #17243D;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 48px;
            }

            .tooltip-text {
                margin-left: 0;
            }
        }

        .row:not(:last-child) {
            border-bottom: 1px solid rgba(23,36,61,0.05);
        }

        .button-container {

            .expired-data-container {
                p:not(:last-child) {
                    margin: 0;
                    font-size: 14px;
                }

                button {
                    margin-top: 60px;
                }

                .gray-text {
                    color: $gray;
                    font-size: 13px;
                    margin-top: 10px;
                    margin-bottom: 50px;
                }
            }

            &.action-buttons {
                margin-bottom: 60px;
            }
        }
        

        .credit-widget {
            background-color: #FFFFFF;
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
            padding: 20px 20px 50px 20px;

            p {
                padding-top: 30px;
                padding-bottom: 50px;

                max-width: 450px;
                margin: auto;

                color: #17243D;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 1px;
                line-height: 24px;
                text-align: center;
            }

        }
    }
}

.subscription-share-container {
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    margin-top: 30px;
    @include desktop-box-max-width();

    .title-container {
        p:first-child {
            color: #17243D;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.5px;
            line-height: 32px;
        }

        p:last-child {
            color: #17243D;
            font-size: 14px;
            letter-spacing: 0.25px;
            line-height: 21px;
            max-width: 315px;
            
            margin: auto;
        }
    }

    .seat-info-container {
        width: 335px;
        background-color: #FFF3DB;
        margin: auto;
        padding: 20px;

        margin-bottom: 30px;
        margin-top: 30px;

        p:first-child {
            color: #17243D;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.25px;
            line-height: 21px;
            margin: 0;
        }

        p:last-child {
            padding-top: 7px;
            margin: 0;
        }
    }

    .link-to {
        color: #006238;
        text-decoration: underline;
    }

    .link-to:hover {
        cursor: pointer;
    }

    .small-text {
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
    }

    .relation-container {
        margin-bottom: 50px;

        .relations {
            margin-bottom: 30px;

            .relation {
                padding: 18px 0;
                border-bottom: 1px solid rgba($base, 0.07);
        
                @media screen and (min-width: 480px) {
                    margin: 0 18px;
                }
        
                .member-detail {
                    padding-left: 0;
                    display: flex;
        
                    .img-container {
                        margin-right: 14px;
        
                        img {
                            width: 56px;
                            height: 56px;
                        }
                    }
        
                    .content {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
        
                        p {
                            margin: 0;
                        }
        
                        .name {
                            text-transform: uppercase;
                            font-weight: bold;
                            letter-spacing: 1.17px;
                            line-height: 18px;
                            margin-bottom: 4px;
                            font-size: 14px;
                            transition: $transition;
                            text-align: left;
                        }
        
                        .used {
                            text-align: left;
                            color: rgba(23,36,61,0.7);
                            font-size: 12px;
                            letter-spacing: 0;
                            line-height: 20px;
                        }

                        .already-premium {
                            color: #A38138;
                            font-size: 12px;
                            font-weight: 500;
                            letter-spacing: 0;
                            line-height: 20px;
                            text-align: start;
                        }
                    }
                }
        
                .member-detail:hover {
                    cursor: pointer;
                    .name {
                        color: $gray;
                    }
                }
        
                .menu-btn {
                    .checkbox {
                        float: right;
                        margin-top: 5px;
                    }

                    .self {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}

.subscription-buy-container {
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    margin-top: 30px;
    @include desktop-box-max-width();

    .info-title {

        margin-top: 10px;
        margin-bottom: 30px;

        color: #17243D;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 32px;
    }

    .price {
        padding: 30px;
        background-color: #DBF9FF;

        .price-main {
            color: #17243D;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 32px;
        }

        p {
            color: #17243D;
            font-size: 16px;
            letter-spacing: 0.15px;
            line-height: 24px;
            margin: 5px;
        }

        .info {
            color: rgba(23,36,61,0.5);
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
            text-align: center;
        }
    }


    .subscription-details {
        margin-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 20px;

        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

        background-color: white;

        .subscription-details-info {
            margin-top: 20px;
            margin-bottom: 40px;
            .row {
                padding: 10px;
                p {
                    margin: 0;
                }

                .col:first-child {
                    text-align: left;

                    color: rgba(23,36,61,0.5);
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 60px;
                }

                .col:last-child {
                    text-align: right;
                    color: #17243D;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 60px;
                }
            }

            .row:not(:last-child) {
                border-bottom: 1px solid rgba(23,36,61,0.05);
            }
        }

        .button-container {

            p {
                color: rgba(23,36,61,0.5);
                font-size: 12px;
                letter-spacing: 0;
                line-height: 20px;
                margin-top: 10px;
                margin-bottom: 30px;
            }

            .default-button {
                margin-top: 10px;
            }

            .light-button {
                color: green !important;
            }

        }

    }
    .form-floating > .form-select ~ label {
        opacity: 1;
        transform: scale(0.95) translateY(-1.8rem) translateX(0.15rem);
        background: white;
        letter-spacing: 0.5px;
        width: fit-content;
    }

    .form-floating > label {
        left: 10px;
        top: 16px;
        height: auto;
        padding: 0rem 0rem;
    }

    .form-floating > .form-select {
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
    }

}

.account-purchases-container {
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    margin-top: 20px;
    position: relative;
    @include desktop-box-max-width();
    @include content-min-height();

    .title-text {
        color: #17243D;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 32px;

        margin-top: 20px;
        margin-bottom: 40px;
    }

    .info-text {
        color: rgba(23,36,61,0.7);
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 16px;

        margin: 0;
    }

    .purchases {
        margin: 20px;
        margin-bottom: 40px;
        padding: 20px;

        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

        padding-bottom: 30px;
        background-color: white;

        .purchase {
            padding-bottom: 10px;
            padding-top: 10px;
            p {
                margin: 0;
            }

            .date {
                text-align: left;

                color: rgba(23,36,61,0.7);
                font-size: 12px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 16px;
            }

            .type {
                text-align: left;

                color: #17243D;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 21px;

            }

            .price {
                text-align: right;
                color: #17243D;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 21px;
                text-align: right;

                margin-top: 15px;

            }
        }

        .row:not(:last-child) {
            border-bottom: 1px solid rgba(23,36,61,0.05);
        }
    }
}

.confirm-cancel-modal-body {
    .subtitle {
        color: #17243D;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
    }

    .paragraph-header {
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    p {
        color: #17243D;
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 21px;

        span {
            font-weight: 500;
        }
    }
}

.link-text {
    color: #006238;
    letter-spacing: 0.25px;
    line-height: 21px;
    text-decoration: underline;
}

.link-text:hover {
    cursor: pointer;
}

.profile-tabs {
    .nav-item {
        font-size: 14px;
    }
}
.profile-tabs.submenu-tabs {
    .nav-item {
        font-size: 12px;
    }
}

.tab-content, .profile-container.account {
    .coming-soon-text {
        text-align: center;
        font-size: 18px;
        font-weight: 900;
        margin: 5% 0;
    }
}
