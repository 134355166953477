.settings {

     >div:not(.need-help) {
        max-width: 800px;
        margin: auto;
    }

        .settings-container-newsletters,
        .settings-container,
        .settings-container-media {
            @include content-min-height();
        }

    .setting-group-title {
        width: 100%;
        color: rgba(23,36,61,0.5);
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 24px;
        text-align: center;

        background-color: #F3F4F5;
        text-transform: uppercase;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0;
    }

    .setting-option-row {
        border-bottom: 1px solid #F3F4F5;
        padding: 16px;

        .settings-title {
            color: #17243D;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 18px;
            margin: 0;
            padding-bottom: 8px;
        }

        .settings-description {
            color: rgba(23,36,61,0.5);
            font-size: 12px;
            letter-spacing: 0.4px;
            line-height: 18px;
            margin: 0;
        }

        .icon-container {
            text-align: center;
            margin: auto;
            
            svg {
                color: rgba(23,36,61,0.3);
            }
        }
    }

    .notifications-row {
        display: flex;
        justify-content: space-between;
        margin-right: 10px;
        span {
            flex: 10;
        }
        p {
            flex: 1;
        }
    }

    .need-help {
        margin-top: 77px;
    }

    .setting-option-row:hover {
        cursor: pointer;
        background-color: #f3f4f565;
    }
}

.settings.final {
    padding-top: 40px;
/*
    @media only screen and (min-width: 992px) {
        min-height: 71vh;
    }
    */

    div.row:not(.black) > div > p {
        color: rgba(23, 36, 61, 0.5);
    }

    div.row > div > p:last-child {
        margin-bottom: 40px;
    }    
}

.settings.media-quality {
    padding-top: 40px;

    >div:not(.need-help) {
        max-width: 500px;
        @include desktop-box-max-width();
    }

    .setting-option-row {
        border: 0;
    }    

    .setting-option-row.quality-container {
        padding: 0px;
    }
    
    .unique-single-select-container {
        margin-bottom: 40px;
    }

    p.quality {
        margin-top: 50px;
    }
}

.account-data {
    .account-data-container {
        max-width: 500px !important;

        .current {
            text-align: right;
            color: $base !important;
        }

        .edit-icon {
            color: $base-green;
            width: 18px;
            cursor: pointer;
            &:hover {
                opacity: 0.7;

            }
        }
    }

}

.change-password-modal, .change-email-modal {
    .change-password-form, .change-email-form {
        padding-top: 20px;
        padding-bottom: 20px;

        .warning-message {
            font-size: 12px;
            color: red;
        }

        .group {
            width: 100%;
            margin-bottom: 48px;

            &.email,
            &.password {
                margin-bottom: 36px;
            }

            input {
                width: 100%;
                padding: 13px;
            }

            .bottom-label {
                font-size: 12px;
                line-height: 16px;
                color: $gray;
                margin-left: 16px;
                margin-bottom: 0;
                margin-top: 5px;
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .notification-settings {
        min-height: 71vh;
    }
}

.notification-settings {
    min-height: 38vh;
}

.settings-container-newsletters {
    .switch-status {
        text-align: right;
        padding-right: 30px;
    }
}
