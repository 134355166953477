.welcome-container {
  text-align: center;
  padding: 50px;

  button {
    margin-bottom: 1rem;
  }

  .lead {
    margin-bottom: 1rem;
    margin-top: 3rem;  
  }

  .motto {
    margin-bottom: 3rem;
    font-size: 16px;
  }

  img.logo {
    height: 110px;
  }

  a {
    border: none;
    background: none;
    color: #006238;
    text-transform: uppercase;
    padding: 1rem;
    margin-bottom: 1rem;
    text-decoration: none;
    display: block;
    letter-spacing: 1.25px;
  }

  img.item-image {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    object-fit: cover;
    border-radius: 50%;
  }

  .join {
    margin-bottom: 50px;
    font-size: 1.15rem;
  }

  .open-by-link {
    margin-bottom: 50px;
    cursor: pointer;
  }

  .usertips-container {
    max-width: 800px;
    margin: auto;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: center;
    background-color: white;
    margin-bottom: 50px;
  
    .usertips-content {
        margin: 0 40px;
        padding: 0 60px 0 60px;
        border: 0;
        @media (max-width: 540px) {
          padding: 0;
          margin: 0 0;
        }
  
        .why-stories-matter-img {
            width: 100%;
            height: auto;
            cursor: pointer;
        }
  
        small {
            display: block;
            color: $base;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 1.25px;
            line-height: 16px;
            text-align: center;
            text-transform: uppercase;
            margin-top: 20px;
            margin-bottom: 20px;
        }
  
        h3.usertips-title {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 18px;
            text-align: center;
            margin-bottom: 10px;
        }
  
        p.usertips-author {
            color: rgba(23, 36, 61, 0.5);
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            margin-bottom: 20px;
        }
  
        p.usertips-main-content {
            /*font-size: 12px;*/
            letter-spacing: 0.25px;
            line-height: 18px;
            text-align: center;
        }
  
        a.usertips-button {
            display: block;
            text-align: center;
            font-size: 12px !important;
            letter-spacing: 0.25px;
            line-height: 18px !important;
        }
    }

    @media screen and (max-width: 760px){
      .usertips-content {
        margin: 0;
        padding: 0;
      }
    }

    button.usertips-share-button {
        position: absolute;
        bottom: 14px;
        left: 50%;
        width: 201px;
        transform: translateX(-50%);
    }
  }
}

.modal.video-welcome-modal {
  padding-left: 0;
  .modal-dialog {
    @media (min-width: 1000px) {
      max-width: 55%;
    }
  }

  .modal-iframe-custom {
    text-align: center;
        
    iframe {
      width: 100%;
      height: 500px;
      @media (max-width: 890px) {
        height: 300px;
      }
    }
  }
}