.unpublished-stories {
    padding-bottom: 80px;
   // width: 100%;
    margin: auto;
    @include desktop-box-max-width();

    @include content-min-height();

    .information {
        text-align: center;
        color: $gray;
        font-size: 13px; /* 12px; */
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .up-story {
       // width: 100%;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 20px;
        //margin-bottom: 20px;
        position: relative;
        margin: 0 auto 20px auto;
        max-width: 700px;

        .story-title {
            font-size: 16px;
            color: $base-green;
            font-weight: 500;
            padding-bottom: 10px;
        }

        .author {
            display: inline-block;
            margin-left: 10px;
            margin-bottom: 0;
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
        }

        .user-picture {
            width: 32px;
            height: 32px;
        }

        .last-modified {
            // display: inline-block;
            position: absolute;
            bottom: 27px;
            right: 30px;
            font-size: 12px;
            color: $gray;
            margin-bottom: 0;
        }

        .flair {
            position: absolute;
            bottom: 20px;
            right: -10px;
            width: 30px;
            height: 21px;
        }
    }

    .up-story:hover {
        cursor: pointer;
        background-color: $lightgray;
    }
}