.someone-is-missing {
    margin-top: 8px;
    padding: 36px 41px;
    background-color: #fff3db;
    width: 100vw;
    position: relative;
    left: -18px;
    margin-bottom: 48px;

    @media screen and (min-width: 480px) {
        position: static;
        width: 100%;
    }

    .title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 12px;
    }

    .text {
        margin-bottom: 18px;
    }

    button {
        font-size: 14px !important;
        line-height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .plus {
            font-size: 30px;
            font-weight: 400;
            margin-right: 10px;
        }

        .btn-text {
            padding-top: 7px;
        }
    }
}
