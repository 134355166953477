.img-upload {

    .selected {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .selected-container {
        margin:0 -20px;

        .selected {
            margin-bottom: 4px;
        }

        .add-more-cont {
            position: absolute;

            right: 20px;
            top: 40px;

            svg {
                color: $base-green;
            }
            svg:hover {
                cursor: pointer;
                color: $base-green-hover;
            }
        }
    }

    .sub-selected {
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: $gray;
        margin-bottom: 0;
    }

    .upload-container {
        text-align: center;
    }

    .carousel-info {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 90px;
        margin-right: 90px;

        font-size: 12px;
        font-weight: 400;
        color: $gray;
    }

    .upload-drag-and-drop {
        width: 100%;
        height: 150px;
        background-color: rgba(23,36,61,0.05);
        border: 1px dashed rgba(23,36,61,0.12);

        text-align: center;

        svg {
            margin-top: 35px;
            color: $base-green;
        }

        p {
            font-size: 16px;
            letter-spacing: 0.15px;
            line-height: 24px;
            color: rgba(23,36,61,0.5);
            font-weight: bold;
        }
    }

    .upload-drag-and-drop:hover{
        cursor: pointer;
    }

    .addedImages {
        margin-top: 15px;
        margin-bottom: 15px;

        .image-container {
            position: relative;
            @media screen and (max-width: 550px) {
                width: 50%;
            }

            @media (min-width: 768px) and (max-width: 991px) {
                width: 33.33%;
            }

            @media (min-width: 551px) and (max-width: 768px) {
                width: 25%;
            }

            svg {
                position: absolute;
                bottom: 13px;
                right: 15px;
                color: white;
                @media (min-width: 401px) and (max-width: 550px) {
                    bottom: 10px;
                    left: 62%;
                }
                @media (min-width: 320px) and (max-width: 400px) {
                    left: 66%;
                    bottom: 10px;
                }
            }
    
            img {
                width: 110px;
                height: 110px;
                margin-bottom: 5px;
                margin-top: 5px;
                object-fit: cover;
                @media screen and (max-width: 550px) {
                    width: 120px;
                    height: 120px;

                 }
            }

            video {
                width: 110px;
                height: 110px;
                margin-bottom: 5px;
                margin-top: 5px;
            }
        }
    }

    .active {
        background-color: $gold;
        border-radius: 30px;
    }


    .empty-page {
        text-align: center;
        padding-left: 50px;
        padding-right: 50px;

        .icon-group-container {
            margin-left: -16px;
            .icon-group {
                position: relative;
                margin-left: auto;
                margin-right: auto;
                margin-top: 30px;
                margin-bottom: 60px;
                width: 145px;
                height: 140px;
                display: grid;
                grid-template-columns: 50px 50px;
                justify-content: center;

                .image-icon {
                    margin-top: 40px;
                    margin-bottom: 80px;
                    color: RGBA(23, 36, 61, 0.3);
                }

                svg {
                    margin: 0 !important;
                }

                .album {
                    position: absolute;
                    top: 10px;
                    left: 15px;
                }

                .flare {
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                }

                .move {
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                }

                .star {
                    position: absolute;
                    top: 0px;
                    right: -5px;
                }
            }

        }


        .upload-container-button {

            .button-label {
                background-color: $base-green !important;
                color: white !important;
                width: 100%;

                padding-bottom: 15px;
                padding-top: 15px;

                font-size: 14px !important;
                font-weight: 500 !important;
                text-transform: uppercase;
                margin-bottom: 40px;

                &:hover {
                    background-color: $base-green-hover !important;
                    cursor: pointer;
                }

                svg {
                    margin-left: 10px;
                    vertical-align: middle;
                }
            }
        }

        .grey-text {
            font-size: 12px;
            color: RGBA(23, 36, 61, 0.5);
            font-weight: 400;
        }
    }

    .sub-text-info {
        color: rgba(23,36,61,0.5);
        font-size: 12px;
        letter-spacing: 0.5px;
        line-height: 18px;
        margin-bottom: 70px;
    }

    .switch-container {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        
        .infos {
            span {
                color: $base;
                font-size: 16px;
                letter-spacing: 0.15px;
                line-height: 24px;
            }
        }

        .status {
            display: flex;
            align-items: center;
            color: $gray;

            .switch-status {
                float: right;
                margin-right: 10px;
                font-size: 14px;
                font-weight: 400;
                color: $gray;
                margin-bottom: 0;
            }
        }
    }

    .add-button {
        position: relative;
        .progressbar {
            height: 5px;
            width: 100%;
            position: absolute;
            bottom: 0;
            background-color: transparent;
            left: 0;
            transition: all 0.2s linear;
            .progress-bar {
                background-color: $base-green;
            }
        }
    }
}

.video-upload {

    .selected {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .sub-selected {
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: $gray;
        margin-bottom: 0;
    }

    .change-video {
        margin-top: 40px;
    }

    .selected-container {
        margin-bottom: 30px;
        margin-top: 0;
        .selected {
            margin-bottom: 4px;
        }

        .add-more-cont {
            position: absolute;

            right: 20px;
            top: 40px;

            svg {
                color: $base-green;
            }
            svg:hover {
                cursor: pointer;
                color: $base-green-hover;
            }
        }
    }

    .video {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;    
        color: RGBA(23, 36, 61, 0.3);
        text-align: center;
    }    

    .upload-drag-and-drop {
        width: 100%;
        height: 150px;
        background-color: rgba(23,36,61,0.05);
        border: 1px dashed rgba(23,36,61,0.12);

        text-align: center;
        margin-bottom: 50px;
        /*margin-bottom: 150px;*/

        svg {
            margin-top: 35px;
            color: $base-green;
        }

        p {
            font-size: 16px;
            letter-spacing: 0.15px;
            line-height: 24px;
            color: rgba(23,36,61,0.5);
            font-weight: bold;
        }
    }

    .upload-drag-and-drop:hover{
        cursor: pointer;
    }

    .upload-container-button {
        .button-label {
            background-color: $base-green !important;
            color: white !important;
            width: 100%;

            padding-bottom: 15px;
            padding-top: 15px;

            font-size: 14px !important;
            font-weight: 500 !important;
            text-transform: uppercase;
            margin-bottom: 40px;

            &:hover {
                background-color: $base-green-hover !important;
                cursor: pointer;
            }

            svg {
                margin-left: 10px;
                vertical-align: middle;
            }
        }
    }

    .add-button {
        position: relative;
        .progressbar {
            height: 5px;
            width: 100%;
            position: absolute;
            bottom: 0;
            background-color: transparent;
            left: 0;
            transition: all 0.2s linear;
            .progress-bar {
                background-color: $base-green;
            }
        }
    }
}