.modal {
    backdrop-filter: blur(1px);

    &:has(.rich-text-editor-container) {
        padding-left: 0!important;
        padding-right: 0!important;
    }

    @media screen and (min-width: 992px) {
        padding-left: 250px;
    }

    @media (min-width: 768px) {
        text-align: left;
        display: flex !important;
        align-items: center;
    }

    .modal-dialog.block-picker-modal.text-editor-width {
        @media (min-width: 780px) {
            max-width: 75%;
            .modal-body {
                padding-bottom: 8px;
            }
            .modal-content {
                .modal-header {
                    background-color: white;
                    z-index: 9;
                }
            }

            .text-editor-wrapper-class {
                height: 75vh;
                overflow: visible;
            }
        }

        @media (max-width: 799px) {
            max-width: 100%;
            .modal-body {
                padding-bottom: 8px;
            }
            .modal-content {
                .modal-header {
                    background-color: white;
                    z-index: 9;
                }
            }
        }
    }

    .modal-dialog.block-picker-modal.text-editor-with-fullscreen {
        max-height: 100%;
        @media (min-width: 780px) {
            max-width: 100%;
        }

        .text-editor-wrapper-class {
            height: 85vh;
            overflow: visible;
        }
    }

  /*  .modal-dialog.block-picker-modal.text-editor-width-no-fullscreen {
        @media (min-width: 1000px) {
            max-width: 55%;
        }
    }
        //removed until a new decision from Andrew
    */

    .modal-dialog {
        margin: 0;
        position: absolute;
        bottom: 0;
        max-width: none;
        width: 100%;
        max-height: 98%;

        .need-help {
            margin-top: 1rem;

            .need-help-header {
                background-color: white;
                border-bottom: 0;               
            }
        }

        @media (min-width: 768px) {
            max-width: 500px;
            margin: auto;
            position: relative;
        }

        .modal-content {
            border: none;
            border-radius: 8px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            height: 90%;

            @media (min-width: 768px) {
                border-radius: 8px;
                margin-left: 0px;
            }

            .modal-header {
                position: relative;
                display: block;
                text-align: center;

                .title,
                .modal-title {
                    display: inline-block;
                    line-height: 36px;
                    margin-bottom: 0;
                    font-weight: 600;
                    font-size: 20px;
                }

                .btn-close {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    padding: 0 8px;
                }
            }
            .modal-body {
                .image {
                    width: 100%;
                    height: auto;
                }
                
                .input {

                    .inner {
                        padding: 20px 40px 20px 40px;
                        @media (min-width: 320px) and (max-width: 360px) {
                            padding: 20px 10px 20px 10px;
                        }
                    }
                }

                .inputs {
                    padding: 20px;
                    /*
                    margin-top: 40px;
                    margin-bottom: 40px;
                    */
                }                
            }
        }
    }
}

.fixed-modal {
    @media (min-width: 768px) {
        margin-top: 100px !important;
    }
}

.access-modal {
    text-align: center;

    .modal-header {
        display: inline-block;
        line-height: 36px;
        margin-bottom: 0;
        font-weight: 600;
        font-size: 20px;
    }
    .modal-body {
        p:first-child {
            color: $gold;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 16px;
            margin-top: 5px;
        }
        .info-text {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        button {
            margin-bottom: 70px;
        }
    }
}