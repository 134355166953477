.find-your-family-container {
    text-align: center;
    padding: 36px 0 40px 0;
    margin-bottom: 48px;
    background-color: #FFF3DB;
    width: 100vw;
    position: relative;
    left: -18px;

    @media screen and (min-width: 480px) {
        position: static;
        width: 100%;
    }

    .title {
        font-size: 16px;
        margin-bottom: 12px !important;
    }

    .text {
        font-weight: 400 !important;
        font-size: 14px !important;
        max-width: 290px;
        margin: auto;
        margin-bottom: 34px;
    }

    button {
        font-size: 14px !important;
        line-height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .plus {
            font-size: 30px;
            font-weight: 400;
            margin-right: 10px;
        }

        .btn-text {
            padding-top: 7px;
        }
    }

}