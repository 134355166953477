.publish-page {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px; /* 34px; */
    margin-bottom: 20px;
    padding-top: 20px;    
    @include desktop-box-max-width();

    .private-access-container {

        .notification-container {

            margin-top: 40px;
            margin-bottom: 60px;
            padding-bottom: 40px;
            
            .notification-title {
                margin-bottom: 20px;
            }

            .notification-body {
                text-align: left;
            }
        }
    }

    .access-to-friends-container {

        margin-bottom: 5px;
        background-color: #f3f4f5;
        margin-top: 16px;
        border-radius: 16px;
        padding-bottom: 2px;

        .name-list {
            font-size: 12px;
            color: $gray;
            letter-spacing: 0.25px;
            line-height: 18px;
            padding: 8px 12px 0 12px;
            margin-bottom: 13px;
            background-color: #f3f4f5;
        }

        .group-item {
            border: 1px solid rgba($base, 0.3);
            padding: 4px;
            display: flex;
            align-items: center;
            border-radius: 16px;
            background-color: white;

            .icon {
                height: 24px;
                width: 24px;
                background-color: rgb(211, 104, 104);
                border-radius: 50px;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    color: $secondary;
                    font-size: 12px;
                }
            }


            p {
                display: inline-block;
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
                font-size: 14px;
                letter-spacing: 0.13px;
                color: $base;
                padding: 0 10px;
                flex-grow: 1;
                margin-bottom: 0;
            }
        }
    }

    .basics {
        margin-bottom: 40px;
        .title {
            margin-bottom: 40px;
        }

        .padded {
            margin-top: 30px;
        }
    }
    .separator {
        position: absolute;
        left: -42px;
        width: calc(100% + 42px);
        border-bottom: 1px solid #e7e9eb;
    }

    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 500px;
        margin: auto;
        margin-top: 34px;

        .back {
            flex: 1;
            max-width: 200px;
            margin-right: 20px;
        }

        .next {
            flex: 2;
            margin-left: 0;
            margin-right: 0;
        }

        .left {
            margin: 10px;
            margin-left: 0;
        }

        .right {
            margin: 10px;
            margin-right: 0;
        }

    }

    .other-options {
        @media screen and (max-width: 400px) {
            display: block;
            width: 100%;
            text-align: center;

            button {
                margin-bottom: 20px;
            }

            span {
                display: block !important;
                margin-bottom: 20px;                
            }
        }

        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;

        span {
            display: inline-block;
            padding-right: 8px;
            padding-left: 8px;
        }

        button {
            border: none;
            background: none;
            color: $red;
            text-transform: uppercase;
        }
    }

    .title {
        text-align: center;
    }

    .explanation {
        color: $gray;
        font-size: 13px;
        padding-bottom: 20px;
    }

    .explanation span {
        text-decoration: underline;
    }    

    .optional {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 5px;
        padding-right: 5px;

        .switch-container {
            display: flex;
            justify-content: space-between;
            max-width: 500px;
            align-items: center;
            margin: 20px 0;
            @include desktop-box-max-width-100();

            &:first-of-type {
                margin-top: 40px !important;
            }
            &:last-of-type {
                margin-bottom: 40px;
            }

            svg {
                cursor: pointer;
                color: $gray;
                margin-left: 10px;
                padding: 2px;
            }

            .infos > span {
                font-size: 16px;
            }

            .status {
                display: flex;
                align-items: center;
                color: $gray;

                .switch-status {
                    float: right;
                    margin-right: 10px;
                    font-size: 14px;
                    font-weight: 400;
                    color: $gray;
                    margin-bottom: 0;
                }

                .switch {
                    .react-switch-bg {
                        opacity: 0.15;
                    }
                }
            }
        }

        textarea {
            width: 100%;
            margin: 20px auto;
        }

        .private-sharing-url-container {
            padding: 10px;

            .info-text {
                margin-bottom: 0;
                word-wrap: break-word;
            }
    
            .button-container {
                margin-top: 0;
            }

            .input-container {

                .group {
                    width:75%;
                    display: inline-flex;
                }
                // input {
                //     width: 75%;
                //     display: inline-flex;
                // }

                .add-button {
                    background-color: $base-green !important;
                    color: white !important;
                    height: 56px;
                    font-weight: 500 !important;
                    letter-spacing: 1.25px;
                    text-transform: uppercase;
                    border-radius: 0px !important;
                    border: none !important;
                    box-shadow: none !important;
                    transition: all 0.2s linear;
                    text-align: center;

                    float:right;
                    width: 20%;
                }

                .add-button:hover {
                    background-color: $base-green-hover !important;
                }

                .info-text {
                    margin-left: 15px;
                    margin-top: 5px;
                    color: rgba(23,36,61,0.5);
                    font-size: 12px;
                    letter-spacing: 0.4px;
                    line-height: 18px;
                }
            }

            .input-container.emails {
                margin-top: 25px;
            }            

            .icon-container {
                margin-right: 10px;
            }

            .refresh {
                display: inline-block;
            }

            .refresh:hover {
                cursor: pointer;
                color: rgba(23,36,61,0.5);
            }

            .link-type {
                display: inline-flex;
                text-decoration: underline;
                color: $base-green;
            }
            .link-type:hover {
                cursor: pointer;
                color: $base-green-hover;
            }

            .copy {
                display: inline-flex;
                color: rgba(23,36,61,0.5);
                font-size: 12px;
                letter-spacing: 0.4px;
                line-height: 18px;
                float: right;
            }

            .added-emails {
                margin-top: 20px;
                margin-bottom: 20px;
                padding: 10px;

                .email {
                    border: 1px solid rgba($gray, 0.3);
                    padding: 4px;
                    display: inline-flex;
                    align-items: center;
                    width: fit-content;
                    border-radius: 16px;
                    margin-bottom: 8px;
                    margin-right: 5px;
                    height: 32px;
                    max-width: 100%;
                }


                p {
                    display: inline-block;
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    font-size: 12px;
                    letter-spacing: 0.13px;
                    color: $base;
                    padding: 0 10px;
                    flex-grow: 1;
                    margin-bottom: 0;
                }


                .icon {
                    height: 24px;
                    width: 24px;
                    background-color: $blue;
                    border-radius: 50px;
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        color: $secondary;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .group-selection {
        position: relative;

        .select {
            color: gray !important;
        }

        .line {
            z-index: 1;
            width: 100vw;
            max-width: 500px;
            height: 1px;
            background-color: rgba(23, 36, 61, 0.1);
            overflow: visible;
        }

        .title-wrapper {
            padding: 30px;
            text-align: center;

            .link-button {
                margin-bottom: 0 !important;
            }

            .action-title {
                display: inline-flex;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.43px;
                line-height: 16px;

            }

            svg {
                position: absolute;
                right: 40px;
                display: inline-flex;
                color: $gray;
            }
        }

        .access-to-friends-container {
            .icon {
                background-color: $blue;
            }

            .remove-icon {
                svg {
                    color: $gray;
                }
            }
        }

        .automatic {
            .icon {
                background-color: rgb(224, 104, 104);
            }  
        }

        .missing-people {
            margin-bottom: 60px;
            .title {
                text-align: center;
                margin-top: 60px;
                margin-bottom: 30px;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.5px;
                line-height: 24px;
            }
        }

        .box-container {

            .extra-individuals-container {
                padding: 20px;
                .description {
                    text-align: center;
                    margin-bottom: 48px;
                }
                .unique-list-input-container {
                    margin-bottom: 48px;
                }
            }
        }

        .group-edit-container {
            padding: 25px 40px 50px 40px;
            .description {
                text-align: center;
                margin-bottom: 36px;
            }
        }

        .active {
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

            .title-wrapper {
                background-color: $lightgray;

                .link-button {
                    color: #17243D !important;
                }
            }
        }
    }
}

.story-delete-confirm-modal {

    backdrop-filter: blur(1px);

    .modal-body {
        font-size: 14px;
        line-height: 21px;
        text-align: center;

        .text {
            margin-top: 20px;
            margin-bottom: 30px;
        }

        p {
            margin: 0;
        }
    }

    .modal-dialog {
        position: absolute;
        bottom: 0;
        margin: 0;
        width: 100%;
        max-width: none;

        @media (min-width: 768px) {
            max-width: 500px;
            margin: auto;
            position: relative;
        }
    }
}

.create-container {

    .story-builder {
        @include content-min-height();
    }

    .comment-section {
        @include desktop-box-max-width();
    }

    .public-story-page.attached-inspiration {
        .new-block {
            display: none;
        }
    }

    .title {
        /* margin-top: 30px; */ /* caused error at need help section, create request page */
    }

    .dot-notification-container {
        position: relative;

        p {
            margin:0;
        }

        .dot {
            position: absolute;
            height: 18px;
            width: 18px;
            background-color: #EF5A5A;
            border-radius: 50%;
            margin-top: 10px;
    
            top: -17px;
            right: -12px;
    
            color: white;
    
            p {
                /* margin-right: 7px; */
                font-size: 10px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 20px;
                text-align: center;
              
            }
        }

    }

    .unique-single-select-container.group {
        margin-bottom: 4px;
    }

    p.explanation {
        padding-left: 15px;
    }

    .co-author-comment-title {
        margin-top: 36px;
    }
}

.locked-story-modal {

    .modal-body {
        text-align: center;

        .user-picture {
            height: 80px;
            width: 80px;
            margin: auto;
            display: block;
            object-fit: cover;
            border-radius: 50%;
        }

        .title-text {
            font-weight: 500;
            font-size: 17px;
            margin-top: 20px;
            margin-bottom: 40px;
        }

        .name {
            font-weight: 500;
            font-size: 15px;
            text-transform: uppercase;
            margin-top: 20px;
            margin-bottom: 0;
        }

        .info-text {
            font-size: 14px;
            margin-bottom: 100px;
        }
    }
}

.request-access-story-modal {
    .modal-body {
        text-align: center;

        .user-details {
            margin-bottom: 30px;
            margin-top: 10px;
            p {
                font-weight: 500;
                font-size: 15px;
                text-transform: uppercase;
                margin-top: 10px;
                margin-bottom: 0;
            }
            .user-picture {
                height: 80px;
                width: 80px;
                margin: auto;
                display: block;
                object-fit: cover;
                border-radius: 50%;
            }
        }
    }

    .would-like {
        font-size: 18px;
        font-weight: bold;
    }

    .story-title {
        margin: 30px;
    }

    .info-text {
        font-weight: bold;
        margin: 40px;
    }

    .default-button {
        margin-bottom: 20px;
    }

    .danger-button {
        margin-bottom: 60px;
    }
}

.modal-body.publish-page{
    margin: 0 auto;
    .optional {
        background-color: transparent;
        padding-top: 15px;
        padding-bottom: 0;
    }

    .buttons {

        margin-top: 1rem;
        text-align: center;

        button.link-button {
            padding: 1rem !important;
            margin-bottom: 1rem;
        }
    }
}