.family-group-details-modal {
    .modal-header {
        text-align: center;
    }

    .modal-body {
        padding: 0px;

        .group-actions {
            margin-top: 46px;
            margin-bottom: 46px;

            padding-left:50px;
            padding-right:50px;

            > div {
                padding: 18px 0;
                border-bottom: 1px solid rgba($base, 0.1);
                text-align: center;
                position: relative;
                cursor: pointer;

                &:hover {
                    .action-title {
                        color: $base !important;
                    }
                }

                .action-title {
                    display: inline-block;
                    margin: 0;
                    letter-spacing: 0.5px;
                }

                .group-action-icon {
                    position: absolute;
                    right: 15px;
                    top: 18px;
                    color: rgba($base, 0.3);
                }

                .unique-list-input-container {
                    margin-bottom: 36px;

                    .example-story-list {
                        text-align: left;
                    }
                }

                &.prev {
                    border: none;
                }

                &.active {
                    box-shadow: 0 4px 5px 0 rgba($base, 0.14), 0 1px 10px 0 rgba($base, 0.12), 0 2px 4px -1px rgba($base, 0.2);
                    margin-top: 10px;
                    margin-bottom: 22px;
                    padding: 0;
                    cursor: auto;

                    .title-wrapper {
                        background-color: #f4f5f6;
                        padding: 20px 0;
                        border-bottom: 1px solid rgba($base, 0.1);
                        cursor: pointer;

                        .action-title {
                            color: $base !important;
                        }
                    }

                    .content {
                        padding: 24px 16px 36px 16px;
                        text-align: left;

                        input {
                            margin-bottom: 36px;
                        }

                        button {
                            height: 40px;
                        }
                    }
                }

                &.delete {
                    &:hover {
                        .action-title {
                            color: $base !important;
                        }
                    }

                    .action-title {
                        color: #ef5a5a !important;
                    }

                    .content {
                        button {
                            background-color: #ef5a5a !important;
                            height: 40px;
                            margin-top: 36px;

                            &:hover {
                                background-color: #e97070 !important;
                            }
                        }

                        ul {
                            list-style: none;
                            padding: 0;
                            margin-left: 10px;
                            margin-bottom: 20px;

                            li {
                                font-size: 13px;
                                line-height: 18px;
                                letter-spacing: 0.4px;
                                margin-bottom: 15px;

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                &::before {
                                    content: "";
                                    width: 4px;
                                    height: 4px;
                                    border-radius: 50%;
                                    background-color: $gray;
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin-right: 10px;
                                    margin-bottom: 4px;
                                }
                            }
                        }

                        .link {
                            color: $base-green;
                            text-align: center;
                            margin: 0;
                            font-size: 13px;
                        }

                        .link:hover {
                            cursor: pointer;
                            color: $base-green;
                        }
                    }
                }

                &.reassign {
                    .content {
                        .content-title {
                            margin-bottom: 36px;
                            text-align: center;
                        }

                        input {
                            margin-bottom: 36px;
                        }

                        button {
                            margin: 30px auto;
                        }

                        .bottom-text {
                            font-size: 13px;
                            color: $gray;
                            margin: 0;
                            line-height: 18px;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .only-member {
            .container {
                
                margin-top: 20px;

                .only-member-title {
                    font-size: 13px;
                    color: rgba($base, 0.7);
                    margin-bottom: 12px;
                    line-height: 16px;
                }

                .only-member-description {
                    font-size: 16px;
                    color: $base;
                    line-height: 24px;
                    margin-bottom: 36px;
                }

                .admin-name {
                    font-size: 14px;
                    color: $base-green;
                    text-decoration: underline;
                    margin-bottom: 36px;
                    cursor: pointer;
                }

                .member {
                    margin-bottom: 14px;

                    img {
                        display: inline;
                        margin-right: 10px;
                    }
                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: $base;
                        display: inline;
                        vertical-align: middle;
                    }
                }
            }

            .group-actions {
                margin-top: 60px;

                > div {
                    padding: 0;
                    border: none;
                    cursor: pointer;

                    p {
                        margin: 0;
                        letter-spacing: 0.5px;
                    }

                    .group-action-icon {
                        top: -1px;
                    }
                }
            }
        }
    }
}

.update-group-container {
    padding: 24px 50px 0px 50px;

    button {
        margin-top: 20px;
    }

    .manager {
        line-height: 21px;
        text-align: center;
        margin-bottom: 36px;

        span {
            color: $base-green;
            text-decoration: underline;
        }
    }

    .goal {
        .tooltip-text {
            float: left;
            margin-top: 10px;
        }
    }

    .successors {
        .unique-list-input-container {
            margin-bottom: 10px !important;
            margin-top: 48px;
        }
    }

    .group {

        .sub-subtitle {
            color: rgba(23, 36, 61, 0.5);
            font-size: 13px;
            text-align: center;
            margin-bottom: 25px;
            line-height: 21px;
        }
        
        .right {
            text-align: right;
            padding-left: 13px;
        }
    }

    .group.goal {
        margin-bottom: 3rem;
    }

    .private-sharing-url-container {
        padding: 10px;

   
        .info-text {
            margin-bottom: 0;
            word-wrap: break-word;
        }

        .button-container {

            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 500px;
            margin: auto;
            margin-top: 0;
    
            .left {
                margin: 10px;
                margin-left: 0;
            }
    
            .right {
                margin: 10px;
                margin-right: 0;
            }                
        }            
                
        .input-container {

            .group {
                width:75%;
                display: inline-flex;
            }
            // input {
            //     width: 75%;
            //     display: inline-flex;
            // }

            .add-button {
                background-color: $base-green !important;
                color: white !important;
                height: 56px;
                font-weight: 500 !important;
                letter-spacing: 1.25px;
                text-transform: uppercase;
                border-radius: 0px !important;
                border: none !important;
                box-shadow: none !important;
                transition: all 0.2s linear;
                text-align: center;

                float:right;
                width: 20%;
            }

            .add-button:hover {
                background-color: $base-green-hover !important;
            }

            .info-text {
                margin-left: 15px;
                margin-top: 5px;
                color: rgba(23,36,61,0.5);
                font-size: 12px;
                letter-spacing: 0.4px;
                line-height: 18px;
            }
        }

        .icon-container {
            margin-right: 10px;
        }

        .refresh {
            float: right;
        }

        .refresh:hover {
            cursor: pointer;
            color: rgba(23,36,61,0.5);
        }

        .link-type {
            display: inline-flex;
            text-decoration: underline;
            color: $base-green;
        }
        .link-type:hover {
            cursor: pointer;
            color: $base-green-hover;
        }

        .copy {
            display: inline-flex;
            color: rgba(23,36,61,0.5);
            font-size: 12px;
            letter-spacing: 0.4px;
            line-height: 18px;
            float: right;
        }

        .added-emails {
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 10px;

            .email {
                border: 1px solid rgba($gray, 0.3);
                padding: 4px;
                display: inline-flex;
                align-items: center;
                width: fit-content;
                border-radius: 16px;
                margin-bottom: 8px;
                margin-right: 5px;
                height: 32px;
                max-width: 100%;
            }


            p {
                display: inline-block;
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
                font-size: 12px;
                letter-spacing: 0.13px;
                color: $base;
                padding: 0 10px;
                flex-grow: 1;
                margin-bottom: 0;
            }


            .icon {
                height: 24px;
                width: 24px;
                background-color: $blue;
                border-radius: 50px;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    color: $secondary;
                    font-size: 16px;
                }
            }
        }
    }

    .switch-container {
        display: flex;
        justify-content: space-between;
        max-width: 500px;
        align-items: center;
        @include desktop-box-max-width-100();

        &:first-of-type {
            margin-top: 40px !important;
        }
        &:last-of-type {
            margin-bottom: 40px;
        }

        svg {
            cursor: pointer;
            color: $gray;
            margin-left: 10px;
            padding: 2px;
        }

        .infos > span {
            font-size: 16px;
        }

        .status {
            display: flex;
            align-items: center;
            color: $gray;

            .switch-status {
                float: right;
                margin-right: 10px;
                font-size: 14px;
                font-weight: 400;
                color: $gray;
                margin-bottom: 0;
            }

            .switch {
                .react-switch-bg {
                    opacity: 0.15;
                }
            }
        }
    }    
}
