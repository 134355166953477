.media-usage-widget {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

    .usage {
        padding: 30px;
        border-bottom-left-radius: 300px 50px;
        border-bottom-right-radius: 300px 50px;

        .usage-text {
            /*color: #006238;*/
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.5px;
            line-height: 30px;
        }

        .usage-info {
            color: #17243D;
            font-size: 16px;
            letter-spacing: 0.5px;
            line-height: 24px;
        }
    }

    .emoji-conatiner {
        position: relative;
        height: 20px;

        .emoji {
            position: relative;
            bottom: 25px;
            width: 49px;
            height: 49px;
        }
    }


    .info-text-container {
        margin-top: 20px;
        /* margin-bottom: 20px; */
        padding-bottom: 20px;

        p:first-child {
            color: #17243D;
            font-size: 14px;
            letter-spacing: 0.25px;
            line-height: 21px;
        }

        .gray-text {
            color: rgba(23,36,61,0.5);
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
        }
    }

    .details-container {
        .title-text {
            color: #17243D;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 32px;
        }
    }

    .button-container {
        margin-top: 30px;
        p {
            margin-top: 10px;
            padding-bottom: 40px;
            color: rgba(23,36,61,0.5);
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
        }
    }

}