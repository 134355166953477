
@mixin desktop-medium-sized-model() {
    @media (min-width: 1400px) {
        max-width: 55%;
    }

    @media (min-width: 1000px) and (max-width: 1399px) {
        max-width: 65%;
    }

    @media (min-width: 769px) and (max-width: 999px) {
        max-width: 75%;
    }
}

@mixin desktop-box-max-width() {
    @media screen and (max-width: 760px) {
        margin: 0 20px 20px 20px;
    }
}

@mixin desktop-box-max-width-100() {
    /*@media screen and (min-width: 1000px) {
        max-width: 100%;
    }*/
}

@mixin general-desktop-container() {
    //create or modify story container width 
    @media screen and (min-width: 993px) {
        max-width: 55%;
    }

    @media screen and (min-width: 1000px) {
        max-width: 70%;
    }
}

@mixin media-default-settings() {
    width: auto;
    height: 350px;
    flex-shrink:0;
    -webkit-flex-shrink: 0;
    max-width:100%;
    max-height:100%;
    object-fit: contain;

    @media screen and (min-width: 1800px) {
        height: 780px;
    }

    @media (min-width: 1400px) and (max-width: 1800px) {
        height: 710px;
    }

    @media (min-width: 1100px) and (max-width: 1399px) {
        height: 600px;
    }

    @media (min-width: 1000px) and (max-width: 1099px) {
        height: 450px;
    }

    @media screen and (max-width: 769px) {
        height: 250px;

    }

    @media (min-width: 770px) and (max-width: 999px)  {
        height: 300px;
    }
}

@mixin carousel-image-white-space() {
    @media screen and (min-width: 1800px) {
        padding: 3% 0;
    }

    @media (min-width: 1400px) and (max-width: 1800px) {
        padding: 4% 0;
    }

    @media (min-width: 1000px) and (max-width: 1399px) {
        padding: 5% 0;
    }

    @media screen and (max-width: 769px) {
        padding: 0;
    }
}


@mixin media-default-settings-one-img() {
    flex-shrink:0;
    -webkit-flex-shrink: 0;
    max-width:100%;
    max-height:90%;
    height: 350px;
    width: auto;
    object-fit: contain;

    @media screen and (min-width: 1800px) {
        height: 780px;
    }

    @media (min-width: 1400px) and (max-width: 1800px) {
        height: 710px;
    }

    @media (min-width: 1100px) and (max-width: 1399px) {
        height: 600px;
    }

    @media (min-width: 1000px) and (max-width: 1099px) {
        height: 450px;
    }

    @media screen and (max-width: 769px) {
        height: 250px;
    }
}

@mixin story-box-small() {
   // TEXT BLOCKS FOR COAUTHORS / LIFE LESSON BLOCK / ATTACHMENTS BLOCK / EMBED BLOCK / AUDIO BLOCKS: set a 'max width' so these are all the same width on the page.  
    @media screen and (min-width: 1000px) {
        margin: 0 15%;
    }
}

@mixin story-box-medium() {
    // text margin at story view page
    @media screen and (min-width: 1000px) {
        margin: 0 auto;
        max-width: 700px;
    }
}

@mixin comment-width-section() {
    @media screen and (min-width: 1000px){
        max-width: 70%;
    }
}

@mixin display-story-text() {
    @media (min-width: 1000px) {
        font-size: 18px;
        letter-spacing: 0.5px;
        line-height: 27px;
    }
}

@mixin content-min-height() {
    @media only screen and (min-width: 992px) {
         min-height: 60vh;
     }

     /* Portrait */
    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1366px) 
    and (orientation: portrait) {
        min-height: 73vh;
    }

    /* Landscape */
    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1366px) 
    and (orientation: landscape) {
        min-height: 65vh;
    }
 }

 @mixin text-modal-entry-height() {
    @media (min-height: 711px) and (max-height: 2500px){
        min-height: 600px;
    }

    @media (min-height: 550px) and (max-height: 710px){
        min-height: 440px;
    }
 } 
