.refer-email {
    backdrop-filter: blur(1px);

    @media (min-width: 768px) {
        text-align: left;
        display: flex !important;
        align-items: center;
    }

    .modal-dialog {
        position: absolute;
        bottom: 0;
        margin: 0;
        width: 100%;
        max-width: none;

        @media (min-width: 768px) {
            max-width: 500px;
            margin: auto;
            position: relative;
        }

        .modal-content {
            border: none;
            border-radius: 8px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            @media (min-width: 768px) {
                border-radius: 8px;
            }

            .modal-header {
                border: none;
                padding: 18px 24px;
                min-height: 56px;
                position: relative;
                display: block;

                .modal-title {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 36px;

                    &:empty {
                        display: none;
                    }

                    &::after {
                        content: "";
                        width: 100%;
                        height: 1px;
                        background-color: rgba($base, 0.1);
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }
                }

                .btn-close {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    padding: 0 8px;
                }
            }

            .modal-body {
                padding: 0px 20px 56px 20px;

                .info-text {
                    margin-top: 24px;
                    margin-bottom: 24px;
                }

                .input-container {
                    margin-bottom: 24px;
                    .group {
                        width: 100%;
                        display: inline-flex;
                    }

                    .submit-icon-container {
                        background-color: transparent;
                        border: none;
                        position: absolute;
                        width: 20px;
                        right: 30px;
                        top: 33%;
                        margin-left: 15px;
                        z-index: 999;
                        svg {
                            color: $base-green;
                            z-index: 999;
                            position: absolute;
                            cursor: pointer;
                            top: -2px;
                            &:hover {
                                color: $base-green-hover;
                            }
                        }
                    }

                    .add-button:hover {
                        background-color: $base-green-hover !important;
                    }

                    .info-text {
                        margin-left: 15px;
                        margin-top: 5px;
                        color: rgba(23, 36, 61, 0.5);
                        font-size: 12px;
                        letter-spacing: 0.4px;
                        line-height: 18px;
                    }
                }

                .added-emails {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    border: 1px solid rgba(23, 36, 61, 0.38) !important;
                    outline: #17243d;
                    font-size: 16px !important;
                    color: #17243d !important;
                    background-color: transparent;
                    border-radius: 0 !important;
                    width: 100%;
                    padding: 5px 12px;
                    position: relative;

                    .emails-label {
                        position: absolute;
                        top: -10px;
                        left: 15px;
                        background-color: white;
                        font-size: 13px !important;
                        padding: 0 5px;
                        color: rgba(23, 36, 61, 0.7) !important;
                        z-index: 10;
                        line-height: 19px !important;
                        z-index: 10;
                    }

                    .email {
                        border: 1px solid rgba($gray, 0.3);
                        padding: 4px;
                        display: inline-flex;
                        align-items: center;
                        width: fit-content;
                        border-radius: 16px;
                        margin-bottom: 8px;
                        margin-top: 8px;
                        margin-right: 5px;
                        height: 32px;
                        max-width: 100%;
                        
                        .icon-container {
                            cursor: pointer;
                            &:hover {
                                opacity: 0.8;
                            }
                        }
                    }

                    p {
                        display: inline-block;
                        max-width: 100%;
                        white-space: nowrap;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                        font-size: 12px;
                        letter-spacing: 0.13px;
                        color: $base;
                        padding: 0 10px;
                        flex-grow: 1;
                        margin-bottom: 0;
                    }

                    .icon {
                        height: 24px;
                        width: 24px;
                        background-color: $blue;
                        border-radius: 50px;
                        flex-shrink: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            color: $secondary;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}
