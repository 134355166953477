.connect-modal {
    .modal-body {
        text-align: center;
        padding: 24px 32px 120px;

        .user-picture {
            margin-bottom: 30px !important;
            height: 200px;
            width: 200px;
            margin: auto;
            display: block;
            object-fit: cover;
            border-radius: 50%;
        }

        .top-image {
            height: 152px;
            width: 152px;
            margin-bottom: 16px;
        }

        .name {
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 1.17px;
            margin-bottom: 16px;
            line-height: 18px;
        }

        .relation {
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
            color: $gray;
            margin-bottom: 48px;
        }

        .unique-list-input-container {
            text-align: left;
            max-width: 364px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 36px;
        }

        .bottom-text {
            margin-bottom: 0;
            margin-top: 24px;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
            color: $gray;
        }
        .tab-content {
            p {
                text-align: left;
            }
        }
    }
}
