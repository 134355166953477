.active-filters {
    select,
    input {
        margin-bottom: 30px;
    }

    .showing-text {
        margin-bottom: 22px;
        font-size: 13px;
        color: rgba($base, 0.7);
        text-align: center;
        line-height: 18px;
    }

    .active-filter-container {
        text-align: center;
        margin-bottom: 16px;

        .content {
            border-radius: 16px;
            border: 1px solid rgba($base, 0.1);
            display: inline-block;
            padding: 0 10px;
        }

        svg {
            vertical-align: middle;
        }

        .icon {
            color: $gray;
            width: 15px;
            height: 15px;
        }

        p {
            margin: 0;
            color: rgba($base, 0.7);
            font-size: 13px;
            line-height: 30px;
            letter-spacing: 0.25px;
            margin: 0 8px 0 5px;
            display: inline-block;
        }

        .close {
            width: 15px;
            height: 15px;
            color: rgba($base, 0.3);
            cursor: pointer;
            transition: $transition;

            &:hover {
                color: $gold;
            }
        }
    }
}
