.today-container {

    background-color: $lightgray;
    padding: 30px 0;
    /* border-bottom: 1px solid rgba(23, 36, 61, 0.1); */

    .my-masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        margin-left: -30px; /* gutter size offset */
        width: auto;
      }
      .my-masonry-grid_column {
        padding-left: 30px; /* gutter size */
        background-clip: padding-box;
      }
      
      /* Style your items */
      .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
        background: grey;
        margin-bottom: 30px;
      }

    p.today-heading {
        margin: 24px 0;
        display: flex;
        justify-content: center;
        color: rgba(23, 36, 61, 0.7);
        font-size: 12px;
        line-height: 18px;
        text-align: center;
    }

    p.story-feedback {
        text-align: center;
        padding: 50px;
        font-size: 12px;
        font-weight: 400;
        color: $gray;
    }

    .today-container-heading {
        @media screen and (max-width: 760px) {
            margin: 0 20px 20px 20px;
        }

        position: relative;

        .layout-change {
            position: absolute;
            right: 50px;
            top: 0px;

            .icon {
                color: rgba(23, 36, 61, 0.2);
            }

            .active {
                color: $gray;
            }

            .icon:hover {
                cursor: pointer;
            }

            @media screen and (max-width: 500px) {
                display: none;

            }  
        }

        button {
            margin-bottom: 60px;
        }
    }

    .dashboard {
    
        padding-left: 30px;
        padding-right: 30px;
        .story-item-header {
            padding-top: 10px;
        }
        .story-item-container {
                //margin: 0 0 1em;
                width: 100% !important;

            .flair {
                top: 10px;
                left: -13px !important;
                @media screen and (min-width: 1425px) {
                    left: -13px !important;
                }
            }

            .action-container {
                position: absolute;
                top: 10px;
                right: 18px;
                color: $gray;
            }

            .action:hover {
                cursor: pointer;
            }
        }
        .story-blocks {
            .content {
                p {
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-bottom: 15px !important;
                }
            }
        }

        .story-item-footer { 
            padding: 10px !important;
        }
        .story-item-content {
            padding: 0px !important;
        }

        .story-blocks {
            padding: 0px !important;
        }
    }

    .story-item-container {

        width: 75%;
        margin: auto;

        @media screen and (max-width: 500px) {
            width: 90%;
        }   
    
        .action-container {
            position: absolute;
            top: 1px;
            right: 24px;
            color: $gray;
        }
        
        .action:hover {
            cursor: pointer;
        }

        .story-item-content {
    
            background-color: #ffffff;
            box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.12);
            margin-bottom: 30px;
            padding-top: 10px;

            @media screen and (min-width: 1425px) {
                padding-bottom: 40px;
                padding-left: 110px;
                padding-right: 110px;
            }

            &:last-of-type {
                margin-bottom: 50px;
            }
    
            .story-item-header {
                text-align: center;
                margin-top: 10px;
                position: relative;

                .flair {
                    position: absolute;
                    left: -5px;

                    @media screen and (min-width: 1425px) {
                        left: -115px;
                    }  
                }

                p {
                    font-size: 12px;
                    text-transform: uppercase;
                    font-weight: 700;
                    letter-spacing: 1.25px;
                    line-height: 24px;
                }
            }
    
            .story-item-info {
                text-align: center;
    
                .user-picture {
                    width: 50px;
                    height: 50px;
                    align-self: center;
                }
    
                .story-item-title {
                    margin-bottom: 25px;
                    padding: 0 10px;
                    color: $base-green;
                    font-size: 20px;
                    font-weight: 600;
                    letter-spacing: 0.5px;
                    line-height: 30px;
                    cursor: pointer;
                }
        
                .story-item-author {
                    padding: 0 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 25px;
                    img {
                        width: 56px;
                        height: 56px;
                        object-fit: cover;
                        border-radius: 50%;
                    }
        
                    span.user-name {
                        margin-top: 10px;
                        font-size: 12px;
                        font-weight: 600;
                        letter-spacing: 1px;
                        line-height: 16px;
                        text-transform: uppercase;
                    }
                    span.story-date {
                        margin-top: 5px;
                        color: rgba(23, 36, 61, 0.7);
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 20px;
                    }
                }
            }
    
            .story-blocks {
                @media screen and (max-width: 500px) {
                    padding: 0px;
                }  

                .content {
                    img {
                        display: flex;
                        max-width:100%;
                        max-height:100%;
                        margin-bottom: 10px;
                        margin-top: 10px;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    p {
                        display: flex;
                        justify-content: center;
                        font-size: 16px;
                        letter-spacing: 0.25px;
                        line-height: 24px;
                        margin-bottom: 36px;
                        padding-left: 20px;
                        padding-right: 20px;

                        /* css 3 liner trick starts */
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        line-height: inherit;     /* fallback. org: 16px */
                        max-height: inherit;   /* fallback. org: 32px */
                        -webkit-line-clamp: 3; /* number of lines to show */
                        -webkit-box-orient: vertical;
                        /* css 3 liner trick ends */                        

                        @media screen and (max-width: 500px) {
                            font-size: 14px;
                            letter-spacing: 0.25px;
                            line-height: 21px;
                            margin-bottom: 10px;

                            padding-left: 15px;
                            padding-right: 15px;
                        }  
                    }
                }
            }
    
            .story-item-footer {
                display: grid;
                grid-template-columns: 1fr 90px;
                grid-template-areas: "toolbar readtime";
                align-items: center;

                padding-top: 35px;
                padding-right: 20px;
                padding-left: 5px;
                padding-bottom: 60px;

                .toolbar {
                    grid-area: toolbar
                }
                .readtime {
                    grid-area: readtime;
                    justify-self: end;
                    padding-right: 7px;
                    @media screen and (max-width: 500px) {
                        padding-right: 14px;
                    }  

                }


                @media screen and (max-width: 500px) {
                    padding: 10px;
                }  

                svg {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                    cursor: pointer;
                    path {
                        fill: rgba(23, 36, 61, 0.3);
                    }
                }
    
                .toolbar {
                    span {
                        cursor: pointer;
                        color: rgba(23, 36, 61, 0.3);
                        font-size: 11px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 20px;
                        display: inline-block;
                        margin-right: 15px;


                        @media screen and (max-width: 1425px) {
                            margin-left: 15px;
                            margin-right: 0;
                        }   
                    }
                    display: inline;
                    flex-direction: row;
                }
    
                .readtime {
                    cursor: pointer;
                    color: rgba(23, 36, 61, 0.3);
                    font-size: 11px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 20px;
                }
            }
        }
    }

    .game-container {
        position: relative;
        width: 75%;
        margin: auto;
        max-width: inherit !important;
        margin-bottom: 30px;
        padding-top: 10px;
        padding-bottom: 30px;
        background-color: #ffffff;
        box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.12);
        
        > .title {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 1.25px;
            line-height: 24px;
            margin: 15px auto;
        }

        ul.relative-titles {
            position: relative;
            top: 60px;
            color: $gray;
            font-size: 12px;
            margin-bottom: -20px;
            
            li {
                text-align: center;
                width: 120px;            
            }

            li.active {
                font-weight: bold;
                color: $base;
            }
        }

        @media screen and (max-width: 424px) {
            ul.relative-titles {
                display: none !important
            }
        }

        @media screen and (max-width: 500px) {
            width: 90%;
        }

        .nav-tabs .nav-item .nav-link {

            @media screen and (min-width: 641px) {            
                margin: 0 40px;
            }

            p {
                font-size: 11px;
                width: 100px;
                display: inline-block;
                position: absolute;
                top: 50px;
                left: -50px;                
            }

            &::after {
                width: 80px;
                top: 50%;
                left: 100%;
            }

            @media screen and (max-width: 640px) {
                &::after {
                    width: 40px;
                }
            }            
        }

        .nav-tabs .nav-item .nav-link.completed {
            border-color:$base-green;

            .done-icon {
                color:$base-green;
            }
        }
        
        .nav-tabs .nav-item .active {
            background-color: $base-green;
        }

        .game-item-content {

            height: auto;

            @media screen and (min-width: 1425px) {
                padding-bottom: 40px;
                padding-left: 110px;
                padding-right: 110px;
                padding-top: 40px;
            }

            &:last-of-type {
                margin-bottom: 0px;
                margin-top: 60px;
            }

            iframe {
                @media only screen and (max-width: 500px) {
                    max-width: 335px;
                }
            
                @media only screen and (min-width: 501px) and (max-width: 700px) {
                    max-width: 335px;
                }
            
                @media only screen and (min-width: 701px) {
                    max-width: 500px;
                }
            
            }            

        }

        .tab-content {
            text-align: center;
        }

        button.default-button {
            margin-top: 3rem;
        }

        button.btn-close {
            position: absolute;
            right: 20px;
            top: 25px;
            padding: 0 8px;

        }

        .btn-close:focus {
            box-shadow: none;
        }

        
        button.underlined-link-button {
            margin-top: 40px;
        }

    }

    .example-story-container {

        > div {

            p {
                margin: 0 auto;
                text-align: center;
            }
            button {
                display: block;
                margin: 0 auto;
                text-align: center;
            }
        }

        .story-item-content {
            
            padding-top: 25px;

            .story-item-info {
                .user-picture {
                    width: 60px;
                    height: 60px;
                }
            }
        }            

    }
 
}

.example-story-modal {
    img {
        @media screen and (max-width: 480px) {                
            width: 100%;
            height: auto;
        }                
    }
} 

.remove-banner-modal.modal {
    button.link-button {
        display: block;
        margin: 50px auto;
    }
}