.family-modal {
    .modal-header {
        border: none;
        padding: 18px 24px;
        min-height: 56px;
        position: relative;
        display: block;
    }

    .modal-body {
        padding: 0px 20px 75px 20px;

        .link-row {
            border-bottom: 1px solid rgba($base, 0.05);
            cursor: pointer;
            padding: 16px 0;

            &:last-child {
                border: none;
            }

            &:hover {
                p {
                    color: $gray;
                }
            }
        }

        svg {
            color: white;
            background-color: $base-green;
            width: 36px !important;
            height: 36px;
            padding: 6px;
            border-radius: 20px;
            text-align: center;
            vertical-align: middle;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            &.black {
                background-color: $darkgray;
            }

            &.red {
                background-color: $red;
            }
        }

        p {
            display: inline-block;
            margin-left: 12px;
            margin-bottom: 0;
            vertical-align: middle;
            font-weight: 600;
            font-size: 16px;
        }
    }
}
