.image-metadata-edit {

    .main {
        font-size: 16px;
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 4px;
        text-align: center;
    }

    .subtitle {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: $gray;
        margin-bottom: 24px;
        text-align: center;
    }

    .map-container {
        margin-bottom: 40px;
    }

    .switch-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 20px !important;

        margin-bottom: 20px;

        svg {
            cursor: pointer;
            color: $gray;
            margin-left: 10px;
            padding: 2px;
        }

        .infos > span {
            font-size: 16px;
        }

        .status {
            display: flex;
            align-items: center;
            color: $gray;

            .switch-status {
                float: right;
                margin-right: 10px;
                font-size: 14px;
                font-weight: 400;
                color: $gray;
                margin-bottom: 0;
            }

            .react-switch-bg {
                opacity: 0.15;
            }
        }
    }
}

//TODO: something is wrong with the google auto complete and have to pull out from super deep somehow.
//TODO: temporary fix
.pac-container {
    z-index: 100000000000 !important;
}