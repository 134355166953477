.react-datepicker-popper {
    z-index: 12 !important;

    select {
        padding: 5px;
        height: auto !important;
        margin-top: 10px;
        margin-bottom: 0 !important;
    }
}
