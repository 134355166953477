.story-preview-modal {

    .modal-dialog {
        @include desktop-medium-sized-model();

        .header-title {
            text-align: center;
            font-size: 18px;
            margin-bottom: 0;
        }

        .story-preview-body {
            margin-bottom: 0;
            padding: 0;

            .story-title {
                font-weight: bold;
                text-align: center;
                font-size: 24px;
                margin-top: 30px;
                line-height: 28px;
            }

            .display-block {
                margin-top: 50px;
                margin-bottom: 50px;

                .display-block-text {
                    padding: 1rem;
                }

            }

            .preview-information {
                text-align: center;
                color: $gray;
                width: 50%;
                margin: auto;
            }

            .line {
                z-index: 1;
                width: 100vw;
                max-width: 100%;
                height: 1px;
                background-color: rgba(23, 36, 61, 0.1);
                overflow: visible;

                margin-bottom: 70px;
                margin-top: 90px;
            }

        }
    }
}