.deceased-update {
    padding-top: 24px;
    text-align: center;
    max-width: 500px;
    margin: auto;
    @include desktop-box-max-width();

    @media screen and (min-width: 992px) {
        padding-top: 40px;
    }

    .top-content {
        margin-bottom: 48px;

        p {
            line-height: 21px;
            letter-spacing: 0.25px;
        }
        .content-title {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 1px;
            font-weight: 600;
            margin-bottom: 24px;
        }
    }

    .group {
        position: relative;
        margin-bottom: 48px;

        .deceased-text-area {
            margin-top: 50px;
        }

        .group-title {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 24px;
            margin-bottom: 36px;
        }

        .subtitle {
            font-size: 13px;
            letter-spacing: 0;
            line-height: 18px;
            color: $gray;
            margin-top: -28px;
        }

        .calendar-icon {
            position: absolute;
            color: $gray;
            bottom: 16px;
            left: 15px;
        }

        .react-datepicker-wrapper {
            input {
                padding-left: 50px;
            }
        }

        .select-administrator-container {
            .group {
                margin-bottom: 0;
            }

            .no-admin {
                font-size: 13px;
                color: $gray;
            }
        }

        .message-label {
            font-size: 13px;
            color: $gray;
            margin-bottom: 36px;
        }
    }

    button {
        margin-bottom: 48px;
        background-color: $base !important;
    }

    .avalible-family-members{
        text-align: left;
    }
}
