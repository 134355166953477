.photo-uploader {
    .body {
        height: 400px;

        .crop-container {
            position: "relative";
            width: "100%";
            height: 500px;
            background: "#333";
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            
            .reactEasyCrop_CropArea {
                border-radius: 50%;
            }

            .image {
                text-align: center;

                .responsive {
                    max-height: 360px;
                }
            }
        }
    }

    .modal-footer {

        .scroll-to-zoom {

            padding-top: 5px;
            padding-bottom: 15px;

            color: rgba(23, 36, 61, 0.414);
            font-size: 13px;
            letter-spacing: 0;
            line-height: 20px;
            text-align: center;
            margin: 0;
        }

        justify-content: center;
    }
}
