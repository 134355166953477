.google-map-container {
    padding: 10px;
    position: relative;
    .map {
        height: 350px;
    }

    .search {
        position: absolute;
        z-index: 100000;
        left: 90px;
        top: 30px;
        color: $gray;
    }

    .controls {
        position: absolute;
        z-index: 100;
        background-color: white;
        text-align: center;
        width: 90%;
        height: 40px !important;

        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 

        top: 20px !important;

        font-size: 14px;
        line-height: 24px;
        color: $lightgray;

        border: 0px !important;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .controls:focus {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
    }
}