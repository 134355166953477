.profile-filters-bar-visuals {
    background-color: white;
    border-bottom: 1px solid rgba(23, 36, 61, 0.1);
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    position: relative;

    .filters {

        .left-filters {
            position: relative;
            
            .sort-options {
                display: flex;
                float:left;
                align-items: center;
                z-index: 1000;

                p {
                    margin-bottom: 0;
                    margin-right: 10px;
                    margin-left: 5px;
                    font-size: 12px;
                }

                .filter-icon {
                    color: $gold;
                }
            }

            .sort-options:hover {
                cursor: pointer;
            }

            .options {
                position: absolute;
                background-color: white;
                padding-top: 10px;
                padding-bottom: 10px;
                width: 200px;

                font-size: 12px;

                z-index: 100000;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

                .selected {
                    background-color: $lightgray;
                    font-weight: bold;
                }

                .option {
                    padding-left: 10px;
                    display: inline-flex;
                    width: 100%;

                    p {
                        padding: 5px;
                        margin-bottom: 0px;
                    }

                    svg {
                        margin-top: 5px;
                        margin-left: 40px;
                    }
                }

                .option:hover {
                    background-color: $lightgray;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }

        .right-filters {
            display: flex;
            // justify-content: space-between;
            height: 100%;
            float:right;
    
            @media screen and (min-width: 480px) {
                padding: 0 18px;
            }

            .favourite {
                font-size: 12px;
                color: $gray;
            }

            .active {
                p {
                    color: $gold !important;
                }
                svg {
                    color: $gold !important;
                }
            }

            .favourite:hover {
                cursor: pointer;
            }
        }
    }
}