.add-to-family-modal.modal {
    display: block !important;

    .modal-dialog {
        margin: 12px auto 0 auto;
        position: relative;
        bottom: unset;
    }

    .modal-content {
        border: none;
        border-radius: 8px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .modal-header {
        position: absolute;
        border: none;
        right: 28px;
        top: 29px;
        padding: 0;
        z-index: 10;

        button {
            box-shadow: none;
        }
    }

    .modal-body {
        padding-top: 24px;
        padding-left: 0;
        padding-right: 0;
    }
}

.add-to-family-modal.modal .modal-dialog .modal-content .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
}

.subtitle-wrapper {
    text-align: center;
    margin-top: 30px;
    .gray {
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 18px;
        text-align: center;
        color: rgba(23,36,61,0.5);
    }
}

.suggestion-container {
    text-align: center;

    > div {
        min-height: 125px;
    }

    .none {
        min-height: 125px;
    }    

    .subtitle {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 24px;
        text-transform: uppercase;
        margin-bottom: 4px;
    }

    .gray {
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 18px;
        text-align: center;
        color: rgba(23,36,61,0.5);
    }

    .suggestions {     
        
        .suggestion {
            text-align: center;
            margin-left: 10px;
            margin-right: 10px;

            p {
                font-size: 11px;
                letter-spacing: 0.5px;
                line-height: 14px;
                color: rgba(23,36,61,0.7);
                text-transform: uppercase;

                margin-top: 5px;
            }
            img {
                width: 56px;
                height: 56px;
            }

        }
    }
}

.add-to-family-container {
    .title {
        text-align: center;
        margin-bottom: 24px;
    }

    .tab-content {
        .modal-content-inside {
            text-align: left;
            padding: 0 50px;
            @media screen and (max-width: 520px) {
                padding: 0 20px;
            }
            .no-bottom {
                margin-bottom: 0;
            }
            .note {
                font-weight: 600;
            }
            svg {
                vertical-align: bottom;
            }
            .gray-container {
                margin-top: 1rem;
                background-color: $lightgray;
                padding: 20px;
            }
        }

        .need-help {
            .help-container {
                padding: 0 50px;
                @media screen and (max-width: 520px) {
                    padding: 0 20px;
                }
            }
            .need-help-header svg {
                right: 50px;
                @media screen and (max-width: 520px) {
                    right: 20px;
                }
            }

            .not-helpful-header svg {
                right: 0;
            }
        }
    }

    .nav-tabs {
        background-color: rgba($base, 0.05);
        width: 100%;
        position: relative;
        padding: 0 32px;
        height: 56px;
        padding-top: 10px;
        align-items: flex-end;
        margin-bottom: 36px;
        overflow: hidden;
        border: none;
        justify-content: center;

        @media screen and (min-width: 768px) {
            max-width: 500px;
        }

        .nav-item {
            button {
                border-radius: 2px;
                height: 48px;
                padding: 15px 17px;
                margin: 0 5px;
                font-weight: 600;
                color: $gray;
                border: none;
                letter-spacing: 1.25px;

                &.active {
                    box-shadow: 0 2px 2px 0 rgba($base, 0.14), 0 3px 1px -2px rgba($base, 0.12), 0 1px 5px 0 rgba($base, 0.2);
                    color: $gold;
                }
            }
        }
    }

    .subtitle-wrapper {
        margin-bottom: 36px;

        .subtitle {
            font-size: 14px;
            text-align: center;
            text-transform: uppercase;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.5px;
            margin-bottom: 4px;
        }

        .top-text {
            text-align: center;
            font-size: 13px;
            color: $gray;
            letter-spacing: 0.4px;
            line-height: 18px;
            margin-bottom: 0;
        }
    }

    .group {
        input {
            display: block;
            width: 100%;
            padding: 0 15px;
            margin-bottom: 48px;
        }
    }

    .active-filters {
        padding: 18px 0 0;

        &:empty {
            padding: 0;
        }

        select {
            margin-bottom: 36px;
        }

        .group:last-child select {
            margin-bottom: 18px;
        }

        .active-filter-item {
            text-align: center;
            margin-bottom: 18px;

            p {
                border: 1px solid rgba($base, 0.1);
                border-radius: 16px;
                display: inline-block;
                font-size: 13px;
                color: rgba($base, 0.7);
                line-height: 20px;
                margin-bottom: 0;
                padding: 5px 12px;

                svg {
                    color: rgba($base, 0.3);
                    margin-left: 10px;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
        }
    }

    .create-profile {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-bottom: 80px;

        input {
            margin-right: 13px;
        }
    }

    .radio-container {
        padding-top: 4px;
        padding-bottom: 30px;

        .group {
            display: flex;
            align-items: center;
            margin-bottom: 35px;

            input {
                margin-bottom: 0;
                display: inline;
            }

            label {
                margin-bottom: 0;
                font-size: 16px;
                cursor: pointer;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .result-toolbar {
        width: 100vw;
        position: relative;
        left: -18px;
        border-top: 1px solid rgba($gray, 0.1);
        border-bottom: 1px solid rgba($gray, 0.1);
        padding: 16px 18px;

        @media screen and (min-width: 480px) {
            position: static;
            width: 100%;
        }

        .result-count {
            color: rgba($gray, 0.7);
            font-size: 12px;
            display: inline;
        }

        .result-filters {
            display: inline;
            float: right;
            font-size: 12px;
            color: $gold;

            svg {
                margin-right: 5px;
                font-size: 16px;
                vertical-align: middle;
            }
        }

        .result-filters:hover {
            cursor: pointer;
        }

        p {
            margin: 0;
        }
    }

    .accounts-list {
        .account-item {
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba($gray, 0.07);
            padding: 12px 0;

            .user-wrapper {
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            &:last-child {
                border-bottom: none;
            }

            img {
                margin-right: 11px;
                width: 56px;
                height: 56px;
            }

            .name {
                margin-bottom: 0;
                line-height: 18px;
                text-transform: uppercase;
                font-weight: 500;
            }

            .country {
                margin: 0;
                font-size: 12px;
                color: rgba($gray, 0.7);
            }
        }
    }

    .btn-container {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 40px;
    }
}
