.welcome-engage {
    .top-text {
        text-align: center;
        margin-bottom: 48px;
    }

        .item {
            text-align: center;
            height: 530px;

            img {
                height: 128px;
                width: 128px;
                object-fit: cover;
            }

            .title {
                margin-bottom: 24px;
                margin-top: 36px;
            }

            .subtitle {
                font-size: 16px;
                max-width: 80%;
                margin: auto;
                margin-bottom: 24px;
                line-height: 24px;
            }

            .description {
                font-size: 12px;
                font-style: italic;
                color: $gray;
                line-height: 18px;
                max-width: 90%;
                margin: auto;
                margin-bottom: 10px;
            }

            .author {
                font-size: 12px;
                color: $gray;
                line-height: 18px;
                margin: 0;
            }
        }

    .carousel {        

        .carousel-indicators {
            margin-bottom: 45px;

            button {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: rgba($gray, 0.3);
                opacity: 1;
                margin: 0 4px;

                &.active {
                    border: 2px solid $gray;
                    background-color: transparent;
                    margin-top: 8px;
                }
            }
        }

        .carousel-control-next {
            display: none;
        }

        .carousel-control-prev {
            display: none;
        }
    }

    iframe {
        @media only screen and (max-width: 500px) {
            max-width: 335px;
        }
    
        @media only screen and (min-width: 501px) and (max-width: 700px) {
            max-width: 335px;
        }
    
        @media only screen and (min-width: 701px) {
            max-width: 335px;
        }
    
    }    
}
