.family-container {
    position: relative;
    @include content-min-height();

    .tab-content > div > div:not(.need-help) {
        max-width: 500px;
        margin: auto;
        @include desktop-box-max-width();

        iframe {
            @media only screen and (max-width: 500px) {
                max-width: 335px;
            }
        
            @media only screen and (min-width: 501px) and (max-width: 700px) {
                max-width: 335px;
            }
        
            @media only screen and (min-width: 701px) {
                max-width: 500px;
            }
        
        }        
    }

    .relation-info {
        margin-bottom: 6px;
        margin-top: -10px;
        padding-top: 25px;
        font-size: 13px;
        color: rgba($base, 0.7);
        text-align: center;
        line-height: 18px;
    }

    .family-tabs {
        overflow: hidden;
        width: 100vw;
        max-width: none !important;
        position: relative;
        //left: -18px;
        align-items: center;
        border-bottom: 1px solid rgba($base, 0.1);
        padding: 0 50px;
        margin-bottom: 18px;

        
        @media screen and (max-width: 500px) {
            margin-bottom: 80px;
        }

        @media screen and (min-width: 992px) {
            width: calc(100% + 0px); /* + 36px */
        }

        .nav-item {
            display: flex;
            justify-content: center;
            padding: 0 15px;
        }

        .nav-link {
            color: $gray;
            text-transform: uppercase;
            font-weight: 600;
            border: none;
            padding: 20px 0;
            white-space: nowrap;
            font-size: 13px;
            letter-spacing: 1.25px;
            margin-bottom: 0;

            &.active,
            &:hover {
                border: none;
                color: $gold;
                border-bottom: 2px solid $gold;
                margin-top: 2px;
            }
        }
    }

    .family-relations {
        @include content-min-height();
        padding-top: 15px;

        .action {
            margin-top: 46px;
            margin-bottom: 86px;
            /* border-bottom: 1px solid rgba($base, 0.07); */
            text-align: center;

            button {
                margin-bottom: 16px;
            }

            .subtext {
                color: $gray;
                font-size: 13px;
                text-align: center;
                line-height: 18px;
                max-width: 240px;
                margin: auto;
                margin-bottom: 24px;

                &.missing {
                    font-size: 14px;
                    color: $base;
                    line-height: 21px;
                }
            }
        }

        .showing-text {
            margin-bottom: 14px;
            font-size: 13px;
            color: rgba($base, 0.7);
            text-align: center;
            line-height: 18px;
        }

        .active-filter-container {
            text-align: center;
            margin-block-end: 16px;

            .content {
                border-radius: 16px;
                border: 1px solid rgba($base, 0.1);
                display: inline-block;
                padding: 0 10px;
            }
        }
    }

    .switch-wrapper {
        padding-top: 28px;
        padding-bottom: 48px;

        @media screen and (min-width: 480px) {
            padding-left: 18px;
            padding-right: 18px;
        }

        .row > div {
            padding: 0;
        }

        .private-profiles {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                line-height: 48px;
            }
        }

        .react-switch-bg {
            opacity: 0.15;
        }

        .subtext {
            font-size: 13px;
            color: $gray;
            line-height: 18px;
            margin: 0;
        }
    }

    .family-groups {
        .description {
            font-weight: bold;
            text-align: center;
            padding: 20px 0;
        }

        .group {
            margin: 10px auto;
            padding-bottom: 10px;
            border-bottom: 1px solid $gray;

            .group-name {
                text-transform: uppercase;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .group-info {
                margin-bottom: 0px;
                font-size: 12px;
            }

            .group-size {
                color: $gray;
            }

            img {
                max-width: 30px;
                margin: 1px;
            }
        }
    }
}
