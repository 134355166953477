.comment-section {
    text-align: center;
    margin-top: 20px;
    max-width: 500px;
    margin: auto;

    @include comment-width-section();

    .no-comments {
        color: rgba(23,36,61,0.7);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        padding: 20px;
        @media screen and (min-width: 1000px) {
            padding: 20px 0 20px 0;
        }
    }

    .comments-container {


        .title {
            margin-top: 48px;
            margin-bottom: 36px;
            color: #17243D;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0.5px;
            line-height: 36px;
            text-align: center;
            @media (min-width: 1000px) {
                font-size: 28px;
                font-weight: 500;
                letter-spacing: 0.5px;
                line-height: 50px;
                text-align: center;
            }
        }

        .add-comment-container {
            margin-bottom: 20px;
            position: relative;

            .curently-typing {
                color: rgba(23,36,61,0.7);
                font-size: 13px;
                letter-spacing: 0;
                line-height: 20px;
                text-align: center;
                padding-bottom: 30px;
            }

            .closed {
                color: rgba(23,36,61,0.7);
                font-size: 12px;
                letter-spacing: 0;
                line-height: 20px;
                text-align: center;

                padding: 20px;
            }

            .focused {
                border-top: 2px solid $gold;
                border-left: 2px solid $gold;
                border-right: 2px solid $gold;
                outline: none;

                textarea {
                    // border: none !important;
                    border-left: none !important;
                    border-right: none !important;
                    border-top: none !important;

                    border-bottom: 1px solid rgb(218, 218, 218) !important;
                }
            }

            .focused-emoji {
                border-left: 2px solid $gold;
                border-right: 2px solid $gold;
                border-bottom: 2px solid $gold;
                outline: none;
            }

            textarea {
                width: 100%;
                border: 1px solid rgba(23,36,61,0.38);
                background-color: #FFFFFF;
                resize: none;
            }

            .emoji-picker {

                .selected-emoji:hover {
                    cursor: pointer;
                }

                .selected-emoji {
                    p {
                        text-transform: capitalize;
                    }
                }
                .action-row {
                    position: relative;
                    height: 50px;

                    .open-emoji-container {
                        .default-select {
                            margin: 5px;
                            display: inline-flex;
                            float: left;

                            color: rgba(23, 36, 61, 0.381);
                            font-size: 12px;
                            letter-spacing: 0;
                            line-height: 18px;
                            text-align: center;
                        }

                        .selected-emoji {
                            float: left;
                            padding: 5px;
                            display: inline-flex;

                            p {
                                margin-bottom: 0px;
                                margin-left: 10px;
                                margin-top: 7px;
                                color: rgba(23,36,61,0.7);
                                font-size: 12px;
                                letter-spacing: 0;
                                line-height: 18px;
                            }
                        }
                    }

                    .send-icon-container {
                        margin: 10px;
                        float: right;
                        svg {
                            color: rgba(23,36,61,0.3);
                        }
                    }

                    .enabled {
                        svg {
                            color: $base-green
                        };
                    }
        
                    .enabled:hover {
                        cursor: pointer;
                    }
                }                    
            }

            .emoji-row-container {
                position: absolute;
                left: 0px;
                background-color: white;
                margin-top: -2px;
                border-radius: 2px;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                height: 80px;
                width: 100%;
                z-index: 100;
                display: inline-flex;

                .emoji {
                    // margin-top: 15px;
                    // margin-left: 9px;
                    // margin-right: 9px;
                    // display: inline-flex;
                    padding: 15px 15px 0px 15px;
                    margin: auto;
                    p {
                        text-transform: capitalize;
                        margin-bottom: 5px;
                        margin-top: 5px;
                        color: rgba(23,36,61,0.7);
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 18px;
                    }
                }

                .emoji:hover {
                    cursor: pointer;
                    background-color: #f3f4f5;
                }
            }
        }

        .comments {
            padding-top: 40px;
            padding-bottom: 40px;
            text-align: left;

            .edit-icon-container {
                float: right;
                margin: 10px;

                svg {
                    color: rgba(23,36,61,0.3);
                }

                svg:hover {
                    cursor: pointer;
                    color: rgba(23, 36, 61, 0.684);
                }
            }

            .delete-icon-container {
                float: right;
                margin-top: 10px;

                svg {
                    color: rgba(61, 23, 23, 0.3);
                }

                svg:hover {
                    cursor: pointer;
                    color: rgba(129, 14, 14, 0.684);
                }
            }

            .comment-container:last-of-type {

                .text-container {
                    border-left: none;
                }
            }

    
            .avatar-name-container {
                display: inline-flex;
                position: relative;
    
                .emoji { 
                    position: absolute;
                    bottom: -15px;
                    left: 14px;
                }
    
                .name-avatar {
                    padding-left: 15px;
                    text-align: left;
                    text-transform: uppercase;
                }
    
                .user-picture {
                    width: 60px;
                    height: 60px;
                }
    
                .user-name {
                    color: #17243D;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 1.25px;
                    line-height: 18px;
                    margin-top: 10px;
                    margin-bottom: 3px;
                    @media (min-width: 1000px) {
                        font-size: 16px;
                        line-height: 17px;
                    }
                }
    
                .date {
                    color: rgba(23,36,61,0.7);
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 18px;
                    text-align: left;
                    margin-bottom: 0;
                    @media (min-width: 1000px) {
                        font-size: 14px;
                    }
                }
            }
    
            .text-container {
                padding-top: 10px;
                padding-bottom: 45px;
                margin-left: 30px;
                padding-left: 25px;
                border-left: 1px solid rgba(23,36,61,0.12);

                .update-comment-group {
                    margin-left: 30px;
                    position: relative;

                    .submit-icon-container {
                        position: absolute;
                        right: 10px;
                        top: 15px;

                        color: $base-green;
                    }

                    .submit-icon-container:hover {
                        svg {
                            cursor: pointer;
                            color: $base-green-hover;
                        }
                    }
                }

                .edited {
                    font-size: 1px;
                    color: rgba(23,36,61,0.7);
                }
    
                p {
                    color: #17243D;
                    font-size: 14px;
                    letter-spacing: 0.25px;
                    line-height: 21px;
                    text-align: left;
                    margin-bottom: 0;
                    @media (min-width: 1000px) {
                        font-size: 16px;
                    }
                    
                }
            }

        }

        .light-button {
            margin-bottom: 50px;
        }

    }

    .input-container{
        position: relative;
    }

    .info-text {
        margin: 20px;
        font-size: 12px;
        color: $gray;
    }

    .send-icon-outer {
        svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%); 
            right: 10px;
            color: rgba(23,36,61,0.3);
        }
    }
}