.create-new-group-modal {
    .modal-body{
        padding: 0px
    }
}

.create-group-container {
    padding: 24px 20px 140px 20px;
    .subtitle{
        text-align: center;
        line-height: 21px;
        margin-bottom: 36px;
    }

    input{
        margin-bottom: 48px;
    }

    button{
        margin-top: 38px;
        margin-bottom: 24px;
    }

    .info-text{
        font-size: 13px;
        line-height: 18px;
        margin: 0;
        color: $gray;
        text-align: center;
    }

    .explanation {
        input {
            margin-bottom: 5px !important;
        }
    }

    .successors {
        margin-top: 50px;
    }

    .people {
        margin-top: 30px;
    }

    .group {

        .sub-subtitle {
            color: rgba(23, 36, 61, 0.5);
            font-size: 13px;
            text-align: center;
            margin-bottom: 25px;
            line-height: 21px;
        }

        .right {
            text-align: right;
            padding-left: 13px;
        }        
    }

    .goal {
        .tooltip-text {
            float: left;
        }
    }    

    .unique-list-input-container{
        .option p{
            font-size: 14px;
            letter-spacing: 0.15px;
        }
    }
}
