.referral-page-container {
    .content {
        max-width: 460px;
        text-align: center;
        margin: auto;
    }

    .logo-container {
        margin-bottom: 50px;

        img {
            width: auto;
            height: 100px;
        }
    }

    .text-data-container {
        margin-top: 50px;
        margin-bottom: 50px;
        /*
        background-image: url('../../images/yellow-background.png');
        background-repeat: no-repeat;
        background-size: cover;
        */

        p {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .info-container {
            p {
                padding-top: 0px;
                padding-bottom: 0px;
                margin-bottom: 0;
            }

            p:first-child {
                font-weight: 700;
            }

            .owner-text {
                color: #17243D;
                font-size: 14px;
                letter-spacing: 0.44px;
                line-height: 24px;
                text-align: center;
                text-transform: uppercase;
            }
        }

        .gray-text {
            color: rgba(23,36,61,0.5);
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
            margin: 0 20px;
        }        

        .title-gold {
            color: #A38138;
            font-size: 28px;
            font-weight: 500;
            letter-spacing: 0.5px;
            line-height: 36px;
            padding-left: 40px;
            padding-right: 40px;
            padding-top: 0;
        }

        .related-sub-text {
            color: #17243D;
            font-size: 14px;
            letter-spacing: 0.44px;
            line-height: 24px;
            text-align: center;
            text-transform: uppercase;
        }

        .join, .link-video {
            color: $base;
            font-size: 14px;
            letter-spacing: 0.25px;
            line-height: 21px;
            text-align: center;
            &:hover {
                cursor: pointer;
                color: $base-green;
            }
        }
        .video-row {
            text-align: center;
            display: flex;
            justify-content: center;
            .play-icon {
                background-color: rgba(0, 0, 0, 0.418);
                border-radius: 30px;
                width: 60px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                
                svg {
                    color: white;
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0.8);
                    cursor: pointer;
                    transition: $transition;
                }

                @media (max-width: 834px) {
                    background-color: rgba(0, 0, 0, 0.8);
                }
            }
        }

        .link-video {
            padding: 0;
        }

        .link-text {
            color: $base-green;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.07px;
            line-height: 13px;
            text-align: center;
            text-decoration: none;
            margin-top: 30px;
        }
    }

    .offer-expired-page {
        margin-top: 50px;
        margin-bottom: 50px;
            .text-data-container {
                background-image: none;
        }
        @media (max-width: 834px) {
            margin-left: 20px;
            margin-right: 20px;
        }
    }


    .button-container {
        width: 90%;
        margin-left: 5%;
        button {
            margin-bottom: 20px;
        }
        p {
            color: rgba(23,36,61,0.5);
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
        }

        .up-to-family-grey-text {
            padding-top: 20px;
        }
    }
}