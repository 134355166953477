.registration-details-container {
    max-width: 500px !important;

    @media screen and (min-width: 641px) {
        padding: 60px;
    }    

    .title {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 30px;
    }

    .subtitle {
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        margin: 48px 0;
    }

    .subtitle:first-child {
        margin-top: 0;
    }

    .nav-tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        margin-bottom: 16px;

        .nav-item {
            &:last-child .nav-link::after {
                display: none;
            }

            .nav-link {
                border: none;
                border-radius: 20px;
                background-color: #f4f5f6;
                margin: 0px 20px;
                color: $gray;
                height: 40px;
                width: 40px;
                font-size: 16px;
                position: relative;
                cursor: default;

                &::after {
                    content: "";
                    height: 1px;
                    width: 40px;
                    background-color: rgba($base, 0.12);
                    position: absolute;
                    top: 50%;
                    right: -100%;
                }

                &.completed {
                    border: 1px solid #a38138;
                    background: none;

                    .done-icon {
                        color: #a38138;
                        margin: 0 -12px;
                    }
                }
            }

            .active {
                background-color: #a38138;
                color: white;
                font-weight: 700;
            }
        }
    }

    button {
        svg {
            margin-left: 12px;
        }
    }

    .help-container {
        border-top: 1px solid rgba($gray, 0.1);
        padding-top: 32px;
        margin-top: 48px;

        .help {
            text-align: center;
        }
    }
}
