.profile-voice-container {
    background-color: #f3f4f5;
    @include content-min-height();
    
    .content {
        max-width: 500px;
        margin: auto;
        padding-bottom: 20px;
        @include desktop-box-max-width();

        .info-row {
            p {
                text-align: center;
                margin-top: 24px;
                margin-bottom: 24px;

                color: rgba(23,36,61,0.7);
                font-size: 12px;
                letter-spacing: 0;
                line-height: 18px;
            }
        }

        .feedback {
            text-align: center;
            margin-top: 34px;
            margin-bottom: 14px;

            color: rgba(23,36,61,0.7);
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;
        }

        .voices {

            .voice-container {
                margin-bottom: 12px;
                background-color: white;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

                padding: 20px;

                position: relative;
                cursor: pointer;

                .from {
                    margin-bottom: 16px;

                    .text {
                        margin: 0;
                        color: rgba(23,36,61,0.5);
                        font-size: 14px;
                        letter-spacing: 0.25px;
                        line-height: 21px;
                    }

                    .story-title {
                        margin: 0;
                        color: #17243D;
                        font-size: 14px;
                        letter-spacing: 0.25px;
                        line-height: 21px;
                    }
                }

                .media-title {
                    color: $base-green;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                    line-height: 24px;
                }

                .voice-player {

                    .wave {
                        height: 0px;
                        display:none;
                    }

                    .interaction-container {
        
                        width: 40px;
                        height: 40px;
                        background-color: $base-green;
                        border-radius: 30px;
                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                        position: absolute;
                        display: flex;
                        justify-content: center;

                        top:-60px;
                        right: 0px;
    
                        svg {
                            color: white;
                            display: block;
                            margin:auto;
                        }
                    }
                }

                .profile-and-date {

                    p {
                        margin: 0;
                        text-transform: uppercase;
                    }

                    .image {
                        display: inline-flex;

                        p {
                            margin-left: 10px;
                            color: #17243D;
                            font-size: 12px;
                            font-weight: 500;
                            letter-spacing: 1px;
                            line-height: 13px;
                            padding-top: 6px;

                        }
                        .user-picture {
                            width: 25px;
                            height: 25px;
                        }

                    }

                    .date {
                        float: right;
                        color: rgba(23,36,61,0.7);
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 20px;
                    }
                }
                
            }

            .has-more {
                text-align: center;
                padding-top: 10px;
            }
        }
    }

    .button-container {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .story-count {
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: $gray;
        padding: 24px 0;
        margin: 0;
    }    
}

.voice-details-modal {

    .modal-title {
        position: absolute;
        left: 45%;
    }

    .voice-details-body {

        text-align: center;

        .profile {
            .from {
                color: rgba(23,36,61,0.5);
                font-size: 12px;
                letter-spacing: 0.5px;
                line-height: 18px;

                margin-top: 24px;
                margin-bottom: 8px;

            }

            .story-name {
                color: #17243D;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 1px;
                line-height: 18px;

                margin-bottom: 8px;
            }

            .date {

                color: rgba(23,36,61,0.7);
                font-size: 12px;
                letter-spacing: 0;
                line-height: 20px;

                margin-bottom: 24px;
            }

            .user-picture {
                width: 150px;
                height: 150px;

                margin-bottom: 24px;
            }

            .user-name {
                color: #17243D;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 1.17px;
                line-height: 16px;
                text-transform: uppercase;
            }
        }

        .audio {
            .audio-title {
                color: #17243D;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 30px;
                text-align: center;
                margin-top: 26px;

            }

            .play {
                background-color: $base-green;
            }
        
            .pause {
                background-color: RGB(239, 90, 90);
            }
                
            .record-button {
                width: 64px;
                height: 64px;
                border-radius: 40px;
                border: none;
        
                svg {
                    color: white;
                }
            }

            .audio-user {
                margin-top: 10px;
                font-size: 14px ;
                font-weight: 400;
                color: $gray;
                margin-bottom: 40px;
            }
        
            #waveform {
                height: 100px;
                width: 100%;
                display: block;
                margin-bottom: 70px;
                overflow: hidden;

                canvas {
                    width: 100%;
                    left: auto !important;
                }
            }

        }

        .fav-container {
            margin-top: 39px;
            margin-bottom: 20px;

            color: rgba(23,36,61,0.5);
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 1.25px;
            line-height: 16px;
            text-align: center;

            p {
                margin-top: 8px;
            }
        }

        .fav-container:hover {
            cursor: pointer;
        }

        .active {
            color: $gold

        }
    }
}