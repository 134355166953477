.pwa-modal-ios {
    .modal-content {
        padding-bottom: 30px;
    }
    .content {
        padding: 15px;
        padding-left: 25px;
        padding-right: 25px;

        .desc-container {
            h6 {
                text-align: center;
                margin: 20px 0;
            }

            p {
                line-height: 1.5rem;
            }

            svg {
                width: 1.2rem;
                height: 1.2rem;
                vertical-align: text-bottom;
            }
        }

        .logo-container {
            text-align: center;
            padding: 10px 0;
        }
    }

    .modal-button {
        width: 50% !important;
        margin: auto;
        margin-top: 5px;
        margin-bottom: 20px;
        background: $base-green;
        color: #fff;
        border: none;
        border-radius: 4px;
        transition: 0.4s;
        height: 40px;

        &:hover {
            background-color: $base-green-hover;
        }
    }
}