.detailed-media-modal {

    max-width: none !important;
    width: auto !important;
    margin: auto !important;

    .modal-body {
        padding: 50px;
        @media screen and (max-width: 550px) {
            padding: 20px;
        }

        .carousel-item {
            text-align: center;
        }
    }
    img {
        height: 700px;
        width: auto;
        @media screen and (max-width: 567px) {
            height: auto;
            width: 100%;
            max-width: 100%;
        }
         /* Portrait */
        @media only screen 
        and (min-device-width: 768px) 
        and (max-device-width: 1366px) 
        and (orientation: portrait) {
            height: auto;
            width: 100%;
            max-width: 100%;
        }

        /* Landscape */
        @media only screen 
        and (min-device-width: 768px) 
        and (max-device-width: 1366px) 
        and (orientation: landscape) {
            height: auto;
            width: 100%;
            max-width: 100%;
        }
    }

    video {
        height: 700px;
        width: auto;
        @media screen and (max-width: 550px) {
            height: 350px;
            width: auto;
            max-width: 100%;
        }
    }
}