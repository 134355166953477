.notification-container {
    background-color: #ebffd6;
    padding: 12px 20px 24px 20px;
    color: black;
    margin: 20px auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-align: center;
    position: relative;
    max-width: 464px;
    margin-bottom: 30px;

    img {
        width: 30px !important;
        height: 30px !important;
    }

    .close-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #17243d83;
    }

    .close-icon:hover {
        color: $darkgray;
        cursor: pointer;
    }

    .notification-title {
        font-size: 14px;
        text-transform: uppercase;
        margin: 10px 0;
        /*color: #17243D;*/
        font-weight: 700;
        letter-spacing: 0.5px!important;
        line-height: 21px;
    }

    .notification-subtitle {
        color: #17243D;
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 21px;
        text-align: center;
    }

    .notification-text {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        letter-spacing: 0.58px;
        line-height: 18px;
        /*color: #17243D;*/
    }

    .notification-subtext {
        color: #ccc;
        margin: 0;
        letter-spacing: 0.25px;
        padding-top: 10px;
    }

    .notification-link {
        text-decoration: underline;
        margin: 0;
        letter-spacing: 0.25px;
    }

    button.nth2{
        margin: 20px auto 20px auto !important;
    }
    
    .buttons-container {
        display: flex;
        align-items: center;
        .empty-space-to-socialicons {
            width: 45px;
        }
        .facebook-button {
            background-color: #4267B2 !important;
            &:hover {
                background-color: #3B5998 !important;
            }
        }
        .twitter-button {
            background-color: #00acee !important;
            &:hover {
                background-color: #00B6F1 !important;
            }
        }
        .facebook-button, .twitter-button {
            display: flex!important;
            height: 40px;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
            flex-direction: row;
            .button-text-social {
                font-size: 12px!important;
                letter-spacing: 1px!important;
                padding-left: 10px;
            }
        }
    }

    .share-with-text {
        color: #17243D;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.25px;
        margin: 30px 0 30px 0;
        line-height: 21px;
        text-align: center;
    }
}

.success {
    background-color: #ebffd6;
}

.problem {
    color: white;
    background-color: #f75f5f;
}

.info {
    background-color: #dbf9ff;
}
