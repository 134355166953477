.record-audio {

    .tab-content {
        text-align: center;
    }

    .audio-title {
        font-size: 18px;
    }       

    .audio-user {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 45px;
        margin-top: 10px;
        color: $base;
    }

    .wave {
        height: 0px;
        display:none;
    }

    .record-audio-tabs {
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;

        border-bottom: 1px solid rgb(222, 222, 222);
    
        .nav-item {
            padding: 0 17px;
        }
    
        .nav-link {
            color: $gray;
            text-transform: uppercase;
            font-weight: 600;
            border: none;
            padding: 16px 0;
            white-space: nowrap;
            letter-spacing: 1.25px;
    
            &.active,
            &:hover {
                z-index: 1001;
                border: none;
                color: $gold;
                border-bottom: 2px solid $gold;
                margin-top: 2px;
            }
        }
    }

    .group {
        .sub-subtitle {
            color: rgba(23, 36, 61, 0.5);
            font-size: 13px;
            text-align: center;
            margin-bottom: 20px;
        }

        .right {
            text-align: right;
            padding-left: 12px;
        }
    }

    .record-button {
        width: 64px;
        height: 64px;
        border-radius: 100%;
        border: none;
        padding: 0;

        svg {
            color: white;
        }

        .countdown {
            color: white;
            font-size: 24px;
        }
    }

    .record {
        background-color: $base-green;
    }
    .record.hidden {
        background-color: transparent;
        height: fit-content;
    }

    .stop {
        background-color: RGB(239, 90, 90);
    }

    .play {
        background-color: $base-green;
    }

    .pause {
        background-color: RGB(239, 90, 90);
    }

    .record-help {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
        margin-top: 10px;

        span {
            font-size: 12px;
            font-weight: 400;
            color: $gray;
            margin-bottom: 20px;
        }
    }
     .timer-text {
        color: $base;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 18px;
        text-align: center;
        margin-bottom: 78.8px !important;
    }

    .record-info {
        font-size: 12px;
        font-weight: 400;
        color: $gray;
        margin-bottom: 50px;
    }
    .record-info.hidden {
        display: none;
    }

    .add-button {
        position: relative;
        .progressbar {
            height: 5px;
            width: 100%;
            position: absolute;
            bottom: 0;
            background-color: transparent;
            left: 0;
            transition: all 0.2s linear;
            .progress-bar {
                background-color: $base-green;
            }
        }
    }
}

.timer-countdown-item {
    .count-container {
        display: flex;
        justify-content: center;
    }
}

.modal-dialog.narration-modal {
    .record-audio {
        .record-section {
            text-align: center;
        }
    }
}
