.visual-details-modal {

    .modal-header {
        .navigation-icons {
            float: left;

            div:hover {
                color: rgba(23,36,61,0.7);
                cursor: pointer;
            }
        
            div {
                display: inline-block;
            }
        }
    }

    .edit-card-content {

        .description-container {
            height: 40px;

            .description {
                color: rgba(23,36,61,0.7);
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
                text-align: center;
                margin: 0;
            }
        }

        .image-container {
            position:relative;

            img {
                width: 100%;
            }

            video {
                width: 100%;
            }

            .interactions {

                .comment {
                    left: 50%;
                    bottom: -20px;
                }

                .people {
                    left: 40%;
                    bottom: -20px;
                }

                .audio {
                    left: 80%;
                    bottom: -20px;
                }

                .favourite {
                    left: 10%;
                    bottom: -20px;

                    svg {
                        color: $gray !important;
                    }

                }

                .active {
                    svg {
                        color: $gold !important
                    }
                }

                .interaction-container {
        
                    width: 40px;
                    height: 40px;
                    background-color: white;
                    border-radius: 30px;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                    position: absolute;
                    display: flex;
                    justify-content: center;

                    svg {
                        color: $base-green;
                        display: block;
                        margin:auto;
                    }
        
                    .big-icon {
                        color: $base-green;
                        display: block;
                        margin:auto;
                    }
        
                    .added {
                        position: absolute;
                        top: 50px;
                        width: max-content;

                        p {
                            color: $gray;
                            font-size: 12px;
                            letter-spacing: 0;
                            line-height: 18px;
                            text-align: center;
                        }
                    }
        
                    .record-user {
                        width: 100px;
                        font-size: 14px;
                        position: absolute;
                        top: 45px;
                        color: $gray;
                        right: -32px;
                        // left: -80px;
                        text-align: center;
                    }
        
                    .record-user-add {
                        width: 200px;
                        font-size: 14px;
                        position: absolute;
                        top: 45px;
                        color: $base-green;
                    }
        
                    .small {
                        position: absolute;
                        right: 1px;
                        bottom: 27px;
                        z-index: 10;
                        width: 15px;
                        height: 15px;
                        background-color: $base-green;
                        border-radius: 10px;
        
        
                        .add-text {
                            position: relative;
                            width: 40px;
                            top: 37px;
                            right: 24px;
                            font-size: 12px;
                            color: $base-green;
                        }
        
                        .small-icon {
                            color: white;
                            display: block;
                            margin:auto;
                        }
                    }
                }
        
                .interaction-container:hover {
                    background-color: $lightgray;
                    cursor: pointer;
                }
            }
        }

        .info-container {
            padding-top: 86px;
            .from {
                color: rgba(23,36,61,0.5);
                font-size: 12px;
                letter-spacing: 0.4px;
                line-height: 18px;
                text-align: center;
            }

            .where {
                color: #17243D;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 1px;
                line-height: 18px;
                text-align: center;
            }

            .date {
                color: rgba(23,36,61,0.7);
                font-size: 12px;
                letter-spacing: 0;
                line-height: 18px;
                text-align: center;
                margin: 4px;
            }

            .name {
                color: rgba(23,36,61,0.7);
                font-size: 12px;
                letter-spacing: 0;
                line-height: 18px;
                text-align: center;
            }

            .default-button {
                margin-top: 60px !important;
                width: 45% !important;
                margin: auto;
                min-width: fit-content !important;
            }
        }

        .featured-container {
            margin-top: 70px;

            .featured {
                color: #17243D;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 1px;
                line-height: 18px;
                text-align: center;
                margin-bottom: 40px;
            }

            .tagged-user {
                text-decoration: underline;
                color: $base-green;
            }
    
            .tagged-user:hover {
                color: $base-green-hover;
                cursor: pointer;
            }

            .featured-list {
                div {
                    overflow: hidden;
                    border-bottom: 1px solid rgba(23, 36, 61, 0.1);
                }
                
                p {
                    margin-top: 0.1rem;
                    margin-bottom: 0.1rem;
                }
                
                p:nth-of-type(2n + 1) {
                    float: left;
                    color: rgba(23,36,61,0.7);
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 42px;
                }
        
                p:nth-of-type(2n) {
                    float: right;
                    color: #17243D;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 42px;
                }
            }
        }

        .comments-container {
            margin-top: 50px;

            .comment-section {
                @include desktop-medium-sized-model();
            }

            .featured {
                color: #17243D;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 1px;
                line-height: 18px;
                text-align: center;
                margin-bottom: 40px;
            }
        }

        .back-to-top {
            color: rgba(23,36,61,0.5);
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.25px;
            line-height: 16px;
            text-align: center;
            text-transform: uppercase;
        }

        .back-to-top:hover {
            cursor: pointer;
        }
    }
}

// modal desktop size -> Stories > Visuals, when you click to open a photo or video in a modal 
.modal-dialog.visual-details-modal {
    @include desktop-medium-sized-model();
}

// modal desktop size - Story > comments on media files. Access comments from the story page by clicking on the comments icon
.visual-details-modal.media-comment-modal.show {
    .modal-dialog {
        @include desktop-medium-sized-model();
    }
}