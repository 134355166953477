.refer-container {
    text-align: center;
    max-width: 500px;
    margin: auto;
    @include desktop-box-max-width();

    .referral-box-container {
        .friends-green-box {
            padding: 24px;
            background-color: #EBFFD6;
            border-bottom-left-radius: 300px 50px;
            border-bottom-right-radius: 300px 50px;
            -webkit-border-bottom-left-radius: 300px 50px;
            -webkit-border-bottom-right-radius: 300px 50px;
            -moz-border-bottom-left-radius: 300px 50px;
            -moz-border-bottom-right-radius: 300px 50px;
            margin-top: -20px;

            p:first-child {
                color: #17243D;
                font-size: 24px;
                font-weight: 700;
                line-height: 30px;
            }

            p:last-child {
                color: #17243D;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.25px;
                line-height: 21px;
                padding-bottom: 40px;
            }

            .emoji-conatiner {
                padding-top: 50px;
            }
        }
        .referral-link {
            color: #17243D;
            font-size: 16px;
            letter-spacing: 0.25px;
            line-height: 24px;
            text-align: center;
            padding-top: 20px;
        }
        .ref-link-section {
            display: flex;
            justify-content: center;
            .link-text-referral {
                color: #006238;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.25px;
                line-height: 24px;
                text-align: center;
                text-decoration: none!important;
                padding-bottom: 20px;
                margin: 0 20px 0 10px;
                @media screen and (max-width: 525px) {
                    font-size: 12px;
                }
            }
        }
    }

    .title-text {
        margin-top: 24px;
        margin-bottom: 16px;
        color: #17243D;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 24px;
    }

    .info-text {
        color: #17243D;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 24px;
        text-align: center;
        font-weight: 300;
        margin: 0 0 31px 0;
    }

    .image-container {
        img {
            height: 93px;
            width: 93px;
        }
    }

    .small-gray-section {
        margin: 24px 10% 30px 10%;
        padding-bottom: 40px;
        .small-gray-text {
            color: rgba(23, 36, 61, 0.5);
            font-size: 11px;
            letter-spacing: 0.34px;
            margin: 0;
            line-height: 13px;
        }
    }

    .referral-box-container {
        background-color: #FFFFFF;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        margin-bottom: 20px;

        .buttons-container {
            padding: 0 20px;
            .default-button {
                margin-bottom: 12px;
                height: 40px;
                display: flex!important;
                align-items: center;
                justify-content: center;
                .button-text {
                    margin-left: 10px;
                    font-size: 12px;
                }
            }

            .facebook-button {
                background-color: #4267B2 !important;

                &:hover {
                    background-color: #3B5998 !important;
                }
            }

            .twitter-button {
                background-color: #00acee !important;

                &:hover {
                    background-color: #00B6F1 !important;
                }
            }
        }
    }

}

.save-container {
    text-align: center;
    max-width: 500px;
    margin: auto;
    @include desktop-box-max-width();

    .notification-container {
        margin-top: -20px;
        margin-bottom: 40px;
        max-width: inherit;
    }

    .title-text {
        margin-top: 24px;
        margin-bottom: 45px;
        color: #17243D;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 24px;
    }

    .savings-widget {
        background-color: #FFFFFF;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        margin-bottom: 20px;

        .saving {
            padding: 24px;
            background-color: #EBFFD6;
            border-radius: 0 0 25% 25%;
            border-bottom-left-radius: 300px 50px;
            border-bottom-right-radius: 300px 50px;
            -webkit-border-bottom-left-radius: 300px 50px;
            -webkit-border-bottom-right-radius: 300px 50px;
            -moz-border-bottom-left-radius: 300px 50px;
            -moz-border-bottom-right-radius: 300px 50px;
            margin-top: -20px;

            p:first-child {
                color: #006238;
                font-size: 22px;
                font-weight: bold;
                line-height: 30px;
            }

            p:last-child {
                color: #17243D;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.9px;
                line-height: 21px;
            }
        }

        .icon-container {
            background-color: #66B23D;
            width: 49px;
            height: 49px;
            border-radius: 30px;
            margin: auto;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -25px;
        }

        .info-container {
            padding-top: 24px;
            padding-bottom: 49px;

            color: rgba(23, 36, 61, 0.5);
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;

            .small-gray-text {
                margin: auto;
                max-width: 300px;
            }
        }

    }

    .information-widget {
        background-color: #FFFFFF;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

        padding-top: 36px;
        margin-bottom: 36px;
        padding-bottom: 36px;

        p:first-child {
            color: #17243D;
            font-size: 22px;
            font-weight: 900;
            letter-spacing: 0.9px;
            line-height: 30px;
        }

        p:last-child {
            color: red;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0.9px;
            line-height: 21px;
            text-align: center;
        }

        .information-widget-description {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.9px;
            color: #17243D;
            line-height: 21px;
            text-align: center;
        }

        .default-button {
            margin-top: 36px;
        }
    }
}