.info-page {
    max-width: 500px;
    padding-top: 20px;
    margin: 20px auto;
    @include desktop-box-max-width();

    .button-container {
        padding-top: 40px;
        padding-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        .back {
            flex: 1;
            max-width: 200px;
            margin-right: 20px;
        }

        .next {
            flex: 2;
            margin-left: 0;
            margin-right: 0;
        }
    }
    .storyinfo-container {
        position: relative;
        .title {
            text-align: center;
            margin-bottom: 30px;
        }

        .block-title {
            text-align: center;
            font-size: 14px;
            margin-top: 32px;
            margin-bottom: 28px;
            font-weight: 500;
            color: #17243d;
            text-transform: uppercase;
        }

        .involved {
            margin-bottom: 15px;
        }

        .center-explanation {
            font-size: 13px;
            text-align: center;
            margin-bottom: 40px;
        }

        .optional {
            .title {
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 32px;
                margin-bottom: 24px;
                padding-top: 24px;
                text-align: center;
            }
        }

        .tooltip-text {
            margin-bottom: 34px;
        }
        .separator {
            position: absolute;
            left: -42px;
            width: calc(100% + 84px);
            border-bottom: 1px solid #e7e9eb;
        }

        .explanation {
            color: $gray;
            font-size: 13px;
        }

        .basics {
            .radio-input-container {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .radio-input {
                    cursor: pointer;
                    margin-right: 10px;
                }
            }
            .radio-input-container:last-of-type {
                margin-bottom: 40px;
            }

            .date-period-container-margin {
                margin-bottom: 50px;
            }
        }
        .switch-container {
            display: flex;
            justify-content: space-between;
            max-width: 500px;
            align-items: center;
            margin: 20px 0;
            margin-bottom: 40px;
            @include desktop-box-max-width-100();

            .infos {
                span {
                    color: $base;
                    font-size: 16px;
                    letter-spacing: 0.15px;
                    line-height: 24px;
                }
            }

            .status {
                display: flex;
                align-items: center;
                color: $gray;

                .switch-status {
                    float: right;
                    margin-right: 10px;
                    font-size: 14px;
                    font-weight: 400;
                    color: $gray;
                    margin-bottom: 0;
                }
            }
        }

        .map {
            padding: 10px;

            .infos {
                span {
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }

        .google-map-container {
            margin-bottom: 50px;
        }

        .line {
            margin-top: 30px;
            margin-bottom: 40px;
            z-index: 1;
            width: 100vw;
            max-width: 500px;
            height: 1px;
            background-color: rgba(23, 36, 61, 0.1);
            overflow: visible;
            @include desktop-box-max-width-100();
        }
    }
}
