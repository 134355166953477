.forgot-password-container {
    max-width: 400px !important;
    padding: 80px;
    text-align: center;

    p {
        text-align: center;
    }

    .title {
        margin-bottom: 24px;
    }

    .info-title {
        margin-bottom: 48px;
    }

    .forgot-password-form {
        width: 100%;

        .info {
            margin-top: 15px;
            margin-bottom: 36px;
        }

        input {
            width: 100%;
            padding: 13px;
        }
    }
}
