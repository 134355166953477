.invitation-container {
    text-align: left;
    margin: 20px auto !important;
    border-bottom: 1px solid rgba($gray, 0.07);
    max-width: 100%;
    padding-bottom: 20px;

    &:last-child{
        border-bottom: 0;
    }

    .image-container img {
        max-width: 100%;
    }

    .details-container {
        padding: 0;
        padding-left: 5px;
        padding-top: 5px;

        p {
            margin: 0;
        }

        .name {
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: 9px;
        }

        .email,
        .location {
            font-weight: 400;
            font-size: 12px;
            color: $gray;

            svg {
                font-size: 14px;
                width: 20px;
            }
        }

        select {
            font-size: 12px !important;
            width: 65%;
            margin: 15px 0 20px 0;
            height: 32px !important;
            border-radius: 0;
        }

        .cta-block {
            p {
                font-weight: 700;
                font-size: 12px;
                width: 50%;
                display: inline-block;
            }

            .accept {
                color: #66b23d;
            }

            .accept:hover {
                color: #79bb55;
                cursor: pointer;
            }

            .refuse {
                color: #ef5a5a;
            }
            .refuse:hover {
                color: #ec7878;
                cursor: pointer;
            }

            svg {
                padding: 2px;
                float: right;
                margin: 0 7.5px;
                font-size: 31px;
            }
        }
    }
}
