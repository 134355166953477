.password-reset-container {
    max-width: 400px !important;
    padding: 80px;

    .title{
        text-align: center;
    }

    .group {
        width: 100%;
        margin-top: 20px;

        input {
            width: 100%;
            padding: 13px;
            margin-bottom: 11px;
        }
/*
        .top-label {
            position: relative;
            bottom: -23px;
            right: -15px;
            background-color: white;
            font-size: 13px;
            padding: 5px;
            color: grey;
        }
*/   
        .bottom-label {
            font-size: 13px;
            color: grey;
            margin-left: 15px;
        }
    }

    .pw-reset-button {
        margin-top: 25px;
        width: 100%;
        margin-bottom: 30px;
        padding: 10px;
        border-radius: 0;
        text-transform: uppercase;
    }
}