.create-new-profile {
    padding: 0 50px;
    @media screen and (max-width: 520px) {
        padding: 0 20px;
    }
    .subtitle {
        margin-bottom: 36px;
        display: block;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.5px;
    }

    .relationship-picker-container {
        .result-container {
            width: 100%;
        }
        .nav-tabs {
            .nav-item {
                button {
                    border-radius: 0;
                }
            }
        }
    }

    .group {
        &.date {
            svg {
                position: absolute;
                font-size: 20px;
                color: $gray;
                top: 18px;
                right: 18px;
            }
        }

        &.administration {
            .subtitle {
                margin-bottom: 4px;
            }

            .top-text {
                font-size: 13px;
                letter-spacing: 0.4px;
                color: $gray;
                text-align: center;
            }
        }

        input,
        select {
            margin-bottom: 36px;
            width: 100%;
        }

        &.explanation {

            margin-bottom: 40px;

            .subtitle {
                margin-bottom: 0;
            }

            .sub-subtitle {
                color: $gray;
                font-size: 13px;
                text-align: center;
                margin-bottom: 48px;

                &.left {
                    text-align: left;
                    padding-left: 12px;
                }
            }

            input,
            select {
                margin-bottom: 48px;

                &.with-label {
                    margin-bottom: 5px;
                }
            }

            .unique-list-input-container{
                margin-bottom: 5px;
            }
        }
    }

    .add-to-family {
        /*border-top: 1px solid rgba($gray, 0.07);*/
        padding-top: 24px;

        .title {
            margin-bottom: 36px;
        }

        .subtitle {
            margin-bottom: 4px;
        }

        .top-text {
            text-align: center;
            font-size: 13px;
            letter-spacing: 0.4px;
            line-height: 18px;
            color: $gray;
            margin-bottom: 38px;
        }

        .subtitle:last-child {
            background-color: blue;
        }

        .weekly-update {
            padding-left: 10px;
            padding-top: 5px;

            span {
                font-size: 16px;
            }

            .switch {
                float: right;

                .react-switch-bg {
                    opacity: 0.15;
                }
            }

            .switch-status {
                float: right;
                margin-right: 10px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(23, 36, 61, 0.5);
                margin-bottom: 0;
            }
        }
    }

    .checkbox {
        padding-left: 14px;
        padding-top: 46px;
        padding-bottom: 60px;

        .checkbox-input {
            padding-left: 0;
            display: flex;

            input {
                flex-basis: 18px;
                flex-grow: 0;
                flex-shrink: 0;
                margin-left: 0;
            }
        }
    }

    .actions {
        text-align: center;
        padding-bottom: 48px;
        margin-bottom: 40px;

        .finish {
            margin-bottom: 36px;
        }
    }

    .family-member-list-to-connect {
        margin-top: 10px;

        .group {
            .top-label {
                z-index: 2
            }    
        }        

        .member-name {
            display: inline;
            font-size: 12px;
            font-weight: 400;
            color: $gray;
        }

        .connect-info {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 12px;
            font-weight: 400;
            color: $gray;
            background-color: none;
        }
        .show-all {
            text-decoration: underline;
            color: $base-green;
            cursor: pointer;

            &.less {
                margin-left: 5px
            }
        }
    }

    .checkbox.information {
        padding: 38px 0 60px 0;
        text-align: left;

        > div.checkbox-input {
            display: inline-block;
            float: left;
            width: 7%;
        }

        label {
            width: 93%;
        }

        .create-information {
            display: flex;
            margin-bottom: 34px;

            .icon {
                width: 20px;
                height: 20px;
                flex-shrink: 0;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 12px;

                svg {
                    color: $gray;
                    font-size: 12px;
                }
            }

            p {
                letter-spacing: 0.25px;
                margin: 0;

                span {
                    font-weight: 600;
                }
            }
        }
    }
}
