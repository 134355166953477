.family-members {
    .family-member {
        padding: 18px 0;
        border-bottom: 1px solid rgba($base, 0.07);

        @media screen and (min-width: 480px) {
            margin: 0 18px;
        }

        .member-detail {
            padding-left: 0;
            display: flex;

            .img-container {
                margin-right: 14px;

                img {
                    width: 56px;
                    height: 56px;
                }
            }

            .content {
                display: flex;
                flex-direction: column;
                justify-content: center;

                p {
                    margin: 0;
                }

                .name {
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 1.17px;
                    line-height: 18px;
                    margin-bottom: 2px;
                    font-size: 14px;
                    transition: $transition;
                    text-align: left;
                }

                .relation {
                    color: rgba($base, 0.7);
                    text-transform: capitalize;
                    font-size: 13px;
                    line-height: 20px;
                    font-weight: 400;
                }

                .invitation-info {
                    font-size: 13px;
                    color: rgba($base, 0.7);
                    display: flex;
                    align-items: center;

                    svg {
                        width: 15px;
                        height: 15px;
                        color: $gray;
                        margin-right: 6px;
                    }
                }
            }
        }

        .member-detail:hover {
            cursor: pointer;
            .name {
                color: $gray;
            }
        }

        .menu-btn {
            padding: 0;
            text-align: right;

            .icon {
                color: rgba($base, 0.3);
                cursor: pointer;
            }

            .connect {
                text-align: center;
                color: $base-green;

                svg {
                    height: 30px;
                    width: 30px;
                    color: $base-green;
                }

                p{
                    margin: 0;
                    font-size: 13px;
                    line-height: 20px;
                    letter-spacing: 0;
                }

                &:hover {
                    color: $base-green-hover;

                    svg {
                        color: $base-green-hover;
                    }
                }
            }

            .connect:hover {
                cursor: pointer;
                color: $base-green-hover;
            }

            .sent {
                color: $blue;

                svg {
                    color: $blue;
                }
            }

            .sent:hover {
                cursor: default;
                color: $blue;

                svg {
                    cursor: default;
                    color: $blue;
                }
            }
        }

        &.deceased {
            width: 100vw;
            position: relative;
            left: -18px;
            background-color: #eff0f1;
            max-width: none;
            padding: 18px;

            @media screen and (min-width: 480px) {
                width: 100%;
            }
        }

        .member-detail {
            .unqiue-name-border {
                margin-top: 4px;
                font-size: 9px;
                color: #4569ad;
                font-weight: 600;
                border: 1px solid #4569ad;
                border-radius: 8px;
                width: 36px;
                height: 16px;
                line-height: 16px;
                text-align: center;
                text-transform: uppercase;
            }

            .with-relation {
                //This is for 1 version where a relation is after this
                padding-left: 5px;
                padding-right: 5px;
                margin-left: 5px;
            }

            .unqiue-name-border-profile {
                margin-top: 4px;
                font-size: 9px;
                color: #8f60af;
                font-weight: 600;
                border: 1px solid #8f60af;
                border-radius: 8px;
                width: 48px;
                height: 16px;
                line-height: 16px;
                text-align: center;
                text-transform: uppercase;
                padding-left: 5px;
                padding-right: 5px;
                margin-right: 5px;
            }
        }
    }
}
