.profile-container {
    padding: 0 !important;

    @media only screen and (min-width: 992px) {
        .family-life-lessons {
            min-height: 40.5vh;
        }
    }

    .top-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 500px;
        margin: auto;
        @include desktop-box-max-width();

        .icon {
            color: $base-green;
            cursor: pointer;

            &:hover {
                color: $base-green-hover;
            }
        }

        .profile-name {
            margin: auto;
        }
    }

    .admin-profile-tab {
        background-color: #f0fcff;
        width: 100vw;
        /*margin-left: -18px;*/
        /*margin-top: -18px;*/
        max-width: none;
        padding: 18px;

        @media screen and (min-width: 992px) {
            width: calc(100% + 36px);
            padding: 18px 0;
            margin-top: 1px;
            padding-bottom: 0;
        }
    }

    .admin-profile-subtab {
        margin-top: -18px;
    }

    .admin-access-notification {
        background-color: #dbf9ff;
        padding: 10px;
        margin: 16px auto 24px auto;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        text-align: center;
        /*
        position: sticky;
        top: 0;
        width: 100vw;
        margin-left: -18px;

        @media screen and (min-width: 992px) {
            width: 100%;
            margin-left: 0;
        }
        */

        .access-text {
            margin-bottom: 0;
            font-size: 13px;

            .access-bold {
                font-weight: bold;
            }
        }
    }    
}

.profile-container.family-page {
    .profile-name {
        display: none
    }
    .profile-tabs {
        display: none !important
    }
}
