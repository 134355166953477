.public-story-page { 

    .header {
        height: 100%;
        padding: 18px;
    }

    .loader-container {
        text-align: center;
    }

    .story-container {
        border-bottom: 1px solid rgba($base, 0.1);
        margin-bottom: 30px;
    }

    .error-container {
        text-align: center;
        padding: 3rem;

        p{
            margin: 0;
        }
    }

}