.pending-invitation-container {
    width: 100vw;
    position: relative;
    left: -18px;
    display: flex;
    padding: 22px 18px;
    border-bottom: 1px solid rgba($base, 0.07);

    @media screen and (min-width: 480px) {
        position: static;
        width: 100%;
    }

    .image-container {
        margin-right: 14px;

        img {
            height: 56px;
            width: 56px;
        }
    }

    .details-container {
        flex-grow: 1;

        div {

            .name {
                line-height: 18px;
                margin-bottom: 5px;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 1.17px;
                color: $base;
                font-size: 14px;
            }

            p {
                font-size: 13px;
                color: $gray;
                line-height: 16px;
                margin-bottom: 5px;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 5px;
                    width: 15px;
                    height: 15px;
                }
            }
            
            .feedback.accept {
                color: #66b23d;
            }

            .feedback.decline {
                color: #ef5a5a;
            }            

        }

        .form-select {
            text-transform: capitalize;
            width: 170px;
            height: 32px !important;
            background-color: transparent;
            padding: 0 12px;
            font-size: 13px !important;
            margin-bottom: 18px;
            color: $gray !important;

            .default {
                display: none;
            }
        }

        .cta-block {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                margin: 0;
                font-size: 13px;
                font-weight: 600;
            }

            svg:first-child {
                margin-right: 25px;
                cursor: pointer;

                &.accept {
                    color: #66b23d;
                }
                
                &.disabled {
                    color: rgba($base, 0.3);
                    cursor: unset;
                }
            }

            .refuse {
                color: #ef5a5a;
                cursor: pointer;
            }
        }
    }
}
