.family-groups {
    padding-top: 6px;
    @include content-min-height();

    .info-text {
        text-align: center;
        line-height: 21px;
        max-width: 80%;
        margin: auto;
        margin-bottom: 16px;
    }

    .feedback {
        text-align: center;
        padding: 36px 0 0;
        margin: 0;
        color: $gray;
    }

    .group-container {
        border-bottom: 1px solid rgba($base, 0.07);
        padding: 20px 0;

        .members {
            float: right;
            img {
                margin-right: 5px;
            }
            .group-length {
                display: inline;
                font-size: 10px;
                color: $gray;
                margin-right: 5px;
            }
        }

        svg {
            float: right;
            color: $gray;
            cursor: pointer;
        }

        p {
            margin-bottom: 0;
        }

        .name {
            text-align: left;
            font-weight: 600;
            font-size: 16px;
            color: $base-green;
            display: inline;
            line-height: 24px;
            letter-spacing: 0.5px;
            cursor: pointer;
        }

        .group-description {
            text-align: left;
            font-size: 12px;
            font-weight: 400;
            margin-top: 4px;
            line-height: 20px;
            cursor: pointer;
        }

        .manager {
            text-align: left;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            cursor: pointer;

            .m-name {
                color: $base-green;
                text-decoration: underline;
            }

            .m-name:hover {
                cursor: pointer;
                color: $base-green-hover;
            }
        }
        .stories {
            font-size: 12px;
            line-height: 20px;
            color: $gray;
        }
    }

    .action {
        padding-top: 36px;
        padding-bottom: 60px;
        text-align: center;

        p {
            text-align: center;
            line-height: 21px;
            margin-bottom: 36px;
        }
    }

    .group-container:hover {
        .name {
            color: $base-green-hover !important;
        }
    }
}
