.unique-list-input-container {
    margin-bottom: 48px;
    position: relative;

    .wrapper-icon {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translate(0, -50%);
        color: $gray;
    }

    .wrapper-icon:hover {
        cursor: pointer;
        color: $darkgray;
    }

    &:last-of-type {
        margin-bottom: 5px;
    }

    .example-story-select {
        width: 100%;
        border: 1px solid $gray;
        padding: 15px;

        .option-wrapper {
            padding-right: 5px;
        }

        p {
            margin: 0;
            font-size: 16px;
            color: $gray;
            line-height: 24px;
            letter-spacing: 0.15px;

            svg {
                float: right;
                font-size: 14px;
                margin-top: 5px;
            }
        }

        .option {
            border: 1px solid rgba($gray, 0.3);
            padding: 4px;
            display: inline-flex;
            align-items: center;
            width: fit-content;
            border-radius: 16px;
            margin-bottom: 8px;
            margin-right: 5px;
            height: 32px;
            max-width: 100%;

            &:last-of-type {
                margin-bottom: 0;
            }

            img {
                display: inline-block;
                width: 24px;
                height: 24px;
            }

            .icon {
                height: 24px;
                width: 24px;
                background-color: $blue;
                border-radius: 50px;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    color: $secondary;
                    font-size: 16px;
                }
            }

            p {
                display: inline-block;
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
                font-size: 12px;
                letter-spacing: 0.13px;
                color: $base;
                padding: 0 10px;
                flex-grow: 1;
            }

            .remove-icon {
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 6px;

                svg {
                    color: $gray;
                    font-size: 10px;
                    width: 18px !important;
                    height: 18px !important;
                }
            }

            .remove-icon:hover {
                svg {
                    color: $darkgray;
                }
                cursor: pointer;
            }
        }

        .locked{
            background-color: $lightgray !important;
            border: none !important;

            .remove-icon {
                display: none !important;
            }
        }
    }

    .example-story-list {
        width: 100%;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        position: absolute;
        background-color: $secondary;
        z-index: 12;
        border: 1px solid $gray;

        max-height: 300px;
        overflow: auto;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                
        &:empty {
            border: none;
            box-shadow: none;
        }

        p {
            padding: 10px 15px;
            margin: 0;
            font-size: 16px;
        }

        p:hover {
            cursor: pointer;
            background-color: rgb(199, 199, 199);
        }
    }
}

.story-tags .unique-list-input-container .example-story-select .option img,
.lifelesson-container .unique-list-input-container .example-story-select .option img,
.related-stories .unique-list-input-container .example-story-select .option img { display: none }

.unique-list-free-input-container {

    .example-story-select {
        border-bottom: 0;
    }

    .example-story-select + input {
        border-top:0 !important;
    }

}

.unique-list-free-input-container.group input:placeholder-shown + .top-label {
    display: block;
}
