.story-details {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    @media (max-width: 760px) {
        padding-left: 0;
        padding-right: 0;
    }

    > div {
        max-width: 500px;
        margin: auto;
        @include desktop-box-max-width();
        @media (min-width: 1600px) {
            max-width: 70%;
        }

        @media (min-width: 1000px) and (max-width: 1600px) {
            max-width: 80%;
        }
    }

    .story-details-footer {
        max-width: none;
    }

    .main-info {
        text-align: center;
        margin-top: 0.5rem;
        @media (min-width: 1000px) {
            padding: 0 4%;
        }

        .top {
            display: flex;
            justify-content: space-between;

            .title-container {
                flex: 10;
            }
        }

        p {
            margin-bottom: 1px;
        }

        .name {
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 500;
            @media (min-width: 1000px) {
                font-size: 18px;
                line-height: 20px;
                letter-spacing: 1.69px;
            }
        }

        .date {
            font-size: 12px;
            font-weight: 400;
            color: $gray;
            @media (min-width: 1000px) {
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
                text-align: center;
            }
        }

        .author {
            font-size: 12px;
            font-weight: 400;
            color: $gray;          
        }

        .co-author {
            font-size: 14px;
            font-weight: 400;
            color: $gray;
        }

        .author, .co-author {
            @media (min-width: 1000px) {
                font-size: 14px;
                line-height: 18px;
                text-align: center;
            }
        }

        .modal-icon {
            color: $base-green;
        }

        .modal-icon:hover {
            color: $base-green-hover;
            cursor: pointer;
        }

        .editcard {
            float: right;
            flex: 1;
        }

        .infocard {
            float: left;
            flex: 1;
            .storie-info-icon {
                width: 1.5rem;
                height: 1.5rem;
                @media (min-width: 1000px) {
                    width: 3rem;
                    height: 3rem;
                }
            } 
        } 

        .user-avatar {
            text-align: center;
            margin-top: 20px;
            position: relative;

            img {
                width: 100px;
                height: 100px;
                margin-bottom: 20px;
                object-fit: cover;
            }
        }

        .user-avatar:hover {
            cursor: pointer;
        }       
    }

    .separator {
        margin: auto;

        z-index: 1;
        width: 10%;
        border-bottom:1px solid rgba(23, 36, 61, 0.1);

        padding-top: 40px;
        margin-bottom: 40px;
        @media (max-width: 760px) {
            display: none;
        }
    }

    .fav-container {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;

        svg {
            color: $gray;
            margin-bottom: 10px;
        }

        p {
            margin-top: 5px;
            color: $gray;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 1.25px;
            line-height: 18px;
        }
    }

    .fav-container:hover {
        cursor: pointer;
    }

    .active {
        svg {
            color: $gold;
        }
        p {
            color: $gold;
        }
    }

    .line {
        margin-top: 60px;
        margin-bottom: 40px;
        z-index: 1;
        width: 100vw;
        max-width: 500px;
        height: 1px;
        background-color: rgba(23, 36, 61, 0.1);
        overflow: visible;
        @media (min-width: 1000px) {
            max-width: 100%;
        }
    }


    .story-body {
        .title {
            text-align: center;
            font-size: 28px;
            line-height: 36px;
            @media (min-width: 1000px) {
                font-size: 36px;
                letter-spacing: 0.5px;
                line-height: 48px;
            }
            @include story-box-medium();
        }
        
        .display-block-text .text-data {
            @include display-story-text();
            @include story-box-medium();
        }
        .display-block-text p {
            @include display-story-text();
        }
    }

    .display-block-media  {
        .content {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
        .content.one-image-in-gallery {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            .carousel-inner {
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            }
        }
    }
}

.story-action-modal {
    .modal-header {
        border-bottom: 0;
        height: 60px;
    }
    .edit-card-content {
        padding: 0 60px 20px 60px;

        .link-row {
            padding: 15px;
            // display:flex;
            align-items:center;
    
            border-bottom: 1px solid rgba($base, 0.05);
            cursor: pointer;
            padding: 19px 0;
    
            &:last-child {
                border: none;
            }
    
            &:hover {
                p {
                    color: $gray;
                }
            }
    
            svg {
                width: 40px !important;
                height: 40px !important;
                padding: 7px;
                float: left;
                background-color: $base-green;
                color: white;
                border-radius: 30px;
                margin-right: 10px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            }

            .main-text-single {
                display: inline-block;
                margin-bottom: 0;
                padding-top: 10px;
                font-weight: 600;
                font-size: 16px;
            }
    
            .main-text-info {
                font-weight: 500;
                font-size: 16px;
                margin-bottom: 0;
                align-items:center;
    
            }
    
            .info-text {
                font-weight: 400;
                font-size: 12px;
                color: $gray;
                margin-bottom: 0;
            }
        }
    }

}

.story-details.story-details-footer > div { max-width: none }


.story-details-footer {

    .whats-next {
        margin-top: 20px;

        .show {
            background-color: white !important;
            border-bottom: none !important;
            padding-bottom: 10px !important;

        }

        .whats-next-header {
            background-color: $lightgray;
            padding: 35px;
            border-top: 1px solid #f3f4f5;
            position: relative;
            text-align: center;
            border-bottom: 1px solid rgba(23, 36, 61, 0.1);

            .main {
                color: $base-green;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 1.25px;
                margin-bottom: 5px;
            }
        
            .info-text {
                color: $gray;
                font-size: 12px;
                font-weight: 400;
            }

            svg {
                position: absolute;
                right: 15px;
        
                top: 50%;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
        
                color: rgba($gray, 0.3);
                font-size: 12px;
            }
        }

        .whats-next-body {
            text-align: center;
            margin: auto auto 40px auto;
            max-width: 500px;
            @include desktop-box-max-width();

            .sub-header {
                margin-top: 40px;
                margin-bottom: 20px;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: 1.25px;
                font-size: 12px;
                line-height: 24px;
            }

            .info-text {
                color: $gray;
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 0;
            }

            .user-picture {
                width: 60px;
                height: 60px;
            }

            .no-click {
                .user-picture {
                    cursor: default;
                }
            }
            
            .xs-2 {
                width: 50%;
                float: left;
            }

            .xs-3 {
                width: 33%;
                float: left;
            }

            .story-tag {
                border: 1px solid rgba($base-green, 0.3);
                padding: 4px;
                display: inline-flex;
                align-items: center;
                width: fit-content;
                border-radius: 16px;
                margin-bottom: 8px;
                margin-right: 5px;
                height: 32px;

                &:hover {
                    border-color: $base-green-hover;
                }
    
                &:last-of-type {
                    margin-bottom: 0;
                }
    
                span {
                    display: inline-block;
                    width: 24px;
                    /* height: 24px; */
                    border: 1px solid rgba($base-green, 0.3);
                    border-radius: 16px;
                    margin-right: 8px; 
                    background-color: $base-green;
                    color: $secondary;     
                }
    
                p {
                    display: inline-block;
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    font-size: 12px;
                    letter-spacing: 0.13px;
                    color: $base-green;
                    padding: 0 2px;
                    flex-grow: 1;
                    margin-bottom: 0;
                }

            }

            .story-tag:hover {
                cursor: pointer;
            }
                        
        }
    }
}