.attachment-upload {
  .icon-group {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 30px;
    margin-bottom: 60px;

    p {
        color: $gray;       
    }

  }

  .attachment {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;    
    color: RGBA(23, 36, 61, 0.3);
  }
  
}
