.subscription-upgrade-container {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    padding-bottom: 20px;
    margin-bottom: 20px;

    .upper-container {
        padding: 30px;
        background-color: #FFF3DB;

        p {
            margin: 5px;
        }

        p:first-child {
            color: #A38138;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 32px;
            text-transform: uppercase;
        }

        p:last-child {
            color: rgba(23,36,61,0.5);
            font-size: 14px;
            letter-spacing: 0;
            max-width: 250px;
            margin: auto;
        }
    }

    .lower-container {

        .base-info {
            padding-top: 40px;
            padding-bottom: 40px;

            margin: auto;
            max-width: 300px;

            color: #17243D;
            font-size: 14px;
            letter-spacing: 0.25px;
            line-height: 21px;
            text-align: center;
        }

        .button-container {

            p {
                margin-top: 20px;
                color: rgba(23,36,61,0.5);
                font-size: 14px;
                letter-spacing: 0;
            }

            .light-button {
                color: $base-green !important;
            }
        }
    }
}