.display-audio {

    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;

    .optional-desc {
        font-size: 18px;
        color: rgba(23, 36, 61, 0.5);        
    }    

    .record-button {
        width: 64px;
        height: 64px;
        border-radius: 40px;
        border: none;
        margin-bottom: 10px;

        svg {
            color: white;
        }
    }

    .play {
        background-color: $base-green;
    }

    .pause {
        background-color: RGB(239, 90, 90);
    }

    .audio-title {
        font-size: 16px;
        font-weight: 500;
        padding-top: 10px;
        padding-bottom: 5px;
        @media (min-width: 1000px) {
            font-size: 24px;
        }
    }

    .audio-user {
        font-size: 14px;
        font-weight: 400;
        color: $gray;
    }

    .wave {
        margin: auto;
    }
}

.display-text {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 40px;
    padding-bottom: 40px;

    span {
        font-size: 16px !important;
        font-weight: 400 !important;
        color: $base !important;
        font-family: $font !important;
        letter-spacing: 0.5px;
        line-height: 24px;     
        min-height: 24px; /* remove? */ 
    }

    p {
        font-size: 16px !important;
        font-weight: 400 !important;
        color: $base !important;
        font-family: $font !important;
        letter-spacing: 0.5px;
        line-height: 24px;
        min-height: 24px;  /* remove? */     
    }
}

.block.VIDEO {
    .display-media {
        .people {
            left: 85px;
            bottom: 58px;
        }
    }   
}

.display-media {

    padding-top: 30px;
    text-align: center;
    padding-bottom: 50px;
    
    .single-image-story-block {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        .story-block-image   {
            //@include media-default-settings();
            @include media-default-settings-one-img();
    
        }
    }

    .video {
        max-height: 400px;
    }

    .optional-desc {
        font-size: 18px; /* 14px */
        font-weight: 400;
        color: $gray;
        margin-bottom: 20px;
    }

    .interactions {

        .interaction-container {

            width: 40px;
            height: 40px;
            background-color: white;
            border-radius: 30px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            position: absolute;
            display: flex;
            justify-content: center;

            .big-icon {
                color: $base-green;
                display: block;
                margin:auto;
            }

            .added {
                position: absolute;
                top: 45px;
                color: $gray;
            }

            .record-user {
                width: 200px;
                font-size: 14px;
                position: absolute;
                top: 45px;
                color: $gray;
            }

            .record-user-add {
                width: 200px;
                font-size: 14px;
                position: absolute;
                top: 45px;
                color: $base-green;
            }

            .small {
                position: absolute;
                right: 1px;
                bottom: 27px;
                z-index: 10;
                width: 15px;
                height: 15px;
                background-color: $base-green;
                border-radius: 10px;


                .add-text {
                    position: relative;
                    width: 40px;
                    top: 37px;
                    right: 24px;
                    font-size: 14px;
                    color: $base-green;
                }

                .small-icon {
                    color: white;
                    display: block;
                    margin:auto;
                }
            }
        }

        .interaction-container:hover {
            background-color: $lightgray;
            cursor: pointer;
        }

        .people {
            left: 85px;
            bottom: 53px;
        }

        .audio {
            right: 36px;
            bottom: 53px;
        }

        .comment {
            left: 36px;
            bottom: 53px;
            .disabled-icon {
                color: $gray;
            }
        }
    }
}

// MEDIA MODALS
.members-modal {

    .modal-body {
        padding: 0;

        .sub-subtitle {
            font-size: 12px;
            font-weight: 400;
            color: $gray;
            margin-left: 10px;
            margin-top: 5px;
        }

    }

    .co-author-info {
        text-align: center;
        font-size: 17px;
        padding-bottom: 40px;
        color: $gray;
    }

    img {
        width: 100%;
    }

    .video-cont {
        margin-top: 10px;
        text-align: center;
    }
}

.narration-modal {

    .modal-body {
        padding: 0;

        .default-button {
            margin-top: 40px;
            margin-bottom: 10px;
        }

        .sub-subtitle {
            font-size: 12px;
            font-weight: 400;
            color: $gray;
            margin-left: 10px;
            margin-top: 5px;
        }

        .buttons {
            margin-bottom: 40px;
        }
    }

    img {
        width: 100%;
        margin-bottom: 40px;
    }
}

.display-carousel {
    padding-top: 30px;
    text-align: center;
    padding-bottom: 70px;

    .optional-desc {
        font-size: 18px; /* 14px */
        font-weight: 400;
        color: rgba(23, 36, 61, 0.5);
        margin-bottom: 20px; 
    }

    .current-page {
        padding-top: 30px;
        font-size: 12px;
        font-weight: 400;
        color: $gray;
    }

    .story-block-video {
        height: 490px;

    }
    .story-block-media {
        @include media-default-settings();
    }

    .carousel-control-prev,
    .carousel-control-next {
        height: 20%;
        top: 40%;
        width: 100px;
    }

    .carousel-control-prev {
        left: -15px;
    }
    .carousel-control-next {
        right: -15px;
    }

    .carousel-indicators {
        margin-bottom: 0;
        bottom: -20px;
        .active {
            background-color: $base-green!important; 
        }
    }

    .carousel-indicators [data-bs-target] {
        width: 6px;
        height: 6px;
        background-color: rgba(23,36,61,0.3);
        border-radius: 5px;
        border: 0;
    }

    button[aria-label="big-dot"] {
        width: 6px !important;
        height: 6px !important;
    }

    button[aria-label="middle-dot"] {
        width: 4px !important;
        height: 4px !important;
    }

    button[aria-label="small-dot"] {
        width: 2px !important;
        height: 2px !important;
    }

    button[aria-label="hidden-dot"] {
        display: none !important;
    }

    .carousel {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        .carousel-control-prev-icon, .carousel-control-next-icon {
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
            background-color: RGBA(0, 0, 0, 0.1);
        }
    }

    .interactions {

        .interaction-container {

            width: 40px;
            height: 40px;
            background-color: white;
            border-radius: 30px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            position: absolute;
            display: flex;
            justify-content: center;

            .big-icon {
                color: $base-green;
                display: block;
                margin:auto;
            }

            .added {
                position: absolute;
                top: 45px;
                color: $gray;
            }

            .record-user {
                width: 200px;
                font-size: 14px;
                position: absolute;
                top: 45px;
                color: $gray;
            }

            .record-user-add {
                width: 200px;
                font-size: 14px;
                position: absolute;
                top: 45px;
                color: $base-green;
            }

            .small {
                position: absolute;
                right: 1px;
                bottom: 27px;
                z-index: 10;
                width: 15px;
                height: 15px;
                background-color: $base-green;
                border-radius: 10px;

                .add-text {
                    position: relative;
                    width: 40px;
                    top: 37px;
                    right: 24px;
                    font-size: 14px;
                    color: $base-green;
                }

                .small-icon {
                    color: white;
                    display: block;
                    margin:auto;
                }
            }
        }

        .interaction-container:hover {
            background-color: $lightgray;
            cursor: pointer;
        }

        .people {
            left: 85px;
            bottom: 140px;
            @media (min-width: 320px) and (max-width: 400px) {
                left: 55px;
            }
        }

        .audio {
            right: 36px;
            bottom: 140px;
            @media (min-width: 320px) and (max-width: 400px) {
                right: 10px;
            }
        }

        .comment {
            left: 36px;
            bottom: 140px;
            @media (min-width: 320px) and (max-width: 400px) {
                left: 10px;
            }
            .disabled-icon {
                color: $gray!important;
            }
        }
    }
}

//Desc edit
.block {

    .group {
        margin-top: 10px;
        margin-bottom: 30px;
        input {
            width: 80%;
            display: inline-block;
        }
    
        .submit-icon-container {
            margin-left: 10px;
            display: inline-block;
        }

        .submit-icon-container:hover {
            color: $base-green;
            cursor: pointer;
        }
    }
}