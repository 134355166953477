.connect-to-family-container {
    text-align: center;

    .connect-title {
        margin-bottom: 8px;
    }

    .connect-info {
        font-size: 12px;
        font-weight: 400;
        color: $gray;
        margin-bottom: 48px;
    }

    .no-pending-container {
        p {
            font-size: 16px;
            font-weight: 500;
        }

        .no-pending-text {
            margin-bottom: 36px;
        }

        .decision-box {
            background-color: #fff3db;
            padding: 33px 0 48px 0;
            margin-bottom: 48px;
            width: 100vw;
            position: relative;
            left: -18px;

            @media screen and (min-width: 480px) {
                position: static;
                width: 100%;
            }

            p {
                padding: 0 20px;
                max-width: 290px;
                margin: auto;
                margin-bottom: 36px;
            }

            button {
                margin: 0px 20px 0px 20px;
                background-color: $base-green !important;
                color: white !important;
                height: 40px;
                width: 90px;
                border: none !important;
                border-radius: 0;
                font-size: 14px;
                font-weight: 500;
            }

            button:hover {
                background-color: $base-green-hover !important;
            }
        }
    }

    .connections-container {
        margin-top: 50px;

        .someone-is-missing {
            margin-top: 40px;
        }
    }
}

.connect-to-family-container.no-next-button + button { display: none !important }