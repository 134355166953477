.existing-profile {
    .relationship-picker-container {
        .result-container {
            width: 100%;
        }
    }
}

.relationship-picker-container {
    margin-bottom: 36px;
    position: relative;

    input {
        margin-bottom: 0 !important;
        text-transform: capitalize;

        &:focus {
            border: 2px solid $gold !important;

            + .top-label {
                color: $gold;
            }
        }

        &.error {
            border: 2px solid $red !important;
        }
    }

    input:focus ~ .top-label {
        color: $gold !important;
    }

    .result-container {
        box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 8px 0px;
        position: absolute;
        background-color: $secondary;
        z-index: 10;
        width: 100%;
        @media screen and (max-width: 520px) {
            width: 90%;
        }

        .nav-tabs {
            width: 100%;
            position: static;
            padding: 0;
            background-color: transparent;
            justify-content: flex-start;
            margin-bottom: 0;
            border-bottom: 1px solid rgba($base, 0.1);
            height: 49px;
            overflow: visible;

            .nav-item {
                padding: 0 10px;
                @media screen and (max-width: 426px) {
                    padding: 0 5px;
                }

                .nav-link {
                    margin: 0;
                    text-transform: uppercase;
                    font-size: 12px;
                    @media screen and (max-width: 385px) {
                        font-size: 10px;
                    }
                    box-shadow: none;
                    padding: 0;
                    font-weight: 600;
                    height: 48px;
                    letter-spacing: 1.25px;
                    border: none;
                    color: $gray;

                    &.active {
                        border-bottom: 2px solid $gold;
                        color: $gold;
                    }
                }
            }
        }

        .tab-content {
            padding: 0 16px;
            max-height: 400px;
            overflow: auto;

            p {
                font-size: 16px;
                text-transform: capitalize;
                margin: 0;
                line-height: 48px;
                display: block !important;
                font-weight: 400 !important;
            }

            p:hover {
                cursor: pointer;
                color: $gray;
            }
        }
    }
}
