.infocard-container {
    
    .modal-dialog {
        // desktop size modal -> Story Info card 
        @include desktop-medium-sized-model();
      
        .modal-content {

            .tooltip-content {
                padding: 30px 60px 0px 60px;
            }
        
            .infocard-header {
                margin-bottom: 0;
            }
    
            .infocard-content {
                
                p {
                    margin-top: 0.7rem;
                    margin-bottom: 0.7rem;
                    font-size: 14px;
                    line-height: 21px;

                    div {
                        display: inline-block;
                    }                    
                }
                
                p:nth-of-type(2n + 1) {
                    float: left;
                    color: $gray;
                    margin-right: 0.7em;
                }
        
                p:nth-of-type(2n) {
                    text-align: right;
                }

                .story-tags{

                    p {
                        a {
                            display: block;
                            text-align: right;
                        } 

                    }

                }

                .infocard-text-section {
                    margin-bottom: 30px;
                }

            }

            .infocard-content > div > div > div {
                overflow: hidden;
                border-bottom: 1px solid rgba(23, 36, 61, 0.1);
            }

            .infocard-content > div > div > div:last-child {
                border-bottom: none;
            }

        }
    }

    .displayed-google-map {
        border-bottom: 0!important;
    }
}