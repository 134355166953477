.story-filters {
    margin: auto;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 200px;
    /* background-color: white; */
    background-color: #f3f4f5;

    .with-tooltip {
        .unique-list-input-container {
            margin-bottom: 5px !important; //overwrite global margin 
        }

        .unique-single-select-container {
            margin-bottom: 5px !important; //overwrite global margin 
        }

        .tooltip-text {
            margin-bottom: 30px;
        }
    }

    .unique-single-select-container {
        margin-bottom: 48px !important; //overwrite global margin 
    }

    .react-datepicker-wrapper {
        margin-bottom: 48px !important; //overwrite global margin 

    }

    .dates-input-container {
        height: 56px;
        margin-bottom: 30px;
    }

    .filter-group-title {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        padding: 36px;
    }

    .people {
        margin: auto;
        max-width: 400px;
        @include desktop-box-max-width();
    }

    .story {
        margin: auto;
        max-width: 400px;
    }

    .toggels {
        margin: auto;
        max-width: 400px;
        margin-bottom: 100px;

        .switch-container {
            display: flex;
            justify-content: space-between;
            max-width: 500px;
            align-items: center;
            margin: 20px 0;
            margin-bottom: 30px;
            .infos {
                span {
                    color: $base;
                    font-size: 16px;
                    letter-spacing: 0.15px;
                    line-height: 24px;
                }
            }

            .react-switch-bg {
                opacity: 0.15;
            }

            .status {
                display: flex;
                align-items: center;
                color: $gray;

                .switch-status {
                    float: right;
                    margin-right: 10px;
                    font-size: 14px;
                    font-weight: 400;
                    color: $gray;
                    margin-bottom: 0;
                }
            }

            .switch-status {
                float: right;
                margin-right: 10px;
                font-size: 14px;
                font-weight: 400;
                color: $gray;
                margin-bottom: 0;
            }
        }
    }

    .default-button {
        margin-top: 100px;
    }
}