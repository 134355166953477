.inspiration-container {
  text-align: center;

  .button-center {
    margin: 0 auto;
  }

  .display-block-media {
    .story-block-image {
      width: auto;
      height: 400px;
      max-width: -webkit-fill-available;
    }
  }

  .open-item-background {
    padding-bottom: 30px;
    padding-top: 67px;
    background: #FFF3DB;
    margin-top: -112px;
    margin-bottom: 60px;

    @media screen and (max-width: 576px) {
      padding-top: 58px !important;
      margin-top: -91px !important;
    }

    svg {
      background: white;
    }
  }

  p.heading {
    margin: 24px 0;
    display: flex;
    justify-content: center;
    color: rgba(23, 36, 61, 0.7);
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }

  .title {
    margin: 40px auto;
    margin-top: 30px;

    svg {
      cursor: pointer;
      color: rgba(23, 36, 61, 0.5);
      margin-left: 10px;
      padding: 2px;
    }
  }

  .inspiration-item-container.no-shadow {
    svg {
      box-shadow: none;
    }
    .display-block-carousel {
      svg {
        box-shadow: 0 1px 5px 0 rgba(100, 100, 111, 0.5);
      }
    }

    .display-block {
      margin-bottom: 0px;
    }
  }

  .inspiration-item-container {
    text-align: center;
    max-width: 700px;
    margin: 0 auto;

    .link-button {
      letter-spacing: 1.5px;
    }

    .display-block {
      margin-top: 20px;
      margin-bottom: 20px;

      @media screen and (max-width: 767px) {
        margin-top: 30px;
      }
      @media screen and (max-width: 576px) {
        margin-bottom: 40px;
      }

      .content {
        cursor: pointer;
      }
    }

    .display-block-carousel {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 20px 29px 0px;

      .carousel-control-prev, .carousel-control-next {
        height: 100%;
        top: 0;
      }

      .carousel-control-next {
        right: 16px;
        
      }
      .carousel-control-prev {
        left: -48px;
      }

      .question,
      .follow-up {
        width: 60%;
        margin: 0 auto;
        padding: 2rem;

        @media screen and (max-width: 767px) {
          width: 85%;
          padding-top: 1rem;
        }
      }

      .question {
        font-size: 18px;
        line-height: 1.2;
        min-height: 6rem;
        padding-bottom: 0;
      }

      .follow-up {
        color: $base;
        padding-top: 1rem;
        min-height: 2.5rem;
      }

      .default-button {
        max-width: 356px;
        margin-bottom: 1rem;
      }

      button {
        padding: 1rem !important;
        margin-bottom: 1rem;
      }
    }

    .display-block-inspiration {
      text-align: left;
      padding-top: 20px;

      .content {
        display: flex;
      }

      .content.show {
        align-items: center;

        svg {
          top: 71px;
          right: 24px;

          &:hover {
            color: $base;
          }

          @media screen and (max-width: 576px) {
            right: 20px;
            top: 52px;
          }
          @media screen and (max-width: 380px) {
            right: 10px;
            top: 52px;
          }
        }

        p.story-tag {
          padding-top: 0;
        }
      }

      .content img {
        width: 120px;
        height: 120px;
        margin-bottom: 20px;
        margin-left: 20px;
        object-fit: cover;
        float: left;
        border-radius: 50%;
        z-index: 100;
        box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2);

        @media screen and (max-width: 576px) {
          width: 85px;
          height: 85px;
        }
      }

      .story-tag {
        width: 100%;
        font-size: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        color: $base-green;
        font-weight: 500;

        @media screen and (max-width: 576px) {
          padding-left: 10px;
        }
      }

      .description {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: rgba(23, 36, 61, 0.5);
        margin-bottom: 0px;
        padding-left: 20px;
        padding-right: 20px;
        padding-right: 16px;


        @media screen and (max-width: 767px) {
          padding-left: 20px;
          margin-bottom: 0px;
          text-align: left;
          width: auto;
        }

        @media screen and (max-width: 576px) {
          padding-left: 10px;
        }
      }

    }

    svg {
      position: absolute;
      right: 0px;
      top: 73%;
      transform: translateY(-80%);
      color: rgba(23, 36, 61, 0.3);
      font-size: 12px;
      box-shadow: 0 1px 5px 0 rgba(100, 100, 111, 0.5);
      border-radius: 9999px;

      @media screen and (max-width: 767px) {
        top: 70%;
      }

      @media screen and (max-width: 576px) {
        top: 65%;
      }

      @media screen and (max-width: 440px) {
        top: 60%;
      }
    }

    .carousel-control-prev {
      svg {
        right: 5px;
      }
    }
  }

  .something-missing {
    background-color: #fff2db;
    padding: 20px;
    text-align: center;
    padding: 24px 16px 24px 16px;
    position: relative;
    max-width: 700px;
    margin: 0 auto 50px auto;

    .container{
      border: 1px solid $base-green;

      p {
        margin-bottom: 40px;
      }

      button {
        margin-bottom: 40px;
      }
    }


  }

  .usertips-container {
    margin-top: 60px;
    margin-bottom: 60px;

    .usertips-content {
      padding: 0px 20px 45px 20px;

      p {
        margin-bottom: 40px;
      }
    }
  }
}

.today-container-heading.hero-slider {
  .inspiration-item-container {

    .display-block-inspiration {
      .description {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 10px;
        text-align: center;
      }
    }

    .open-item-background {
      padding-top: 32px !important;
      background: none;
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
  }
}
