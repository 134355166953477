.need-help {
  margin-top: 0;

  .help-container {
    max-width: 500px;
    margin: auto auto 50px auto;
    padding: 20px;

    @include desktop-box-max-width();

    .accordion {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 22px;
        left: 11px;
        width: 1px;
        height: calc(100% - 60px);
        background-color: rgba(23, 36, 61, 0.12);
        z-index: 98;
      }

      @media screen and (max-width: 530px) {
        margin-left: 14px;
        margin-right: 14px;
      }

      .accordion-collapse {
        .accordion {
          box-shadow: none !important;
          margin-bottom: 0px !important;
          padding-bottom: 0px !important;
        }
      }

      .accordion-item {
        border-bottom: none;
        position: relative;

        .accordion-header {
          .accordion-button {
            padding-bottom: 8px;
          }
          .accordion-button.collapsed {
            padding-bottom: 8px;
          }
        }

        &::after {
          content: "";
          width: calc(100% - 56px);
          position: absolute;
          margin-left: 4px;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 1px;
          background-color: white !important;
        }

        .accordion-collapse.collapse {
          .accordion-body {
            padding-left: 32px;

            p {
              margin-bottom: 0;
            }
          }
        }

        .accordion-collapse {
          .accordion-body {
            padding-left: 32px;
          }
        }

        .accordion-collapse.collapse.show {
          padding-top: 16px;


          &::after {
            display: none;
          }

          .accordion-header {
            .accordion-button {
              padding-bottom: 20px;
            }
          }

          .accordion-body {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 32px;

          }
        }

        .accordion-header {
          background-color: transparent;
          border: none !important;
          button.accordion-button:not(.collapsed) {
            background-color: white;
            box-shadow: none;
            color: $base-green;
          }

          button.accordion-button {
            border: none;
            padding-left: 32px;
            padding-top: 24px;
            padding-bottom: 24px;

            &[aria-expanded="true"] {
              padding-bottom: 0;
              z-index: 2;
              p {
                color: $base-green;
              }
            }

            .title {
              margin-bottom: 0;
              text-align: left;
            }
          }
        }
      }
    }  
  }

  .show {
    background-color: white !important;
    border-bottom: none !important;
    padding-bottom: 10px !important;

    svg {
      margin-top: 10px;
    }
  }

  .need-help-header {
    padding: 35px;
    border-top: 1px solid rgba(23, 36, 61, 0.1); /* $lightgray; */
    position: relative;
    text-align: center;
    border-bottom: 1px solid rgba(23, 36, 61, 0.1);
    background-color: $lightgray;

    .main {
      color: $base-green;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1.25px;
      margin-bottom: -5px;
    }

    .info-text {
      color: $gray;
      font-size: 12px;
      font-weight: 400;
    }

    svg {
      position: absolute;
      right: 15px;

      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);

      color: rgba($gray, 0.3);
      font-size: 12px;
    }
  }

  .not-helpful-body {
    textarea {
      width: 100%;
    }

    .right {
        float: right;
    }
    .sub-subtitle {
        font-size: 12px;
        color: RGBA(23, 36, 61, 0.5);
    }

    button {
        clear: both;
    }
  }

  .need-help-header.not-helpful-header {
    background-color: white;
    border: 0;
  }
}

/*
    .need-help {
        border-bottom: 1px solid rgba($darkgray, 0.1);
        width: 100%;
        margin: 0;

        background-color: $lightgray;
        padding: 35px;
        border-top: 1px solid #f3f4f5;
        position: relative;
        text-align: center;   

        .header {
            background-color: $lightgray;
            padding: 35px;
            border-top: 1px solid #f3f4f5;
            position: relative;
            text-align: center;
            border-bottom: 1px solid rgba(23, 36, 61, 0.1);
        }
        
        svg {
            position: absolute;
            right: 10px;
    
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    
            color: rgba($gray, 0.3);
            font-size: 12px;
        }        

        .help-container {

        }        

        .dropdown {
            position: relative;
        }

        .button.dropdown-toggle {
            .down-icon {
                position: absolute;
                right: 22px;
                top: 30%;
                color: rgba($darkgray, 0.3);
            }

            &::after {
                display: none;
            }

            &:hover {
                color: $darkgray;

                .down-icon {
                    color: $darkgray;
                }
            }

            @media screen and (min-width: 480px) {
                max-width: 200px;
            }
        }
    }

*/

.accordion-item-container {
  .accordion-header {
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    position: relative;

    p.title {
      text-align: left;
      padding-left: 6px;
      color: $base;
      margin-bottom: 10px;
      font-size: 16px;

      &.opened {
        color: $base-green;
      }
    }

    .icon-container {
      width: 24px;
    }
    svg {
      color: $base-green;
      z-index: 100;
    }
  }

  p.answer {
    padding-left: 30px;
    padding-right: 20px;
  }

}

.accordion-shadow {
  &:first-of-type {
    margin-top: 10px !important;
  }
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  padding-bottom: 30px;
  margin-left: 14px;
  padding-left: 0;
  margin-right: 14px;

  .accordion-header {
    .accordion-button {
      padding-left: 18px !important;
    }
  }
}

.accordion-collapse.collapse.show {
  z-index: 100;
}
