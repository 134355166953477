.image-viewer-fullscreen {
    
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    // background: rgba(51,51,51);
    background: black;
    /* padding: 50px; */
    z-index: 100000;
    overflow: auto;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;

    

    .carousel {

        .thumbs-wrapper {
            margin-left: 0px;
        }

        .thumbs {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            @media screen and (max-width: 335px) {
                zoom: 0.8 !important;
            }

            @media screen and (max-width: 600px) {
                justify-content: flex-start;
                &.animated {
                    transform: translate3d(0px, 0px, 0px) !important;
                }
            }
        }

        .thumb {
            height: 100px !important;
            max-width: 200px !important;
            object-fit: cover;
            border: 3px solid #333;
        }

        .thumb.selected {
            border: 3px solid #fff;
        }          

        .thumb.selected:hover {
            border: 3px solid #aaa;
        }        

        .carousel-slider {
            height: 75vh;

            @media screen and (max-width: 180px) {
                height: 70vh;
            }

            @media screen and (max-width: 1024px) {
                height: 65vh;
            }
        }
    }

/*
    @media screen and (max-width: 436px) {
        padding: 0px;

        .right {
            display: none;
        }

        .left {
            display: none;
        }

        .carousel {
            bottom: -10vh;
        }
    }
*/

    .top-bar {
/*        height: 50px;*/
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 10;

        svg {
            color: white;
            float: right;
            /* margin: 10px; */

        }

        .close:hover {
            color: rgb(209, 209, 209);
            cursor: pointer;
        }
    }

    .main-image-container {
        position: relative;
        margin: auto;


        svg {
            color: white;
        }

        svg:hover {
            color: rgb(209, 209, 209);
            cursor: pointer;
        }
        .right {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
        .left {
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            position: absolute;
        }

        .main-image {
            img {
                /* max-width: 90%; */
                object-fit: contain;
                height: 100vh; /* 75vh; */
                max-height: 100vh; /* 900px; */


                @media screen and (min-width: 1680px) {
                    height: 90vh;
                }

/*
                @media screen and (max-width: 1680px) {
                    height: 70vh;
                }

                @media screen and (max-width: 1024px) {
                    height: 65vh;
                }
                @media screen and (max-width: 800px) {
                    height: 55vh;
                }
                @media screen and (max-width: 450px) {
                    height: 45vh;
                }
*/
            }

            video {
                max-width: 90%;
                max-height: 900px;
                object-fit: contain;
            }
        }
    
    }
  
/*
    .image-list {
        margin: auto;
        width: 77%;
        text-align: center;

        .small-image {
            display: inline-flex;
            margin: 19px;
            img {
                max-width: 210px;
                max-height: 150px;
            }
        }

        .selected {
            border: 1px solid white;
        }
    }
*/
}