.story-visuals-container {

    background-color: #f3f4f5; /* */
    /* border-bottom: 1px solid rgba(23, 36, 61, 0.1); */

    @media only screen and (min-width: 992px) {
        min-height: 51.5vh;
    }

    .active-filter-container {
        padding-top: 20px;
        margin: auto;
        text-align: center;
        position: relative;
        max-width: 700px;

        .remove:hover {
            cursor: pointer;
            svg {
                color: $lightgray;
            }
        }

        .active-filter-box {
            margin: 4px;
            display: inline-flex;
            background-color: white;
            border: 1px solid rgba(23,36,61,0.1);
            border-radius: 20px;
            padding: 3px;

            svg {
                color: $gray;
                margin-left: 5px;
                margin-right: 5px;
            }

            p {
                color: $gray;
                margin-right: 5px;
                margin-left: 5px;
                margin-bottom: 0;
                font-size: 12px;
                letter-spacing: 0.25px;
                line-height: 20px;
            }
        }
    }

    .story-count {
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: $gray;
        padding: 24px 0;
        margin: 0;
    }

    .has-more {
        text-align: center;
        font-size: 12px;
        color: $gray;
        padding: 20px;
        margin: 0;
    }

    .info-row {
        display: flex;
        max-width: 660px;
        justify-content: space-between;
        margin: auto;
        padding-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        @include desktop-box-max-width();

        // sticky
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
        z-index: 1000;
        background-color: rgba(243, 244, 245, 0.96);;

        @media screen and (max-width: 576px) {
            max-width: 350px;

        }

        .result {
            color: rgba(23,36,61,0.7);
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
        }

        .left {
            display: inline-flex;

            .p {
                display: inline-flex;
            }

            .select-all {
                margin-left: 16px;
                color: #006238;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 18px;
                text-decoration: underline;
            }

            .select-all:hover {
                cursor: pointer;
            }
        }
        .right {
            margin-right: 0;
            color: #006238;
            text-decoration: underline;

            p {
                font-size: 12px;
                letter-spacing: 0;
                line-height: 18px;
            }

            :hover {
                cursor: pointer;
            }

            .cancel {
                display: flex;
                color: gray;
                p {
                    color: #EF5A5A;
                    text-decoration: underline;
                }

                svg {
                    margin-left: 20px;
                }
            }
        }
    }

    .story-filters-bar-visuals {

        .right-filters {
            .filter {
                margin-right: 0;
            }
        }

        .left-filters {
            .options {
                width: 210px;
            }
        }
    }

    .visuals {
        margin: auto;
        max-width: 660px;
        padding-bottom: 40px;

        @media screen and (max-width: 576px) {
            max-width: 600px;
        }

        //@include desktop-box-max-width();

        
    
        .visual {
            position: relative;
            display: inline-flex;
            margin: 10px;
            width: 200px;
            height: 200px;

            @media screen and (max-width: 576px) {
                width: 175px;
                height: 175px;
            }

            @media screen and (max-width: 375px) {
                width: 165px;
                height: 165px;
            }

            .fav-star {
                position: absolute;
                color: white;
                border-radius: 25px;
                right: 8px;
                top: 5px;
            }

            .video-visual {
                /* position: relative; */
                width: 100%;
                .duration {
                    position: absolute;
                    bottom: -10px;
                    left: 5px;
                    color: white;
                }
            }

            img {
                object-fit: cover;
                width: 200px;
                height: 200px;
                vertical-align: baseline;

                @media screen and (max-width: 576px) {
                    width: 175px;
                    height: 175px;
                }

                @media screen and (max-width: 375px) {
                    width: 165px;
                    height: 165px;
                }
            }

            .image-loading {
                left: 0px;
                top:0px;
                
                position:absolute;
                width: 100%;
                height: 100%;
                background-color: rgb(230, 230, 230);
                text-align: center;

                .spinner {
                    position:relative;
                    top: 50%;
                    transform: translateY(-50%);

                    .spinner-border {
                        color: rgb(208, 208, 208);
                    }

                }
            }

            video {
                object-fit: cover;
                width: 200px;
                height: 200px;

                @media screen and (max-width: 576px) {
                    width: 175px;
                    height: 175px;
                }

                @media screen and (max-width: 375px) {
                    width: 165px;
                    height: 165px;
                }
            }

            .select {
                position: absolute;
                color: white;
                right: 5px;
                bottom: 5px;
            }

            .select-active {
                position: absolute;
                color: white;
                background-color: $gold;
                border-radius: 25px;
                right: 5px;
                bottom: 5px;
            }
        }

        .bordered-button {
            margin-top: 80px;
            margin-bottom: 40px;
        }

    }
}

.story-lifelessons-container {

    .tag {
        border: 1px solid rgba($base-green, 0.3);
        padding: 4px;
        display: inline-flex;
        align-items: center;
        width: fit-content;
        border-radius: 16px;
        margin-bottom: 8px;
        margin-right: 5px;
        height: 32px;

        &:hover {
            border-color: $base-green-hover;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        p {
            display: inline-block;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            font-size: 12px;
            letter-spacing: 0.13px;
            color: $base-green;
            padding: 0 2px;
            flex-grow: 1;
            margin-bottom: 0;
        }
        /*
        &:first-child {
            display: block
        }
        */
    }

    .img-author {
        /*position: absolute;
        bottom: 20px;
        left: 20px;*/

        margin-top: 8px;
        
        img {
            height: 25px;
            width: 25px;
        }
        
        .story-author {
            display: inline-block;
            margin-left: 8px;
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
    
    .unpublished-stories {

        .up-story {
            @include desktop-box-max-width();
            .last-modified {
                bottom: 32px
            }
        }
    }

    .info-row {
        
        > div {
            text-align: center;
            width: 100%
        }
    }

}