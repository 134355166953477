.footer {
    background-color: $lightgray;
    text-align: center;
    overflow: hidden;
    padding-bottom: 208px;

    max-width: 100%;
    position: relative;
    bottom: 0px;

    @media screen and (min-width: 992px) {
        padding-bottom: 50px;
    }
/*
    .needhelp {
        height: 92px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba($darkgray, 0.1);
        width: 100%;
        margin: 0;

        .dropdown {
            position: relative;
        }

        .button.dropdown-toggle {
            .down-icon {
                position: absolute;
                right: 22px;
                top: 30%;
                color: rgba($darkgray, 0.3);
            }

            &::after {
                display: none;
            }

            &:hover {
                color: $darkgray;

                .down-icon {
                    color: $darkgray;
                }
            }

            @media screen and (min-width: 480px) {
                max-width: 200px;
            }
        }
    }
*/
    .footer-links {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1.25px;
        padding: 24px 0;

        @media screen and (min-width: 992px) {
            padding: 45px 0 24px 0;
        }

        ul {
            list-style: none;

            @media screen and (min-width: 992px) {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        li {
            padding: 12px 0;

            @media screen and (min-width: 992px) {
                padding: 0 20px;
            }

            .link {
                color: rgba($base, 0.3);
                text-decoration: none;
                cursor: pointer;
                &:hover {
                    color: $gold;
                    transition: $transition;
                }

                @media screen and (min-width: 992px) {
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 1.46px;
                    line-height: 18px;
                }
            }
        }

        li.footer-social-icons {
            display: flex;
            padding-left: 40px!important;
            align-items: center;
            justify-content: center;
            align-content: center;
            padding-bottom: 5px;
            @media screen and (max-width: 992px) {
                padding-left: 0!important;
            }
            a, svg{
                color: $darkgray;
                font-size: 18px;
                width: 20px!important;
                &:hover {
                    color: $gold;
                    transition: $transition;
                }
            }
            .icon {
                margin-right: 30px;
            }
        }
    }

    .rights {
        color: rgba($base, 0.3);

        p {
            margin: 0;
            font-size: 12px;
            line-height: 18px;

            @media screen and (min-width: 992px) {
                font-size: 14px;
                letter-spacing: 0;
            }
        }

        br {
            @media screen and (min-width: 992px) {
                display: none;
            }
        }
    }

    .logo-container {
        padding-top: 26px;
        color: rgba($darkgray, 0.3);
    }

    .footer {
        .container-fluid {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}
