.experts-history-widget, .experts-packages-widget, .experts-info-widget {
    @include content-min-height();
    .title {
        color: $base;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 24px;
        text-align: center;
    }
}

.experts-history-widget {
    .title {
        margin: 40px;
    }
    .content-section {
        text-align: center;
        margin: 0 5% 40px 5%;
        display: flex;
        align-items: center;
        justify-content: center;
        .no-records-box {
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
            padding: 36px 0;
            color: rgba(23,36,61,0.5);
            font-size: 14px;
            letter-spacing: 0;
            line-height: 21px;
            text-align: center;
            width: 55%;
            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }
}

.experts-packages-widget {
    .title {
        margin: 40px;
    }
    .content-section {
        text-align: center;
        margin: 0 5% 40px 5%;
        display: flex;
        align-items: center;
        justify-content: center;
        .no-records-box {
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
            padding: 36px 5%;
            width: 55%;
            text-align: center;
            @media (max-width: 768px) {
                width: 100%;
            }
            .grey-text {
                color: rgba(23,36,61,0.5);
                font-size: 14px;
                letter-spacing: 0;
                line-height: 21px;
            }
            .request-text {
                color: $base;
                font-size: 14px;
                letter-spacing: 0.25px;
                line-height: 21px;
                padding: 20px 3% 40px 3%;  
            }
        }
    }
}

.experts-info-widget {
    .title {
        margin: 40px 20px 20px 20px;
    }
    text-align: center;
    margin: 0 5% 40px 5%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content-section {
        width: 55%;
        text-align: center;
        @media (max-width: 768px) {
            width: 100%;
        }

        @media (min-width: 769px) and (max-width: 1280px) {
            width: 80%;
        }
        .content {
            color: $base;
            font-size: 14px;
            letter-spacing: 0.25px;
            line-height: 21px;
            text-align: center;
            margin: 0 20px;
        }
        .content-box {
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
            padding: 36px 5%;
            .box-title {
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 32px;
                color: $base;
                margin-bottom: 36px;
            }
            .nr-container {
                display: flex;
                justify-content: center;
                .box-nr {
                    width: 40px;
                    height: 40px;  
                    background-color: $gold;
                    color: $lightgray;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                    letter-spacing: 0;
                    line-height: 26px;
                    margin-top: 3px;
                  }
            }
            .box-subtitle {
                color: $base;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.5px;
                line-height: 24px;
                text-transform: uppercase;
                margin-bottom: 8px;
                margin-top: 12px;
            }
            .box-text {
                color: rgba(23,36,61,0.7);
                font-size: 14px;
                letter-spacing: 0.44px;
                line-height: 21px;
                margin: 0 10px;
            }
            .box-line {
                height: 24px;
                width: 1px;
                border: 1px solid rgba(23,36,61,0.12);
                margin: 11.5px 0;
            }

            .learn-more-button-section {
                margin: 58px 0 58px 0;
            }
        }
        .image-container {
            margin-bottom: 24px;
        }
    }
}