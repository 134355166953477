.media-comment-modal {
    .modal-header {
        p {
            color: #17243D;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 36px;
        }
    }

    .modal-body {
        padding: 0;
    
        .comment-section {
            padding: 20px;
            margin-top: 20px;
        }
    }
}