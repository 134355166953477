.inactive-container{
    max-width: 400px !important;
    padding: 80px;
    text-align: center;

    .row{
        max-width: 100%;
        margin: auto;
    }

    .subtitle{
        max-width: 80%;
        margin: auto;
        margin-bottom: 30px;
    }

    img{
        width: 80px;
        height: 80px;
    }

    .name{
        margin-top: 15px;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 5px;
    }

    .date{
        color: $gray;
        margin-bottom: 36px;
    }

    .middle-text{
        max-width: 80%;
        margin: auto;
        margin-bottom: 36px;
    }

    .tip{
        font-weight: 700;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .bottom-text{
        max-width: 90%;
        margin: auto;
        margin-bottom: 36px;
    }

    button{
        margin-bottom: 35px;
    }

    .cancel{
        color: $gray !important;
    }

    .something-missing {
        background-color: #fff2db;
        padding: 20px;
        text-align: center;
        position: relative;
        max-width: 700px;
        margin: 0 auto 36px auto;

        p {
            margin-bottom: 0
        }
      }    
}