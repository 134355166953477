.rich-text-editor-container {
    .text-editor-wrapper-class.rdw-editor-wrapper {
        .text-editor-editor-class {
            .DraftEditor-root {
                max-width: 700px;
                margin:auto;
                height: 15vh;
            }
        }
    }
}

.rich-text-editor-container {

    .edit-in-fullscreen-icon {
        position: absolute;
        right: 20px;
        top: 20px;
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: $base;
        cursor: pointer;
        z-index: 999;
        @media screen and (max-width: 850px) {
            display: none;
        }
    }

    .text-editor-wrapper-class { 
        display: flex; 
        flex-direction: column-reverse 
    }

    .rdw-editor-toolbar {
        padding: 6px 20px 0;
        @media screen and (max-width: 410px) {
            padding: 6px 10px 0;
        }

        .rdw-link-modal {
            @media screen and (max-width: 410px) {
                left: -200px;
            }            
            @media screen and (min-width: 411px) and (max-width: 850px) {
                left: -190px;
                top: 50px;
            }            
        }
    }

    .default-button {
        margin-top: 8px;
        margin-bottom: 0;
        @media (max-width: 550px) {
            margin-left: 5%;
            margin-right: 5%;
            width: 90%;
        }
    }

    .text-editor-toolbar-class {
        width: 100%;
        border: none;
        background-color: $lightgray;
        position: absolute;
        top: 0;
        margin-bottom: 60px;
        z-index: 9;
        box-shadow: 0 2px 4px 0 rgba(23,36,61,0.12);
        height: 60px;
        @media screen and (max-width: 410px) {
            height: 40px;
        }

        .rdw-option-wrapper {
            width: 40px;
            height: 40px;
            @media screen and (max-width: 410px) {
                width: 30px;
                height: 30px;
            }
            margin-left: 5px;
            margin-right: 5px;
            -webkit-box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);
            -moz-box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);
            box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);
        }

        .rdw-option-active {
            background-color: $gold;
            color: white;
            box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);
        }
        
        .rdw-dropdown-wrapper {
            width: 220px;
            height: 40px;
            @media screen and (max-width: 410px) {
                height: 30px;
            }
            font-size: 12px;
            border: 0;

            @media screen and (max-width: 550px) {
                width: 110px;
            }

            @media screen and (max-width: 410px) {
                width: 100px;
            }

            @media screen and (max-width: 330px) {
                width: 90px;
                font-size: 12px;
            }
            
        }

        .rdw-dropdown-optionwrapper {
            width: 200px;
            overflow: hidden;
            display: flex;
            flex-direction: column-reverse;
            bottom: 145px;

            li {
                width: 200px;
                height: 48px;
            }
        }

        a.rdw-dropdown-selectedtext {
            padding-left: 10px;
            padding-right: 10px;
            text-decoration: none !important;
            box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);
            &:hover {
                box-shadow: none;
                transition: $transition;
                background-color: $gold;
            }
            
            span {
                color: $base !important;
            }

            .rdw-dropdown-carettoopen {
                top: 46%;
                right: 5%;
                color: $gray;
            }
        }
    }

    .text-editor-editor-class {
        padding: 15px;
        min-height: 200px;
        @include text-modal-entry-height();
        @media (min-width: 800px) {
            font-size: 20px;
            line-height: 30px;
            padding: 120px 114px 60px 114px;
            color: $base;
            box-shadow: 0 1px 0 0 rgba(23,36,61,0.1);
        }

        @media (max-width: 799px) {
            font-size: 18px;
            line-height: 25px;
            padding: 88px 50px 60px 50px;
            color: $base;
        }

        @media (max-width: 550px) {
            font-size: 16px;
            line-height: 25px;
            padding: 88px 20px 40px 20px;
            color: $base;
            box-shadow: 0;
        }

        //Cant select text inside the editor
        /*
        h5 {
            text-transform: capitalize;
        }
        */
    }
}

.modal-dialog.block-picker-modal.text-editor-with-fullscreen {
    .rich-text-editor-container {
        .text-editor-editor-class {
            @media (min-width: 1440px) {
                font-size: 24px;
                line-height: 36px;
                padding: 120px 300px 60px 300px;
                letter-spacing: 0.18px;
            }

            @media (min-width: 851px) and (max-width: 1439px)  {
                font-size: 22px;
                line-height: 36px;
                padding: 120px 200px 60px 200px;
                letter-spacing: 0.18px;
            }
        }
    }
}