.subscription-referral-widget {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

    text-align: center;
    margin-bottom: 30px;

    .top-container {
        background-color: #ebffd6;
        border-bottom-left-radius: 300px 50px;
        border-bottom-right-radius: 300px 50px;
        -webkit-border-bottom-left-radius: 300px 50px;
        -webkit-border-bottom-right-radius: 300px 50px;
        -moz-border-bottom-left-radius: 300px 50px;
        -moz-border-bottom-right-radius: 300px 50px;
        margin-top: -20px;
        padding: 20px;

        p:first-child {
            color: #17243D;
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
        }

        p:last-child {
            color: #17243D;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.25px;
            line-height: 21px;
            padding-bottom: 40px;
        }

        .emoji-conatiner {
            padding-top: 50px;
        }

    }

    .info-text-container {

        padding: 20px;

        p:first-child {
            color: #17243D;
            font-size: 16px;
            letter-spacing: 0.25px;
            line-height: 24px;
            font-weight: 400;
        }

        .gray-text {
            color: rgba(23,36,61,0.5);
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
            margin: 0 20px;
        }
    }
}