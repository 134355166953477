.family-filters {
    position: absolute;
    top: 0;
    right: 0;
    height: 62px;
    margin-left: 20px;
    margin-right: 10px;

    @media screen and (max-width: 500px) {
        top: 70px;
        margin-right: 26px;
        width: 88%;
    }

    .filters {
        display: flex;
        justify-content: space-between;
        height: 100%;

        @media screen and (min-width: 480px) {
            padding: 0 18px;
        }

        .filter {
            color: $gray;
            margin-right: 30px;
            display: flex;
            align-items: center;

            .icon-container {
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            &.active,
            &:hover {
                color: $gold;
            }

            svg {
                margin-right: 8px;
                cursor: pointer;
            }

            p {
                margin: 0;
            }
        }

        .search {
            display: flex;
            align-items: center;

            svg {
                color: $gray;
                margin: 0;
                font-size: 18px;
                cursor: pointer;
            }

            &.active svg {
                color: $gold;
            }
        }

        .group {
            display: flex;
            align-items: center;
            position: absolute;
            right: -18px;
            top: 0;
            width: 100vw;
            height: calc(100% + 1px);
            padding: 0 18px;
            background-color: $secondary;

            @media screen and (min-width: 992px) {
                width: calc(100vw - 278px);
            }

            svg {
                color: $gray;
                margin: 0;
                font-size: 18px;
                cursor: pointer;
            }

            .filter-icon {
                color: $gold;
                margin: 0;
                cursor: unset;
            }

            .search-icon {
                color: $gold;
                cursor: unset;
            }

            .close-icon {
                svg {
                    vertical-align: middle;
                }
            }

            select,
            input {
                border: none !important;

                &::placeholder {
                    color: $gray;
                }
            }
        }
    }
}
