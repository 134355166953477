.deceased-nonadmin {
    padding-top: 24px;
    padding-bottom: 52px;
    text-align: center;

    @media screen and (min-width: 992px) {
        height: calc(100vh - 291px );
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 300px;
        max-width: 500px;
        text-align: center;
        margin: 0 auto;        
    }

    .top-content {
        margin-bottom: 30px;

        p {
            line-height: 21px;
            letter-spacing: 0.25px;
        }
        .content-title {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 1px;
            font-weight: 600;
            margin-bottom: 24px;
        }
    }

    .profiles {
        .profile-item {
            margin-bottom: 12px;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }
    }
}
