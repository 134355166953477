.confirm-modal {
    backdrop-filter: blur(1px);

    .modal-body {
        font-size: 14px;
        line-height: 21px;
        text-align: center;
    }

    .field-name {
        font-weight: bold;
        text-transform: uppercase;
    }

    .actions {
        display: flex;
        justify-content: center;
        .refuse {
            color: #ef5a5a;
            cursor: pointer;
            margin: 5px;
        }

        .accept {
            color: #66b23d;
            cursor: pointer;
            margin: 5px;
        }
    }

    @media (min-width: 768px) {
        text-align: left;
        display: flex !important;
        align-items: center;
    }

    .modal-dialog {
        position: absolute;
        bottom: 0;
        margin: 0;
        width: 100%;
        max-width: none;

        @media (min-width: 768px) {
            max-width: 500px;
            margin: auto;
            position: relative;
        }
    }
}
