.registration-container {
    max-width: 500px !important;
    padding: 50px;

    .title {
        text-align: center;
        margin-bottom: 30px;
    }

    img{
        display: block;
        margin: 0 auto;
    }

    > img {
        margin: 0 auto 60px auto;
    }

    .registration-form {
        .warning-message {
            font-size: 12px;
            color: red;
        }

        .warning {
            border: 1px solid red;
        }

        .registration-error {
            color: red;
            text-align: center;
        }

        .group {
            width: 100%;
            margin-bottom: 48px;

            &.email,
            &.password {
                margin-bottom: 36px;
            }

            input {
                width: 100%;
                padding: 13px;
            }

            .bottom-label {
                font-size: 12px;
                line-height: 16px;
                color: $gray;
                margin-left: 16px;
                margin-bottom: 0;
                margin-top: 5px;
            }
        }

        .checkbox {
            margin-top: 35px;

            &-input {
                display: flex;
                padding-left: 0;

                .form-check-input {
                    flex-basis: 18px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    margin-left: 0;
                    margin-right: 8px !important;
                }
            }
        }

        .button-group {
            .registration-button {
                width: 100%;
                margin-top: 48px;                
                margin-bottom: 24px;
                padding: 15px;
                border-radius: 0;
                text-transform: uppercase;
            }
        }
    }

    .small-gray-text {
        text-align: center;
        font-size: 12px;
        color: gray;
    }

    .tos {
        text-align: center;
        font-weight: 300;
    }

    .notification-container {
        p {
            margin-bottom: 5px;
        }

        .forgot-password-link {
            text-decoration: underline;
            cursor: pointer;
            &:hover {
                opacity: 0.7;
            }
        }
    }
}
