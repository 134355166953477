.change-relationship {
    padding-bottom: 100px;

    .relationship-picker-container{
        margin-top: 24px;

        input{
            width: 100%;
            padding: 0 16px;
        }

        .result-container .tab-content{
            max-height: 200px;
        }

        .result-container {
            width: 100%;
        }
    }
}