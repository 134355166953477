.story-launchpad {
    padding-top: 40px;
    max-width: 500px;
    margin: auto;
    padding-bottom: 80px;
    @include desktop-box-max-width();

    input:focus ~ .top-label {
        color: $gold !important;
    }

    input {

        &:focus {
            border: 2px solid $gold !important;
        }
    }
    
    .sub-subtitle {
        font-size: 12px;
        color: RGBA(23, 36, 61, 0.5);
    }

    .right {
        float: right;
    }

    .gold-title {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: $base;
    }

    .title-input {
        padding-bottom: 20px;
    }

    .group {
        margin-top: 40px;
    }

    .block {
        width: 100px;
        height: 90px;
        display: inline-block;
        margin: 20px;
        text-align: center;
        padding: 20px;
        border: 1px solid $lightgray;
        @media screen and (max-width: 760px) {
            margin: 10px;
        }   

        svg {
            color: $gray;
        }

        p {
            font-size: 12px;
            font-weight: 600;
            margin-top: 10px;
            color: $gray;

            @media screen and (max-width: 625px) {
                margin-left: -22px;
                margin-right: -22px;
            }
        }

        .selected-icon {
            position: relative;
            left: 63px;
            bottom: 103px;
            background-color: white;
            @media screen and (max-width: 760px) {
                left: 44px;
            }  
        }
    }

    .block:hover {
        cursor: pointer;
        box-shadow: rgba(50, 50, 93, 0.3) 5px 7px 5px -1px;        
    }

    .disabled-icon {
        position: relative;
        left: 63px;
        bottom: 105px;
        background-color: $lightgray;
        border-radius: 20px;
        margin: 5px;
        padding: 3px;
        border: 2px solid white;
    }

    .disabled {
        background-color:$lightgray;

        p {
            color: $gray;
        }

        svg {
            color: $gray;
        }
    }

    .disabled:hover {
        cursor: default;
        box-shadow: none;      

    }

    .active {
        border: 1px solid $gold;
        p {
            color: $gold;
        }
        svg {
            color: $gold;
        }
    }

    .button-container {
        margin-top: 80px; /* 20px; */

        @media screen and (min-width: 1000px) {
            //removed until a new decision from Andrew
            //margin: 30px 20%;
        }

        .autosave-container {
            display: flex;
            justify-content: center;
            margin-top: 35px;

            .autosave-text {
                padding-left: 30px;
                color: $gray !important;
                font-size: 13px;
                font-weight: 700;
                letter-spacing: 1.25px;
                text-transform: uppercase;
                text-decoration: none;
                text-align: center;
                margin-bottom: 0 !important;
                align-self: center;
                margin-top: 2px;
            }

            button {
                background: transparent !important;
                border: none !important;
                box-shadow: none !important;
            }

            .image-icon {
                margin-left: 10px;
                color: $gray;
                cursor: pointer;
            }
        }
    }

    .default-button {
        max-width: unset;
    }

    iframe {
        @media only screen and (max-width: 500px) {
            max-width: 335px;
        }
    
        @media only screen and (min-width: 501px) and (max-width: 700px) {
            max-width: 335px;
        }
    
        @media only screen and (min-width: 701px) {
            max-width: 500px;
        }
    
    }  
}