.unique-single-select-container {

    margin-bottom: 10px;
    
    .dropdown-icon {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translate(0, -50%);
        color: $gray;
    }

    .dropdown-icon:hover {
        cursor: pointer;
        color: $darkgray;
    }
    
    .example-list {
        width: 100%;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        position: absolute;
        background-color: $secondary;
        z-index: 12;
        border: 1px solid $gray;

        max-height: 250px;
        overflow: auto;

        &:empty {
            border: none;
            box-shadow: none;
        }

        p {
            padding: 10px 15px;
            margin: 0;
            font-size: 16px;
        }

        p:hover {
            cursor: pointer;
            background-color: rgb(199, 199, 199);
        }
    }

    .example-list.view-only {

        p {
            color: $gray
        }

    }
}