.carousel-container {
    position: relative;

    text-align: center;
    margin: auto auto 40px auto;
    max-width: 500px;
    padding-top: 30px;

    .user-picture {
        width: 60px;
        height: 60px;
    }

    .right-button {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 20px;
        position: absolute;
        right: 10px;
    }

    .left-button {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 20px;
        position: absolute;
        left: 10px;              
    }

    .left-button:hover {
        cursor: pointer;
    }

    .right-button:hover {
        cursor: pointer;
    }

    .featured {
        .name {
            margin-top: 10px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
        }
    }

    .featured:hover {
        cursor: pointer;
        color: $base-green-hover;
    }
}