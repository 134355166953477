.story-filters-bar {

    background-color: white;
    border-bottom: 1px solid rgba(23, 36, 61, 0.1);
    height: 60px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    position: relative;

    .filters {

        .left-filters {
            position: relative;
            
            .sort-options {
                display: flex;
                float:left;
                align-items: center;

                p {
                    margin-bottom: 0;
                    margin-right: 10px;
                    margin-left: 5px;
                    font-size: 12px;
                }

                .filter-icon {
                    color: $gold;
                }
            }

            .sort-options:hover {
                cursor: pointer;
            }

            .options {
                position: absolute;
                background-color: white;
                padding-top: 10px;
                padding-bottom: 10px;
                width: 200px;

                font-size: 12px;

                z-index: 1000000;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

                .selected {
                    background-color: $lightgray;
                    font-weight: bold;
                }

                .option {
                    padding-left: 10px;
                    display: inline-flex;
                    width: 100%;

                    p {
                        padding: 5px;
                        margin-bottom: 0px;
                    }

                    svg {
                        margin-top: 5px;
                        margin-left: 40px;
                    }
                }

                .option:hover {
                    background-color: $lightgray;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }

        .right-filters {
            display: flex;
            // justify-content: space-between;
            height: 100%;
            float:right;
    
            @media screen and (min-width: 480px) {
                padding: 0 18px;
            }


            .icon-container {
                position: relative;

                .active-additional-icon {
                    position: absolute;
                    color: $gold;
                    top: -10px;
                    left: 10px;

                    svg {
                        background-color: white;
                        border: 0.5px solid white;
                        border-radius: 10px;
                    }
                }
            }
    
            .filter {
                color: $gray;
                margin-right: 30px;
                display: flex;
                align-items: center;
                float: right;
    
                .icon-container {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }
    
                &.active,
                &:hover {
                    .status-icon {
                        color: $gold;
                    }
                }
    
                svg {
                    margin-right: 8px;
                    cursor: pointer;
                }
    
                p {
                    margin: 0;
                }
            }
    
            .search {
                display: flex;
                align-items: center;
    
                svg {
                    color: $gray;
                    margin: 0;
                    font-size: 18px;
                    cursor: pointer;
                }
    
                &.active svg {
                    color: $gold;
                }
            }
    
            .show-search {
                display: flex;
                align-items: center;
                position: absolute;
                right: 0px;
                top: 0;
                width: 100%;
                height: 60px;
                padding: 0 18px;
                background-color: $secondary;
    
                svg {
                    color: $gray;
                    margin: 0;
                    font-size: 18px;
                    cursor: pointer;
                }
    
                .filter-icon {
                    color: $gold;
                    margin: 0;
                    cursor: unset;
                }
    
                .search-icon {
                    color: $gold;
                    cursor: unset;
                }
    
                .close-icon {
                    svg {
                        vertical-align: middle;
                    }
                }
    
                select,
                input {
                    border: none !important;
    
                    &::placeholder {
                        color: $gray;
                    }
                }
            }
        }
    }

    .filters-enabled {
        .set-filter {
            p {
                font-size: 16px !important;
                letter-spacing: 0.15px !important;
                line-height: 24px !important;
            }
            svg {
                color: $gold;
            }
        }

        .close {

            .remove {
                font-size: 12px;
                color: red;
                text-decoration: underline;
                margin-right: 20px;
            }

            .remove:hover {
                cursor: pointer;
            }

            svg {
                color: $gray;
            }

            svg:hover {
                color: $lightgray;
                cursor: pointer;
            }
        }

    }
}