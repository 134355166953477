.family-update-page {

    padding-top: 40px;

    .family-updates-container {

        text-align: center;

        /* @include content-min-height(); */

        .title {
            text-transform: uppercase;
            color: rgba(23,36,61,0.5);
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.5px;
            line-height: 24px;
            text-align: center;

            margin-top: 36px;
            margin-bottom: 24px;
        }

        .family-update {
            border-bottom: 1px solid rgba(23, 36, 61, 0.1);
            max-width: 500px;
            margin: auto;
            padding: 10px;
            text-align: left;

            padding-top: 20px;
            padding-bottom: 20px;
            @include desktop-box-max-width();

            > div:nth-child(2) {
                margin-left: 100px;
            }

            .users {
                text-transform: uppercase;
                display: inline-flex;
                color: #17243D;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 1.17px;
                line-height: 18px;

                margin-bottom: 8px;
            }

            .text {
                color: $base;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 18px;
                font-weight: bold;
                margin-bottom: 3px;
            }

            .description {
                color: rgba(23,36,61,0.7);
                font-size: 12px;
                letter-spacing: 0;
                line-height: 18px;
                margin-bottom: 3px;
                display: inline-flex;
            }

            .date {
                float: right;
                color: rgba(23,36,61,0.7);
                font-size: 12px;
                letter-spacing: 0;
                line-height: 18px;
                margin-bottom: 3px;
                display: inline-flex;
            }

            .multiple-images {
                position: relative;

                img {
                    display: block;
                    float: left;
                    width: 45px;
                    height: 45px;
                    border-radius: 25px;
                    margin-bottom: 20px;
                }

                .one {
                    position: relative;
                    left: 0px;
                    top: 20px;
                    z-index: 20;
                }

                .two {
                    position: relative;
                    right: 20px;
                }
            }

            .multiple-images-with-admin {
                position: relative;

                img {
                    display: block;
                    float: left;
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    margin-bottom: 20px;
                }

                .big {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                }

                .small {
                    position: absolute;
                    top: 35px;
                    left: 45px;
                    width: 45px;
                    height: 45px;                    
                }
            }            
            
            .user-avatar {
                display: block;
                float: left;
                width: 70px;
                height: 70px;
                border-radius: 50%;
                margin-right: 20px;
                object-fit: cover;
            }
        }

        .family-update.admin-notification{
            background-color: #f0fcff;
            border-left: 3px solid #80cadc;
        }

        .read {
            opacity: 0.6;
        }
        .link-text {
            color: #006238;
            font-size: 14px;
            letter-spacing: 0.25px;
            line-height: 21px;
            text-decoration: underline;
            margin-top: 20px;
        }

        .link-text:hover {
            cursor: pointer;
        }
    }

    .family-updates-container.new {

        .dot {
            height: 12px;
            width: 12px;
            background-color: $red;
            border-radius: 50%;
            display: inline-flex;
            margin-top: 10px;
            float: right;
        }

        @media screen and (max-width: 525px) {
            .dot, .date {
                margin-right: 15px;
            }
        }        
    }

    .family-updates-container.more {
        padding-bottom: 40px;
    }
}

.modal .family-updates .btn-container button {
    margin: 35px auto 15px auto;
}