.block-picker-modal {

    .modal-header  {
        padding: 10px 0px 10px 0px;
    }

    .block-type {
        font-size: 12px;
        font-weight: 400;
        margin: 0;
        text-transform: lowercase;
    }

    .modal-body {
        padding: 0px;
        padding-bottom: 20px;
    }

    .block-type::first-letter {
        text-transform: capitalize;
    }

    .block-picker {

        text-align: center;

        margin-left: 20px;
        margin-right: 20px;

        .select {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
        }
        
        .select-box {
            width: 103px;
            height: 90px;
            display: inline-block;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            margin: 8px;
            text-align: center;
            padding: 20px;
            vertical-align:top;
            transition: all ease 0.3s;

            .block-name {
                font-size: 12px;
                color: RGB(23, 36, 61);
                margin-top: 10px;
            }
        }

        .select-box:hover {
            box-shadow: rgba(50, 50, 93, 0.3) 5px 7px 5px -1px;
        }

        .disabled {
            background-color:#dfdfdf;
    
            .block-name {
                color: $gray;
            }
    
            svg {
                color: $gray;
            }
        }
    
        .disabled:hover {
            cursor: default !important;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        }

        .disabled-icon {
            position: relative;
            left: 50px;
            bottom: 106px;
            background-color: #dfdfdf;
            border-radius: 20px;
            margin: 5px;
            padding: 3px;

            border: 2px solid white;
        }

        .subscription-info {
            color: rgba(23,36,61,0.5);
            font-size: 12px;
            letter-spacing: 0.5px;
            line-height: 18px;
            text-align: center;

            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    .default-button {
        margin-top: 15px;
    }
}