.administrator-modal {
    text-align: center;

    .modal-body {
        padding-bottom: 100px;
        padding-top: 24px;
    }

    .content-title {
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 24px;
        text-transform: uppercase;
        margin-bottom: 36px;
    }

    img {
        height: 152px;
        width: 152px;
        margin-bottom: 20px;
    }

    .name {
        font-weight: 600;
        letter-spacing: 1.17px;
        line-height: 16px;
        text-transform: uppercase;
        margin-bottom: 36px;
    }

    .info-text {
        letter-spacing: 0.25px;
        line-height: 21px;
        margin-bottom: 36px;

        @media screen and (min-width: 992px) {
            padding: 0 20px;
        }
    }
}
