.help-page-container {
  margin: 50px auto 50px auto;
  max-width: 700px;

  #item-0, #item-1 {
    display: none;
  }
  .category-title {
    background-color: $lightgray;
    padding: 10px;
    padding-left: 23px;
    padding-top: 1.5rem;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 23px;
    height: 71px;
    border-bottom: 1px solid $gray-color-line;
  }

  .accordion {
    .accordion-item {
      border: none;
      position: relative;
      margin-right: 14px;

      @media screen and (max-width: 436px) {
        margin-right: 18px;
      }

      &::after {
        content: "";
        width: calc(100% - 56px);
        position: absolute;
        margin-left: 4px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        background-color: $lightgray !important;
      }

      .accordion-collapse.collapse.show {
        border: none;

        &::after {
          display: none;
        }

        .accordion-header {
          .accordion-button {
            padding-bottom: 20px;
          }
          .accordion-button.collapsed {
            padding-bottom: 20px;
          }
        }

        .accordion-body {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 32px;

          @media screen and (max-width: 436px) {
            padding-left: 22px;
          }
        }
      }

      .accordion-header {
        background-color: transparent;
        border: none !important;
        button.accordion-button:not(.collapsed) {
          background-color: white;
          box-shadow: none;
          color: $base-green;
        }

        button.accordion-button {
          border: none;
          padding-left: 32px;
          padding-top: 24px;
          padding-bottom: 24px;

          &[aria-expanded="true"] {
            padding-bottom: 0;
            z-index: 2;
            p {
              color: $base-green;
            }
          }

          .title {
            font-size: 16px !important;
            margin-bottom: 0;
            text-align: left;
          }
        }

        button.accordion-button:not(.collapsed) {
          .category-name {
            padding-bottom: 10px;
          }
        }
      }
    }
  }

  .hidden {
    display: none;
  }

  .help-page-search-container {

    @media screen and (min-width: 576px) {
      margin-top: 10px;
    }
    span.input-group-text {
      cursor: pointer;
      padding-left: 14px;
      padding-right: 0;
      color: $base;
      background-color: white;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $gray-color-border;
      border-left: 1px solid $gray-color-border;
      @media screen and (max-width: 700px) {
        border-left: none;
      }
    }

    input[type="search"] {
      border: none !important;
      border-bottom: 1px solid $gray-color-border !important;
      border-right: 1px solid $gray-color-border !important;
      @media screen and (max-width: 700px) {
        border-right: none;
      }
    }
  }
}

.accordion-item.accordion-shadow {
  position: relative;
}

.outer-accordion {
  .accordion-item {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 83px;
      left: 43.5px;
      width: 1px;
      height: calc(100% - 135px);
      background-color: $gray-color-line;
      z-index: 98;

      @media screen and (max-width: 436px) {
         top: 116px;
         left: 34px;
         height: calc(100% - 183px);
      }
    }
  }

  .accordion-collapse {
    .accordion-item {
      &::before {
        content: "";
        background-color: transparent;
      }
     }
  }
}
