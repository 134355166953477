.details-container {
    .details-title {
        text-align: center;
        margin-bottom: 8px;
    }

    .link-to-data-info {
        margin: auto;
        display: block;
        font-size: 12px !important;
        margin-bottom: 20px;
    }

    .user-picture {
        margin-top: 20px;
        margin-bottom: 20px;
        height: 80px;
        width: 80px;
        margin: auto;
        display: block;
        object-fit: cover;
        border-radius: 50%;
    }

    .upload-container {
        text-align: center;
        margin-bottom: 48px;

        img {
            margin-bottom: 16px;
        }

        .upload-button {
            color: $base-green;
            border: none;
            background-color: white;
            margin: auto;
            display: block;

            &:hover {
                color: $base-green;
                border: none;
                background-color: white;
            }
        }
    }

    .group {
        width: 100%;
        margin-bottom: 48px;

        input {
            width: 100%;
            padding: 13px;
            display: block;
        }

        select {
            width: 100%;
            padding: 13px;
            display: block;
        }

        .bottom-label {
            font-size: 12px;
            color: $gray;
            margin-left: 16px;
            margin-top: 5px;
        }

        .calendar-icon {
            position: absolute;
            top: 16.5px;
            right: 19px;
            float: right;
            font-size: 23px;
            color: gray;
        }
    }

    button {
        svg {
            margin-left: 12px;
            vertical-align: middle;
        }
    }

    .failed svg {
        color: #ffdf59;
        font-size: 26px;
    }

    .react-datepicker-popper{
        select{
            padding: 5px;
            height: auto !important;
            margin-top: 10px;
        }
    }

    .newsletter-row {
        cursor: pointer;
        .newsletter-title {
            font-size: 16px;
        }

        .newsletter-description {
            font-size: 12px;
            font-weight: 400;
            color: $gray;
            margin: 16px 0px 30px 0px;
            width: 80%;
        }

        .right-icon {
            color: $gray;
        }
    }
}

.hubspot-contact-modal {
    &.centered {
        padding-left: 0px;
    }
}
