.right-sidebar {
    background-color: red;
    .sidebar-navigation-container {
        .logout {
            color: blue;
        }

        button {
            display: block;
        }
    }

}

.off-canvas.offcanvas {
    max-width: 85% !important;
    width: 315px;
    z-index: 1051;

    .offcanvas-body {
        padding-top: 4px;
    }

    .profile-details {
        display: flex;

        img {
            width: 50px;
            height: 50px;
            margin-right: 15px;
        }

        .name {
            font-weight: 600;
            margin-bottom: 0;
        }

        .email {
            font-size: 12px;
            color: rgba($base, 0.7);
            line-height: 18px;
            margin-bottom: 12px;
        }

        .account-type {
            font-size: 11px !important;
            font-weight: 600 !important;
            margin-bottom: 40px;
        }
    }

    .profile-details:hover {
        cursor: pointer;
    }

    .sidebar-navigation-container {
        padding: 0 8px;
        height: calc(100vh - 280px);

        ul {
            list-style: none;
            padding: 0;

            li {
                padding: 10px 0;

                a,
                button {
                    font-weight: 600;
                    text-decoration: none;
                    color: $base;
                    text-transform: uppercase;
                    padding: 0;
                    display: block;
                    border: none;
                    background-color: transparent;
                    letter-spacing: 1.25px;
                    transition: $transition;

                    &:hover {
                        color: $gold;
                    }
                }
            }
        }
    }

    .copyright {
        color: rgba($base, 0.3);
        font-size: 12px;
        line-height: 20px;
    }

    .notification {
        a {
            display: inline-flex !important;
        }

        .dot {
            height: 8px;
            width: 8px;
            background-color: #EF5A5A;
            border-radius: 50%;
            display: inline-flex;
            margin-top: 10px;
            margin-left: 20px;
        }
    }
}
