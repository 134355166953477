.login-container {
    max-width: 400px !important;
    padding: 50px;
    text-align: center;

    .title {
        margin-bottom: 30px;
    }

    .register-link {
        margin: 0 0 10px 0;
    }

    .login-form {
        margin-top: 36px;

        .string-input {
            width: 100%;
            margin-bottom: 48px;
            padding: 15px;
            font-weight: 300;
        }

        .saved-user-container {
            margin-bottom: 48px;
            margin-top: 30px;
            p {
                margin: 0;
            }

            .name {
                font-weight: 700;
                font-size: 16px;
            }

            .email {
                margin-bottom: 16px;
                font-weight: 300;
                color: $gray;
            }

            .user-picture {
                height: 80px;
                width: 80px;
                margin-bottom: 10px;
            }
        }

        .checkbox-input-container {
            text-align: left;

            +p {
                margin-top: 20px;
            }

            .checkbox-input-click {
                display: inline-block;
            }

            .checkbox-input {
                display: inline-block;
                margin: 0;
                padding-left: 0;

                input {
                    margin-top: 2px;
                    margin-left: 0;
                }

                label {
                    vertical-align: middle;
                }
            }

            svg {
                margin-left: 10px;
                color: $gray;
                width: 20px;
                vertical-align: middle;
                cursor: pointer;
            }
        }
    }

    .button-group {
        .login-button {
            margin: 60px 0px 24px 0px;
            padding: 10px;

            .icon {
                margin: 0 10px;
                width: 18px;
            }
        }
    }

    .password-input {
        position: relative;

        svg {
            position: absolute;
            top: 19px;
            right: 17px;
            color: $gray;
            width: 22px;
            cursor: pointer;
        }
    }

    .cta-row {
        margin: 0 25px;

        .register-link {
            margin-bottom: 24px;
            color: rgba($gray, 0.7);
        }
    }
}
