.profile-visuals-container {
    @include content-min-height();
    ul.nav.family-tabs.nav-tabs {
        flex-wrap: nowrap !important;
    }

    .family-tabs {
        overflow: hidden;
        width: 100vw;
        max-width: none !important;
        position: relative;
        left: -18px;
        @media screen and (max-width: 550px) {
            left: 0;
        }
        align-items: center;
        border-bottom: 1px solid rgba($base, 0.1);
        padding: 0 50px;

        @media screen and (min-width: 992px) {
            width: calc(100% + 36px);
        }

        .nav-item {
            display: flex;
            justify-content: center;
            padding: 0 15px;
        }

        .nav-link {
            color: $gray;
            text-transform: uppercase;
            font-weight: 600;
            border: none;
            padding: 20px 0;
            white-space: nowrap;
            font-size: 13px;
            letter-spacing: 1.25px;
            margin-bottom: 0;

            &.active,
            &:hover {
                border: none;
                color: $gold;
                border-bottom: 2px solid $gold;
                margin-top: 2px;
            }
        }
    }

    .profile-visual-images {

        @include content-min-height();

        .feedback {
            text-align: center;
            font-size: 12px;
            color: #a1a1a1;
        }

        padding-bottom: 60px;
        background-color: #f3f4f5;

        .light-button {
            background-color: #f3f4f5 !important;
        }

        .bordered-button {
            background-color: #f3f4f5 !important;
        }

        .visuals {
            margin: auto;
            max-width: 660px;
            padding-top: 20px;
            padding-bottom: 60px;
            //@include desktop-box-max-width();
            @include content-min-height();

            @media screen and (max-width: 576px) {
                max-width: 600px;
            }            

            .visual {
                position: relative;
                display: inline-flex;
                margin: 10px;
                width: 200px;
                height: 200px;

                @media screen and (max-width: 576px) {
                    width: 175px;
                    height: 175px;
                }
    
                @media screen and (max-width: 375px) {
                    width: 165px;
                    height: 165px;
                }                

                .fav-star {
                    position: absolute;
                    color: white;
                    border-radius: 25px;
                    right: 8px;
                    top: 5px;
                }
    
                .video-visual {
                    /* position: relative; */
                    .duration {
                        position: absolute;
                        bottom: -10px;
                        left: 5px;
                        color: white;
                    }
                }
    
                img {
                    object-fit: cover;
                    width: 200px;
                    height: 200px;
                    vertical-align: baseline;                    

                    @media screen and (max-width: 576px) {
                        width: 175px;
                        height: 175px;
                    }
    
                    @media screen and (max-width: 375px) {
                        width: 165px;
                        height: 165px;
                    }                    
                }
    
                video {
                    object-fit: cover;
                    width: 200px;
                    height: 200px;

                    @media screen and (max-width: 576px) {
                        width: 175px;
                        height: 175px;
                    }
    
                    @media screen and (max-width: 375px) {
                        width: 165px;
                        height: 165px;
                    }                    
                }
    
                .select {
                    position: absolute;
                    color: white;
                    right: 5px;
                    bottom: 5px;
                }
    
                .select-active {
                    position: absolute;
                    color: white;
                    background-color: $gold;
                    border-radius: 25px;
                    right: 5px;
                    bottom: 5px;
                }
            }
        }

        .info-row {
            display: flex;
            max-width: 660px;
            justify-content: space-between;
            margin: auto;
            padding-top: 20px;
            padding-left: 10px;
            padding-right: 10px;
    
            // sticky
            position: -webkit-sticky; /* Safari */
            position: sticky;
            top: 0;
            z-index: 1000;
            background-color: rgba(243, 244, 245, 0.96);

            @include desktop-box-max-width();
    
            .result {
                color: rgba(23,36,61,0.7);
                font-size: 12px;
                letter-spacing: 0;
                line-height: 18px;
            }
    
            .left {
                display: inline-flex;
    
                .p {
                    display: inline-flex;
                }
    
                .select-all {
                    margin-left: 16px;
                    color: #006238;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 18px;
                    text-decoration: underline;
                }
    
                .select-all:hover {
                    cursor: pointer;
                }
            }
            .right {
                margin-right: 0;
                color: #006238;
                text-decoration: underline;
    
                p {
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 18px;
                }
    
                :hover {
                    cursor: pointer;
                }
    
                .cancel {
                    display: flex;
                    color: gray;
                    p {
                        color: #EF5A5A;
                        text-decoration: underline;
                    }
    
                    svg {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}