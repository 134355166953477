.audio-uploader {
    .upload-drag-and-drop {
        width: 100%;
        height: 150px;
        background-color: rgba(23,36,61,0.05);
        border: 1px dashed rgba(23,36,61,0.12);

        text-align: center;

        svg {
            margin-top: 35px;
            color: $base-green;
        }

        p {
            font-size: 16px;
            letter-spacing: 0.15px;
            line-height: 24px;
            color: rgba(23,36,61,0.5);
            font-weight: bold;
        }
    }

    .upload-drag-and-drop:hover{
        cursor: pointer;
    }

    .upload-information {
        margin-top: 30px;
        margin-bottom: 30px;
        color: $gray;
    }
}