.story-requests-create {

    padding-top: 40px;
    max-width: 500px;
    margin: auto;
    padding-bottom: 80px;
    @include desktop-box-max-width();

    .top-info {
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        color: rgba(23,36,61,0.5);

        p {
            margin-bottom: 2px;
        }

        margin-bottom: 36px;
        position: relative;

        a {
            color: $base-green;
        }

        .icon-container {
            position: absolute;
            right: 90px;
            top: 0px;
            @media screen and (max-width: 760px) {
                right: 20px;
            }
        }

        .icon-container:hover {
            svg {
                cursor: pointer;
                color: gray;
            }
        }
    }

    .simple {
        margin-bottom: 48px;
    }

    .group {

        .sub-subtitle {
            color: rgba(23, 36, 61, 0.5);
            font-size: 13px;
            text-align: center;
            margin-bottom: 25px;
            line-height: 21px;
        }
        
        .right {
            text-align: right;
            padding-left: 13px;
        }
    }

    textarea {
        margin-top: 48px;
        border: 1px solid rgba($base, 0.38) !important;
        border-radius: 0 !important;
        padding: 15px !important;
        box-shadow: none !important;
        font-size: 16px !important;
        color: $base !important;
        width: 100%;

        &::placeholder {
            font-size: 16px;
            font-weight: 400;
            color: $gray !important;
            letter-spacing: 0.15px;
        }
    
        &:focus-visible {
            outline: none;
        }
    
    }

    .default-button {
        margin-top: 36px;
    }

    .navigated-from-info {
        background-color: #FFF6E1;

        margin-top: 37px;
        margin-bottom: 37px;

        position: relative;

        .clip {
            position: absolute;
            transform: rotate(180deg);
            top: -10px;
            color: #B4A37E;
        }

        .info-title {
            color: #17243D;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.5px;
            line-height: 24px;

            margin-left: 40px;
            margin-bottom: 4px;
            padding-top: 13px;
        }

        .info-text {
            color: #17243D;
            font-size: 14px;
            letter-spacing: 0.25px;
            line-height: 20px;

            margin-left: 40px;
            padding-bottom: 23px;

        }
    }
}

.story-requests-container {
    .status.PENDING {
        color: $blue;
    }
    
    .status.ACCEPTED {
        color: $base-green;
    }
    
    .status.DECLINED {
        color: $red;
    }
    .status-background {
        color: $custom_white;
        border-radius: 20px;
        margin: 0 auto;
        padding: 3px;
        max-width: 100px;

    }
    .status-background.PENDING {
        background-color: $blue;
    }
    
    .status-background.ACCEPTED {
        background-color: $base-green;
    }
    
    .status-background.DECLINED {
        background-color: $red;
    }

    .menu-tabs {

        .dot {
            position: absolute;
            height: 20px;
            width: 20px;
            background-color: #EF5A5A;
            border-radius: 50%;
            margin-top: 10px;
            padding-left: 1px;

            top: 0px;
            right: auto;

            color: white;

            p {
                /* margin-right: 7px; */
                font-size: 11px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 20px;
                text-align: center;
            
            }
        }    
    }

    .top-info {
        max-width: 500px;
        /*margin: auto;*/
        @include desktop-box-max-width();

        iframe {
            @media only screen and (max-width: 500px) {
                max-width: 335px;
            }
        
            @media only screen and (min-width: 501px) and (max-width: 700px) {
                max-width: 335px;
            }
        
            @media only screen and (min-width: 701px) {
                max-width: 500px;
            }
        
        }        
    }    
}
