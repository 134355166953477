.paymet-summary-modal {
    .modal-header {
        color: #17243D;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 32px;
    }

    .modal-body {
        padding: 1rem 0;
    }

    .body-container {
        margin: 5px 20px 50px 20px;
        padding: 15px;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

        .items {
            .name {
                p {
                    color: #17243D;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 21px;
                }
            }

            .price {
                text-align: right;
            }
        }

        .row {

        }

        .promo-code-container {
            margin-top: 30px;
            margin-bottom: 20px;

            .promo-code {
                background-color: #FFF3DB;
                padding: 10px;

                .tag {
                    text-align: left;
                    display: inline-flex;
                    margin-left: 20px;
                    margin-right: 5px;
                    color: $base-green;
                }

                .right {
                    float: right;
                    color: rgba(23,36,61,0.3);
                }

                p {
                    display: inline-flex;
                    text-align: left;
                    color: $base-green;
                    text-decoration: underline;
                    margin: 0;
                }
            }
        }

        .prices {
            background-color: #DBF9FF;
            padding-top: 10px;
            padding-bottom: 10px;

            .row {
                margin-top: 10px;
                margin-bottom: 10px;

                .title-text {
                    text-align: right;
                    color: rgba(23,36,61,0.7);
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 21px;
                }

                .grand-total {
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 21px;
                    p {
                        margin: 0;
                    }
                }

                .price {
                    text-align: right;
                    color: #17243D;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 30px;
                }
            }
        }

        .vat-text {
            text-align: center;
            padding: 30px;

            color: rgba(23,36,61,0.5);
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
        }

        .fastspring-info {
            text-align: center;
            margin-top: 30px;
            margin-bottom: 40px;

            p {
                color: rgba(23,36,61,0.7);
                font-size: 10px;
                font-weight: 300;
                letter-spacing: 1px;
                line-height: 16px;

                margin: 0;
            }
        }

    }
}