.main-wrapper {
    width: 100vw;
    @media screen and (min-width: 992px) {
        display: flex;
    }

    .content-wrapper {
        display: flex;
        flex-direction: column;

        .content {
            flex: 1 0 auto;
        }
        
        .footer {
            flex-shrink: 0;
        }

        @media screen and (min-width: 992px) {
            width: calc(100vw - 195px);
            flex-grow: 1;
        }

        .body {
            > div {
                @media screen and (min-width: 992px) {
                    flex-grow: 1;
                }
            }
        }
    }
}
