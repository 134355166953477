.tooltip-container {
    background-color: ($secondary, 0.5);
    backdrop-filter: blur(1px);
    text-align: center;
    z-index: 100;

    .modal-dialog {
        margin: 0;
        position: absolute;
        bottom: 0;
        max-width: none;

        @media (min-width: 768px) {
            max-width: 500px;
        }

        .modal-content {
            border: none;
            border-radius: 8px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

            @media (min-width: 768px) {
                border-radius: 8px;
            }

            .tooltip-header {
                padding: 20px;
                padding-bottom: 20px;
                margin-bottom: 24px;
                border-bottom: 1px solid rgba($base, 0.1);
                position: relative;
                display: block;

                .title {
                    text-align: center;
                    display: inline-block;
                    line-height: 36px;
                    margin-bottom: 0;
                }

                .btn-close {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    padding: 0 8px;
                }

                svg {
                    position: absolute;
                    right: 28px;
                    top: 8px;
                    color: rgba($base, 0.3);
                    font-size: 20px;
                    cursor: pointer;
                    transition: $transition;

                    &:hover {
                        color: $gray;
                        transition: $transition;
                    }
                }
            }

            .tooltip-content {
                padding: 0 20px;
                padding-bottom: 40px;

                //removed until a new decision from Andrew
                @media (min-width: 1000px) {
                    //padding: 20px 50px;
                }

                .subtitle {
                    text-transform: uppercase;
                    font-weight: 700;
                    margin-bottom: 24px;
                    text-align: center;
                }

                .body {
                    text-align: center;

                    @media (max-width: 767px) and (min-width: 576px) {
                        text-align: center;

                        ol {
                            text-align: left;
                            justify-content: center;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }
                    }
                    .no-margin-bottom {
                        margin-bottom: 0;
                    }

                    .text-left {
                        text-align: left;
                    }

                    ol {
                        padding: 1rem;
                        padding-top: 0;
                    }

                    .italic {
                        font-style: italic;
                    }

                    .button-container {
                        margin-top: 40px;
                        .cancel-button {
                            color: rgba(23,36,61,0.5);
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 768px) {
        text-align: left;
        display: flex !important;
        align-items: center;

        .modal-dialog {
            margin: auto;
            position: relative;
        }
    }
}

// Fade in and out TODO: create fade out

.animate-flicker {
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
