.menu-tabs.nav-tabs:not(.tabs-launchpad):not(.tabs-story-todo):not(.tabs-stories):not(.tabs-inspiration):not(.profile-tabs) {
    background-color: rgba(23,36,61,0.05);
    box-sizing: border-box;
    border-bottom: 3px solid white;
    clip-path: inset(0px 0px 4px 0px);


    .nav-item {
        margin: 0;
        padding: 0;
    }

    .nav-link {
        color: $gray;
        text-transform: uppercase;
        font-weight: 600;
        border: none;
        padding: 15px 16px;
        white-space: nowrap;
        letter-spacing: 1.25px;

        &.active,
        &:hover {
            border: none;
            color: $gold;
            clip-path: inset(0px -2px 0px -5px);
            box-shadow:
                0 2px 2px 0 rgba(0,0,0,0.14),
                0 3px 1px -2px rgba(0,0,0,0.12),
                0 1px 5px 0 rgba(0,0,0,0.2);
            margin-top: 2px;
        }
    }
}
.menu-tabs.tabs-launchpad,
.story-todo-container > ul.menu-tabs.tabs-story-todo,
.stories-container > ul.menu-tabs.tabs-stories,
.stories-container > ul.tabs-inspiration,
.profile-container > ul.profile-tabs
{
    align-items: center;
    justify-content: center;
    position: relative;
    border-bottom: none !important;
    width: 100%;


    .nav-item {
        padding: 0 17px;
    }

    .nav-link {
        color: $gray;
        text-transform: uppercase;
        font-weight: 600;
        border: none;
        padding: 16px 0;
        white-space: nowrap;
        letter-spacing: 1.25px;

        &.active,
        &:hover {
            z-index: 1001;
            border: none;
            color: $gold;
            border-bottom: 2px solid $gold;
            margin-top: 2px;
        }
    }
}


.create-container .menu-tabs.tabs-launchpad::after,
.create-container .story-todo-container > .menu-tabs::after,
.story-requests-container .menu-tabs.tabs-launchpad::after,
.stories-container ul.menu-tabs.tabs-stories::after,
.stories-container > ul.tabs-inspiration::after,
.profile-container > ul.profile-tabs::after
{
    z-index: -1;
    content: '';
    border-bottom: 1px solid #dee2e6;
    width: calc(100% + 72px);
    position: absolute;
    bottom: 0;
    left: -36px;
    display: block;

    @media screen and (max-width: 976px) {
        left: unset !important;
        width: 100vw !important;
    }

}

.menu-level-2{
    .nav-link {
        font-size: 13px;
    }
}

// Center all tabs to middle
ul.nav.nav-tabs {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-wrap: wrap !important;
}

// For slider-tabs component
.mobile-slider-tabs {
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;

    &::after {
        z-index: -1;
        content: '';
        border-bottom: 1px solid #dee2e6;
        width: calc(100% + 72px);
        position: absolute;
        bottom: 0;
        left: -36px;
        display: block;
    }

    ul {
        position: relative;
        padding-left: 0;
        margin-left: 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        list-style: none;
        max-width: 100%;
        overflow-x: scroll;
        scroll-behavior: smooth;
        overflow-y: hidden;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            background: transparent; /* make scrollbar transparent */
            -webkit-appearance: none;
            width: 0;
            height: 0;
            display: none;
        }

        li {
            margin: 0 10px;
            white-space: nowrap;
            box-sizing: border-box;
            border-radius: 0!important;
            margin: 0;
            -webkit-appearance: none;
            display: block;
            text-decoration: none;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
            cursor: pointer;
            margin-bottom: -1px;
            background: none;
            //border-top-left-radius: 0.25rem;
            //border-top-right-radius: 0.25rem;
            color: rgba(23, 36, 61, 0.5);
            text-transform: uppercase;
            font-weight: 600;
            border: none;
            padding: 16px 17px;
            white-space: nowrap;
            letter-spacing: 1.25px;

            &.active {
                background-color: #fff;
                z-index: 1001;
                color: #a38138;
                border-bottom: 3px solid #a38138;
                margin-top: 3px;
                border-radius: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                -webkit-appearance: none;
            }
        }
    }

    button {
        border: 0;
        color: #777;
        font-size: 24px;
        padding: 0;
        background-color: transparent;
        z-index: 0;
        position: relative;
        
        &.right-button {
            //padding-right: 8px;
            margin: 8px 10px 0px 8px;
            position: relative;

            &::after {
                z-index: 1002;
                position: absolute;
                content: "";
                top: 0;
                right: 24px;
                width: 40px;
                height: calc(100% - 3px);
                background: -moz-linear-gradient(left, rgba(255,255,255,0) 25%, rgba(255,255,255,1) 100%);
                background: -webkit-linear-gradient(left, rgba(255,255,255,0) 25%,rgba(255,255,255,1) 100%);
                background: linear-gradient(to right, rgba(255,255,255,0) 25%,rgba(255,255,255,1) 100%);
            }
        }
        &.left-button {
            margin: 8px 4px 0px 0px;

            &::after {
                z-index: 1002;
                position: absolute;
                content: "";
                top: 0;
                left: 24px;
                width: 30px;
                height: calc(100% - 3px);
                background: -moz-linear-gradient(left, rgba(255,255,255,1) 25%, rgba(255,255,255,0) 100%);
                background: -webkit-linear-gradient(left, rgba(255,255,255,1) 25%,rgba(255,255,255,0) 100%);
                background: linear-gradient(to right, rgba(255,255,255,1) 25%,rgba(255,255,255,0) 100%);
            }
        }    
    }
}